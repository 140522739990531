const vesselSelection = {
  vesselSelection: "yes",
  // vesselrangetype: "",
  vesselFlangeType: "",
  vesselMaterial: "",
  vesselFlangePipeSize: "",
  sadlemoc: "",
  flangeSize: "",
  standard: "",
  KettleSize: "",
  FlangeRating: "",
  reducers: "",
  radiography: { value: "", label: "" },
  vesselMaterialCode: "",
  installationPosition: "",
  insulation: {
    insulationThickness: "",
    insulationDensity: "",
    claddingSheetThickness: "",
    claddingSheetDensity: "",
    wastage: "",
  },
};

export default vesselSelection;
