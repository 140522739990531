const nozzleSelection = {
  multiStackVesselFormData: [
    {
      temp: {
        inlettemp: 0,
        outlettemp: 0,
        tempchange: 0,
      },
      heatDutyKW: 0,
      //   Connection:"",
      divideEqually: true,
      multistackvessel: 0,
      bank: 0,
      installedCapacity: 0,
      bundleTableData: [],
      voltage: 0,
      wattdensity: 0,
      wattdensityactual: 0,
      voltage_calculation: 0,
      baffle: {
        numberofHotBaffles: 0,
        baffleplatethickness: 0,
        baffletype: 0,
        bafflecut: 0,
        bafflematerial: "",
        tieroddia: 0,
        tierodquantity: 0,
        tiematerial: "",
        coldBaffle: 0,
      },
      nozzle: [
        {
          name: "Inlet Nozzle",
          inletNozzle: false,
          size: "",
          schedules: "",
          flangeRating: "",
          flangeType: "",
          counterFlange: false,
          material: "",
          materialCode: "",
          elevation: 0,
          orientation: 0,
          // materialGrade: "",
          // quantity: 0,
        },
        // 1
        {
          name: "Outlet Nozzle",
          outletNozzle: false,
          size: "",
          schedules: "",
          flangeRating: "",
          flangeType: "",
          counterFlange: false,
          material: "",
          materialCode: "",
          elevation: 0,
          orientation: 0,
          // materialGrade: "",
          // quantity: 0,
        },
        // 2
        {
          name: "Vent",
          vent: false,
          size: "",
          schedules: "",
          flangeRating: "",
          flangeType: "",
          counterFlange: false,
          material: "",
          materialCode: "",
          elevation: 0,
          orientation: 0,
          // materialGrade: "",
          // quantity: 0,
        },
        // 3
        {
          name: "Drain",
          drain: false,
          size: "",
          schedules: "",
          flangeRating: "",
          flangeType: "",
          counterFlange: false,
          material: "",
          materialCode: "",
          elevation: 0,
          orientation: 0,
          // materialGrade: "",
          // quantity: 0,
        },
        // 4
        {
          name: "Process",
          process: false,
          size: "",
          schedules: "",
          flangeRating: "",
          flangeType: "",
          counterFlange: false,
          material: "",
          materialCode: "",
          elevation: 0,
          orientation: 0,
          // materialGrade: "",
          // quantity: 0,
        },

        // 5
        {
          name: "Dummy",
          dummy: false,
          size: "",
          schedules: "",
          flangeRating: "",
          flangeType: "",
          counterFlange: false,
          material: "",
          materialCode: "",
          elevation: 0,
          orientation: 0,
          // materialGrade: "",
          quantity: 0,
        },
      ],
      heatersheathtubedia: 0,
      sheathmaterial: "",
      maxactivehotlength: 0,
      maxactivecoldlength: 0,
      developedlength: 0,
      bundleimmersionlength: 0,
      sensor: [
        {
          selected: false,
          sensortype: "",
          sensorsubtype: "",
          sensorrange: "",
          quantity: 0,
          material: "",
          headMaterial: "",
        },
        {
          selected: false,
          sensortype: "",
          sensorsubtype: "",
          sensorrange: "",
          quantity: 0,
          material: "",
          headMaterial: "",
        },
        {
          selected: false,
          sensortype: "",
          sensorsubtype: "",
          sensorrange: "",
          quantity: 0,
          material: "",
          headMaterial: "",
        },
      ],
      // Pressures
      inletPressure: null,
      outletPressure: null,
      allowedPressureDrop: null,
      inletPressureUnit: "",
      // outletUnit: "",
      // allowedPressureDropUnit: "",

    },
  ],
};

export default nozzleSelection;

// formik.values.nozzle[0].inletNozzle
// formik?.values?.multiStackVesselFormData[index].nozzle[0].inletNozzle
