import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import Select from "react-select";

function PriceEdit(props) {
  const [item, setItem] = useState({
    id: null,
    name: null,
    material_category: null,
    material: null,
    size: null,
    class: null,
    type: null,
    unit: null,
    price: null,
  });

  const { id } = useParams();
  const [priceName, setPriceName] = useState("");
  const [price, setPrice] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const fetchPriceData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(`/bom-prices/${id}`);
      if (response.status === 200) {
        const data = response.data.data;
        setItem({
          // id: data?.id,
          // name: data?.name,
          // materialName: data?.materialName,
          // flangeSize: data?.flangeSize,
          // size: data?.size,
          // class: data?.class,
          // price: data?.price,
          id: data?.id,
          name: data?.name,
          material_category: data?.material_category,
          material: data?.material,
          size: data?.size,
          class: data?.class,
          type: data?.type,
          unit: data?.unit,
          price: data?.price,
        });
        setPriceName(data?.name);
        setPrice(data?.price);
      }
    } catch (error) {
      showErrorToast("Error fetching price data");
      navigate("/price");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPriceData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !item.id === "" ||
      !item.name === "" ||
      !item.material_category === "" ||
      !item.material === "" ||
      !item.size === "" ||
      !item.class === "" ||
      !item.type === "" ||
      !item.unit === "" ||
      !item.price === ""
    ) {
      showErrorToast("Fill all field");
      return;
    }

    setIsLoading(true);

    try {
      const payload = {
        id: item.id,
        name: item.name,
        material_category: item.material_category,
        material: item.material,
        size: item.size,
        class: item.class,
        type: item.type,
        unit: item.unit,
        price: item.price,
      };

      await axiosPrivate.put(`/bom-prices/${id}`, payload);
      showSuccessToast("Item updated successfully");
      navigate("/price");
    } catch (error) {
      showErrorToast("Error updating Item ");
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (
  //     !item.name === "" ||
  //     !item.type === "" ||
  //     !item.sub_type === "" ||
  //     !item.unit === "" ||
  //     !item.price === ""
  //   ) {
  //     showErrorToast("Fill all field");
  //     return;
  //   }
  //   setIsLoading(true);

  //   axiosPrivate
  //     .post("/bom-prices", {
  //       name: item.name,
  //       type: item.type,
  //       sub_type: item.type,
  //       unit: item.unit,
  //       price: item.price,
  //     })
  //     .then((response) => {
  //       setIsLoading(false);
  //       showSuccessToast("Item added successfully");
  //       navigate("/price");
  //     })
  //     .catch((error) => {
  //       console.error(`Error adding Item: ${error}`);
  //       showErrorToast("Error adding Item");
  //       setIsLoading(false);
  //     });
  // };

  const flangeOptions = [
    { value: "Data 1", label: "Data 1" },
    { value: "Data 2", label: "Data 2" },
    { value: "Data 3", label: "Data 3" },
    { value: "Data 4", label: "Data 4" },
    { value: "Data 5", label: "Data 5" },
    { value: "Data 6", label: "Data 6" },
    { value: "Data 7", label: "Data 7" },
    { value: "Data 8", label: "Data 8" },
    { value: "Data 9", label: "Data 9" },
    { value: "Data 10", label: "Data 10" },
  ];

  const dropdownStyle = {
    control: (provided) => ({
      ...provided,
      minHeight: "40px",
    }),
    menu: (provided) => ({
      ...provided,
    }),
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Edit Price</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex mb-30">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                          <div className="w-100 mr-3">
                            <label htmlFor="name">Name</label>
                            <Select
                              name="name"
                              id="name"
                              required
                              className=" mr-3 "
                              options={flangeOptions}
                              styles={dropdownStyle}
                              value={{ value: item.name, label: item.name }}
                              onChange={(option) =>
                                setItem({
                                  ...item,
                                  name: option?.value ?? option,
                                })
                              }
                              isDisabled={true}
                            />
                          </div>
                          <div className="w-100 mr-3">
                            <label htmlFor="type">material_category</label>
                            <Select
                              name="material_category"
                              id="material_category"
                              // // isClearable
                              className=" mr-3 "
                              options={flangeOptions}
                              styles={dropdownStyle}
                              placeholder="Select material_category"
                              value={
                                item.material_category && {
                                  value: item.material_category,
                                  label: item.material_category,
                                }
                              }
                              onChange={(option) =>
                                setItem({
                                  ...item,
                                  material_category: option?.value ?? option,
                                })
                              }
                              isDisabled={true}
                            />
                          </div>
                          <div className="w-100 mr-3">
                            <label htmlFor="sub_type">material</label>
                            <Select
                              name="material"
                              id="material"
                              className=" mr-3 "
                              options={flangeOptions}
                              styles={dropdownStyle}
                              placeholder="Select material "
                              value={
                                item.material && {
                                  value: item.material,
                                  label: item.material,
                                }
                              }
                              onChange={(option) =>
                                setItem({
                                  ...item,
                                  material: option?.value ?? option,
                                })
                              }
                              isDisabled={true}
                            />
                          </div>
                          <div className="w-100 mr-3">
                            <label htmlFor="size">size</label>
                            <Select
                              name="size"
                              id="size"
                              // // isClearable
                              className=" mr-3 "
                              options={flangeOptions}
                              styles={dropdownStyle}
                              placeholder="Select size "
                              value={
                                item.size && {
                                  value: item.size,
                                  label: item.size,
                                }
                              }
                              onChange={(option) =>
                                setItem({
                                  ...item,
                                  size: option?.value ?? option,
                                })
                              }
                              isDisabled={true}
                            />
                          </div>
                          <div className="w-100 mr-3">
                            <label htmlFor="sub_type">Flange Class</label>
                            <Select
                              name="class"
                              id="class"
                              // // isClearable
                              className=" mr-3 "
                              options={flangeOptions}
                              styles={dropdownStyle}
                              value={
                                item.class && {
                                  value: item.class,
                                  label: item.class,
                                }
                              }
                              placeholder="Select Flange Class "
                              onChange={(option) =>
                                setItem({
                                  ...item,
                                  class: option?.value ?? option,
                                })
                              }
                              isDisabled={true}
                            />
                          </div>
                          <div className="w-100 mr-3">
                            <label htmlFor="sub_type">Flange Type</label>
                            <Select
                              name="type"
                              id="type"
                              // // isClearable
                              className=" mr-3 "
                              options={flangeOptions}
                              styles={dropdownStyle}
                              value={
                                item.type && {
                                  value: item.type,
                                  label: item.type,
                                }
                              }
                              placeholder="Select Flange type "
                              onChange={(option) =>
                                setItem({
                                  ...item,
                                  type: option?.value ?? option,
                                })
                              }
                              isDisabled={true}
                            />
                          </div>
                          <div className="w-100 mr-3">
                            <label htmlFor="sub_type">Unit</label>
                            <Select
                              name="unit"
                              id="unit"
                              // // isClearable
                              className=" mr-3 "
                              options={flangeOptions}
                              styles={dropdownStyle}
                              value={
                                item.unit && {
                                  value: item.unit,
                                  label: item.unit,
                                }
                              }
                              placeholder="Select unit "
                              onChange={(option) =>
                                setItem({
                                  ...item,
                                  type: option?.value ?? option,
                                })
                              }
                              isDisabled={true}
                            />
                          </div>

                          <div className="w-100">
                            <label htmlFor="price">Price</label>
                            <input
                              name="price"
                              type="text"
                              className="form-control mr-3"
                              placeholder="Item price"
                              value={item?.price}
                              onChange={(e) =>
                                setItem({
                                  ...item,
                                  price: parseFloat(e.target.value),
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/price">
                          <button
                            type="button"
                            className="btn btn-secondary mr-3"
                          >
                            Close
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PriceEdit;
