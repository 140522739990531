import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoMdFolderOpen } from "react-icons/io";
import { Check } from "@mui/icons-material";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Define table headers
  const headers = [
    {
      name: "Customer Name",
      field: "customer_name",
      sortable: true,
      classKey: "",
    },
    { name: "Project", field: "project_name", sortable: true, classKey: "" },
    { name: "Move to Post", field: "status", classKey: "" },
    { name: "Action", field: "action", classKey: "" },

  ];

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/customers");
      if (response.status === 200) {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i]["action"] = (
            <IoMdFolderOpen
              color="green"
              style={{ cursor: "pointer", fontSize: "20px" }}
              onClick={() => {
                navigateToDrive(response.data.data[i].id);
              }}
            />
          );
        }
        setCustomers(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Global Search"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <FaEdit
          color="green"
          style={{ cursor: "pointer", fontSize: "20px" }}
          onClick={() => {
            alert(rowData.name);
          }}
        />
      </div>
    );
  };

  const navigateToDrive = (custId) => {
    if (custId) {
      navigate(`/customers/drive/${custId}`);
    }
  };

  const updateStatus = async (custId) => {
    // try {
    //   const response = await axiosPrivate.put(`/customers/${custId}/status`, {
    //     status: "post",
    //   });
    //   if (response.status === 200) {
    //     showSuccessToast("Customer status updated to post");
    //     fetchData(); // Refresh the data
    //   }
    // } catch (error) {
    //   showErrorToast(error.message);
    // }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Button
        className="btn btn-primary inline"
        label={rowData.status === "post" ? "Posted" : "Move to Post"}
        onClick={() => updateStatus(rowData.id)}
        disabled={rowData.status === "post"}
        width="40px"
      />
    );
  };

  const header = renderHeader();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Customers</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive m-2">
                <DataTable
                  value={customers}
                  dataKey="id"
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  globalFilter={filters?.global?.value}
                  emptyMessage="No customers found"
                >
                  {headers.map((header, index) => {
                    return (
                      <Column
                        key={index}
                        field={header?.field}
                        body={
                          header?.field === "status"
                            ? statusBodyTemplate
                            : false
                        }
                        header={header?.name}
                        sortable={header?.field !== "action"}
                        bodyStyle={{
                          textAlign:
                            header?.field === "status"
                              ? "left"
                              : header?.field === "action"
                              ? "right"
                              : "left",
                        }}
                        headerStyle={{
                          width: header?.field === "action" ? "10%" : "",
                          paddingLeft:
                            header?.field === "action" ? "110px" : "",
                        }}
                      />
                    );
                  })}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerList;