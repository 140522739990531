import React from "react";

const BOMControlPanel = () => {
  const tableParentDiv = {
    display: "flex",
    margin: "10px 0px",
    gap: "10px",
  };

  const tableClass = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const trClass = {
    height: "22px",
  };

  const tdClass = {
    border: "1px solid black",
    height: "22px",
    padding: "0 4px",
    minWidth: "20px",
  };

  const thickBorder = {
    border: "1px solid black",
  };

  const rightThickBorder = {
    borderRight: "1px solid black",
  };

  return (
    <div>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          ...thickBorder,
        }}
        cellSpacing={0}
      >
        <tbody style={{margin: "10px 0px"}}>
          <tr style={trClass}>
            <td
              style={{ ...tdClass, ...thickBorder }}
              colSpan={7}
              bgcolor="#C5D9F1"
            >
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "24px",
                  paddingTop: "10px",
                  paddingBottom: "10px",

                }}
              >
                ANNEXURE-1BOQ
              </span>
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} bgcolor="#EEECE1"></td>
            <td style={tdClass} colSpan={6} bgcolor="#EEECE1"></td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} bgcolor="#EEECE1"></td>
            <td style={tdClass} colSpan={6} bgcolor="#EEECE1"></td>
          </tr>
          <tr style={{ ...trClass, ...thickBorder }}>
            <th style={tdClass} bgcolor="#EEECE1">
               SL No
            </th>
            <th style={tdClass} bgcolor="#EEECE1">
               Description
            </th>
            <th style={tdClass} bgcolor="#EEECE1">
              Model No
            </th>
            <th style={tdClass} colSpan={3} bgcolor="#EEECE1">
              Make
            </th>
            <th style={tdClass} bgcolor="#EEECE1">
              Qty
            </th>
          </tr>
          <tr style={trClass}>
             <td style={{...tdClass, fontWeight: "bold"}} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
          <tr style={trClass}>
             <td style={tdClass} align="center">1</td>
            <td style={tdClass}>
              MCCB<span> </span>-<span> </span>
              LSIG
              <span> </span>RELEASE
              <span> </span>,<span> </span>
              50KA@415V
            </td>
            <td style={tdClass}>400A,3P</td>
            <td style={tdClass} colSpan={3}>
              SCHNEIDER
            </td>
             <td style={tdClass} align="center">1</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BOMControlPanel;
