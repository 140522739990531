const customStyles = {
    option: (base, state) => ({
        ...base,
        color: state.isSelected ? 'white' : '#757575',
        backgroundColor: state.isSelected ? 'var(--primary-background-color)' : 'white',
        '&:hover': {
            backgroundColor: 'rgb(177,58,37,0.9)',
            color: 'white',

        },
    }),
    control: (base, state) => ({
        ...base,
        backgroundColor: state.isDisabled ? '#e9ecef' : 'white',
        borderColor: state.isFocused ? 'var(--primary-background-color)' : base.borderColor,
        boxShadow: state.isFocused ? '0 0 0 0.5px var(--primary-background-color)' : base.boxShadow,
        '&:hover': {
            borderColor: 'var(--primary-background-color)',
        },
        borderWidth: state.isFocused ? '1px' : base.borderWidth,
    }),
    singleValue: (base) => ({
        ...base,
        color: 'black',
    }),
    indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: 'var(--primary-background-color)',
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: state.isFocused ? 'var(--primary-background-color)' : base.color,
        '&:hover': {
            color: 'var(--primary-background-color)',
        },
    }),
    clearIndicator: (base, state) => ({
        ...base,
        color: state.isFocused ? 'var(--primary-background-color)' : base.color,
        '&:hover': {
            color: 'var(--primary-background-color)',
        },
    }),
    valueContainer: (base) => ({
        ...base,
        backgroundColor: 'white',
    }),
    menu: (base) => ({
        ...base,
        maxHeight: '150px', // Set the maximum height for the entire dropdown menu
        width: '100%', // Ensure it takes up the full width
    }),
    menuList: (base) => ({
        ...base,
        maxHeight: '150px', // Max height for the list inside the menu
        overflowY: 'auto', // Enable scrolling if the content exceeds the max height
    }),
    placeholder: (base) => ({
        ...base,
        color: '#757575',
    }),
};

const customStylesForDisabled = {
    indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: 'var(--primary-background-color)',
    }),
    singleValue: (base) => ({
        ...base,
        color: 'black',
    }),
    option: (base, state) => ({
        ...base,
        color: state.isSelected ? 'white' : '#757575',
        backgroundColor: state.isSelected ? 'var(--primary-background-color)' : 'white',
        '&:hover': {
            backgroundColor: 'rgb(177,58,37,0.9)',
            color: 'white',
        },
    }),
    control: (base, state) => ({
        ...base,
        backgroundColor: state.isDisabled ? '#e9ecef' : 'white',
        borderColor: state.isFocused ? 'var(--primary-background-color)' : base.borderColor,
        boxShadow: state.isFocused ? '0 0 0 0.5px var(--primary-background-color)' : base.boxShadow,
        '&:hover': {
            borderColor: state.isDisabled ? 'var(--primary-background-color)' : base.borderColor,
        },
        borderWidth: state.isFocused ? '1px' : base.borderWidth,
    }),
};

export { customStyles, customStylesForDisabled };