import * as Yup from "yup";

const validationRules = {
  // screen 2
  processFluid: Yup.string().required("Process Fluid is required"),
  specificHeatKJ: Yup.number().required("Specific Heat KJ is Required"),
  tempChange: Yup.number().required("Temp Change is Required"),
  massFlowRate: Yup.number().required("Mass Flow Rate is Required"),
  startingTemp: Yup.number().required("Starting Temp is Required"),
  outletTemp: Yup.number().required("Outlet Temp is Required"),
  heatDutyKW: Yup.number().required("Heat Duty KW is Required"),
  areaClassification: Yup.string().required("Area Classification is Required"),
  ipRating: Yup.string().required("IP Rating is Required"),
  multistackvessel: Yup.number().required("Multi Stack Vessel is Required"),
  connection: Yup.string().required("Connection is Required"),
  inletPressure: Yup.number().required("Inlet Pressure is Required"),
  outletPressure: Yup.number().required("Outlet Pressure is Required"),
  allowedPressureDrop: Yup.number().required(
    "Allowed Pressure Drop is Required"
  ),
  sensorSelection: Yup.string().required("Sensor Selection is Required"),
  //screen 3
  designTemperature: Yup.number()
    .typeError("Design Temperature must be a number")
    .required("Design Temperature is required"),
  designPressure: Yup.number()
    .typeError("Design Pressure must be a number")
    .required("Design Pressure is required"),
  elementDiameter: Yup.number().required("Element Diameter is Required"),
  flangeSelection: Yup.string().required("Flange Selection is Required"),
  material: Yup.string().required("Material is Required"),
  materialCode: Yup.string().required("Material Code is Required"),
  FlangeClass: Yup.string().required("Flange Class is Required"),
  maxPressure: Yup.number()
    .required("Max Pressure is required")
    .moreThan(
      Yup.ref("designPressure"),
      "Max Pressure must be greater than Design Pressure, or select a higher Flange Class"
    ),
  flangeSize: Yup.string().required("Flange Size is Required"),
  schedule: Yup.string().required("Schedule is Required"),
  //screen 4
  heatersheathtubedia: Yup.string().required(
    "Heater Sheath Tube Dia is Required"
  ),
  distbwheaterflangetoterminalbox: Yup.string().required(
    "Distance between Heater Flange to TerminalBox is Required"
  ),
  maxactivehotlength: Yup.string().required("Max Active Length is Required"),
  maxactivecoldlength: Yup.string().required("Cold Length is Required"),
  sheathmaterial: Yup.string().required("Sheath Material is Required"),
  fixingofheatingelements: Yup.string().required(
    "Fixing of Heating Elements is Required"
  ),
  distancebwionozzels: Yup.string().required(
    "Distance between Ion Nozzles is Required"
  ),
  totalnumberofelements: Yup.number()
    .required("Total No of Elements is Required")
    .lessThan(
      Yup.ref("totalHoles"),
      "Total Number of Elements must be less than Total No of Holes"
    ),
  numberofelementsconnected: Yup.string().required(
    "Number of Elements Connected is Required"
  ),
  bundleimmersionlength: Yup.string().required(
    "Bundle Immersion Length is Required"
  ),

  // screen 5
  vesselFlangeType: Yup.string().required("Vessel Flange Type is Required"),
  vesselMaterial: Yup.string().required("Vessel Material is Required"),
  vesselFlangePipeSize: Yup.string().required(
    "Vessel Flange Pipe Size is Required"
  ),
  standard: Yup.string().required("Standard is Required"),
};

export default validationRules;
