import React from "react";
import logo2 from "../../../assets/Logos/logo.png";

const ControlPanel = ({ formik }) => {
  const tableParentDiv = {
    display: "flex",
    margin: "10px 0px",
    gap: "10px",
  };

  const tableClass = {
    width: "100%",
    height: "100%",
    borderCollapse: "collapse",
  };

  const trClass = {
    height: "22px",
    borderCollapse: "collapse",
  };

  const tdClass = {
    border: "1px solid black",
    height: "22px",
    padding: "0 4px",
    minWidth: "20px",
    borderCollapse: "collapse",
  };

  const thClass = {
    ...tdClass,
    backgroundColor: "#CCCCCC",
    fontWeight: "600",
  };

  const thickBorder = {
    border: "1px solid black",
  };

  const rightThickBorder = {
    borderRight: "1px solid black",
  };

  return (
    <div>
              <div
          style={{
            border: "1px solid black",
            margin: "0 0 10px  0 ",
            position: "relative",
          }}
        >
          <img
            src={logo2}
            alt="abc"
            style={{ height: "auto", width: "200px", margin: "10px " }}
          />
          <h1
            style={{
              fontSize: "25px",
              position: "absolute",
              top: "25%",
              left: "38%",
              margin: "10px 0",
            }}
          >
            DATA SHEET OF 3215KW TCP
          </h1>
        </div>
      <div style={{ marginBottom: "5px" }}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">Project </td>
            <td style={tdClass}>{formik.values.projectName || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">Item </td>
            <td style={tdClass}>3215 kW Thyristor Control Panel and Heater</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass} className="font-weight-bold">Document No. </td>
            <td style={tdClass}>224102-GDUGSU-DS-00004</td>
          </tr>
        </table>
      </div>
      <div style={tableParentDiv}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td style={thClass} colSpan={3} align="center">
              PANEL GENERAL & CONSTRUCTION
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">TYPE TEST APPLICBALE FOR PANEL</td>
            <td style={tdClass}>IEC-61439</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">TYPE OF PANEL </td>
            <td style={tdClass}>ABB-ArTuk Design</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">MATERIAL</td>
            <td style={tdClass}>CRCA STEEL SHEET</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">RATED SHORT CIRCUIT WITHSTAND CURRENT </td>
            <td style={tdClass}>65KA FOR 1 SEC</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">RATED INSULATION VOLTAGE FOR ACB</td>
            <td style={tdClass}>1000V</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">RATED INSULATION VOLTAGE FOR ACB </td>
            <td style={tdClass}>690V</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">IMPULSE WITHSTAND VOLTAGE</td>
            <td style={tdClass}>12KV (FOR ACB AND MAIN BUSBAR)</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold"> TOTAL HARMONIC WITHSTAND CURRENT</td>
            <td style={tdClass}>LESS THAN 3%</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">AMBIENT TEMPERATURE</td>
            <td style={tdClass}>40 DEGREE C</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">TAG NUMBER</td>
            <td style={tdClass}>244-00-E-007A/B</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">DESIGN LIFE</td>
            <td style={tdClass}>20 YEARS</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">WEIGHT OF THE PANEL </td>
            <td style={tdClass}>2500KG (APPROX.)</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">TOTAL HARMONIC DISTORTION </td>
            <td style={tdClass}>LESS THAN 3%</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">PANEL ACCESSORIES DETAILS</td>
            <td style={tdClass}>
              6'' FAN WITH FILTER 100WATT SPACE HEATER 6A/16A POWER SOCKET
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">DOORS</td>
            <td style={tdClass}>1.5MM</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">SIDE & REAR PANEL</td>
            <td style={tdClass}>1.5MM</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">MOUNTING PLATE</td>
            <td style={tdClass}>2.0MM (GI)</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">GLAND PLATE </td>
            <td style={tdClass}>3MM (GI)</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold"> IP RATING</td>
            <td style={tdClass}>IP54</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">LOCK SYSTEM</td>
            <td style={tdClass}>3 POINT LOCK</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">Dimensions (W x H x D) </td>
            <td style={tdClass}>              {formik?.values?.cp_enclosureSizeHeight || "N/A"} x
              {formik?.values?.cp_enclosureSizeWidth || "N/A"} x
              {formik?.values?.cp_enclosureSizeDepth || "N/A"}</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">BASE FRAME</td>
            <td style={tdClass}>ISMC 100</td>
          </tr>
        </table>
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            borderCollapse: "collapse",
          }}
        >
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={3} align="center">
                PAINT SHADE
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">EXTERNAL</td>
              <td style={tdClass}>RAL 7035 TEXTURED FINISH</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">INTERNAL</td>
              <td style={tdClass}>RAL 7035 TEXTURED FINISH</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">MOUNTING PLATE</td>
              <td style={tdClass}>GALVANAISED IRON</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">BASE FRAME </td>
              <td style={tdClass}>BLACK (RAL 9005)</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">PAINT THICKNESS</td>
              <td style={tdClass}>ABOVE 60 MICRONS</td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={3} align="center">
                BUSBAR
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">TYPE TEST APPLICBALE FOR PANEL</td>
              <td style={tdClass}>IEC-61439</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">TYPE OF PANEL </td>
              <td style={tdClass}>ABB-ArTuk Design</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">MATERIAL</td>
              <td style={tdClass}>CRCA STEEL SHEET</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">RATED SHORT CIRCUIT WITHSTAND CURRENT </td>
              <td style={tdClass}>65KA FOR 1 SEC</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">RATED INSULATION VOLTAGE FOR ACB</td>
              <td style={tdClass}>1000V</td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={{ ...trClass, maxHeight: "25px" }}>
              <td style={thClass} colSpan={3} align="center">
                CABLE ENTRY
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">INCOMING</td>
              <td style={tdClass}>TOP BUSDUCT ENTRY</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">OUTGOING</td>
              <td style={tdClass}>BOTTOM ENTRY</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">CONTROL CABLES</td>
              <td style={tdClass}>BOTTOM ENTRY</td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={2} align="center">
                NAME PLATES
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">INTERNAL & EXTERNAL :</td>
              <td style={tdClass}>
                ANODIZED ALUMINIUM LABELS WITH BLACK BACKGROUND AND WHITE
                LETTERS
              </td>
            </tr>
          </table>
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={2} align="center">
                PROTECTION DETAILS
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">ACB</td>
              <td style={tdClass}>LSIG PROTECTION with U/V protection</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">ELR</td>
              <td style={tdClass}>EARTH LEAKAGE PROTECTION</td>
            </tr>
          </table>
        </div>
      </div>
      <div style={tableParentDiv}>
        <table style={tableClass}>
          <tr style={trClass}>
            <td style={thClass} colSpan={2} align="center">
              PANEL WIRING
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">CT CIRCUIT </td>
            <td style={tdClass}>
              2.5 SQMM L1-BROWN, L2-BLACK, L3- GREY, L4-BLUE
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold"> PT CIRCUIT </td>
            <td style={tdClass}>
              2.5 SQMM L1-BROWN, L2-BLACK, L3- GREY, L4-BLUE
            </td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">AC CONTROL CIRCUIT</td>
            <td style={tdClass}>1.5 SQMM GREY</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">POTENTIAL FREE CONTACT WIRING</td>
            <td style={tdClass}>1.5 SQMM GREY</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">RS485(Shielded cable)</td>
            <td style={tdClass}>1 SQMM RED/BLACK</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">24V DC POWER CIRCUIT </td>
            <td style={tdClass}>2.5 SQMM BLACK & RED</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">24V DC CONTROL CIRCUIT </td>
            <td style={tdClass}>2.5 SQMM BLACK & RED</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold"> GROUNDING CIRCUIT</td>
            <td style={tdClass}>2.5 SQMM YELLOW/GREEN</td>
          </tr>
          <tr style={trClass}>
            <td style={tdClass}className="font-weight-bold">CABLE TYPE </td>
            <td style={tdClass}>PVC FRLS CABLES</td>
          </tr>
        </table>
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            borderCollapse: "collapse",
          }}
        >
          <table style={tableClass}>
            <tr style={trClass}>
              <td style={thClass} colSpan={2} align="center">
                SYSTEM RATING
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">SYSTEM</td>
              <td style={tdClass}>3 PHASE, 3 WIRE SYSTEM</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold"> RATED VOLTAGE</td>
              <td style={tdClass}>{formik?.values?.voltage || "N/A"}V</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">RATED FREQUENCY</td>
              <td style={tdClass}>{formik?.values?.frequency || "N/A"}</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">CONTROL VOLTAGE </td>
              <td style={tdClass}>230V AC ( FROM C.TRF)</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">CONTROL VOLTAGE</td>
              <td style={tdClass}>125V DC ( FROM FIELD for ACB only )</td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold">CONTROL HEATER RAING </td>
              <td style={tdClass}>
                3215KW(4X 514.4KW + 2X578.7KW ) BANK-1 (THY.CONTROL-578.7KW)
                BANK-2 (CON.CONTROL-514.4KW)
              </td>
            </tr>
            <tr style={trClass}>
              <td style={tdClass}className="font-weight-bold"> RATED CURRENT</td>
              <td style={tdClass}>2690A</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
