import React, { useState, useEffect } from "react";
import styles from "./ganttChart.module.css";

import {
  GanttOriginal,
  ViewMode,
  TaskListHeader,
  TaskListTable,
  CalendarHeader,
  Grid,
  BarDisplay,
  VerticalScroll,
  HorizontalScroll,
} from "react-gantt-chart";
import axiosPrivate from "../../hooks/axiosPrivate";

const initialTasks = [
  {
    id: "1",
    name: "Create Gantt Chart",
    start: new Date("2024-07-01"),
    end: new Date("2024-07-10"),
    progress: null,
    dependencies: ["0"],
    assignee: "udit",
    type: "task",
    parentId: "0",
  },
];

const Gantt = () => {
  const [currentFormType, setCurrentFormType] = useState(null);
  const [tasks, setTasks] = useState(initialTasks);
  const [showModal, setShowModal] = useState(false);
  const [editedTask, setEditedTask] = useState(null);
  const [newTask, setNewTask] = useState({});
  const [viewMode, setViewMode] = useState(ViewMode.Day);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [validationStatus, setValidationStatus] = useState(false);

  const generateId = () => {
    let maxId = 0;
    for (let i = 0; i < tasks.length; i++) {
      const currentId = parseInt(tasks[i].id);
      if (currentId > maxId) {
        maxId = currentId;
      }
    }
    return (maxId + 1).toString();
  };

  const handleOpenModal = (type, task = null) => {
    setCurrentFormType(type);
    setShowModal(true);
    if (task) {
      setEditedTask(task);
      setNewTask({
        id: task?.id,
        name: task?.name,
        start: new Date(task?.start).toISOString().split("T")[0],
        end: new Date(task?.end).toISOString().split("T")[0],
        progress: task?.progress,
        dependencies: task?.dependencies,
        assignee: task?.assignee,
        type: task?.type,
        parentId: task?.parentId,
      });
    } else {
      setEditedTask(null);
      setNewTask({
        id: generateId(),
        name: "",
        start: new Date(),
        end: new Date(),
        progress: null,
        dependencies: [],
        assignee: "",
        type: type,
        parentId: "",
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentFormType(null);
    setNewTask({
      id: "",
      name: "",
      start: "",
      end: "",
      progress: 0,
      dependencies: ["0"],
      assignee: "",
      type: "",
      parentId: "",
    });
    setValidationStatus(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prevTask) => ({
      ...prevTask,
      [name]:
        name === "dependencies"
          ? value.split(",")?.map((dep) => dep.trim())
          : value,
    }));
  };

  const handleAddTask = async () => {
    const { name, assignee, start, end, progress, parentId, dependencies } =
      newTask;

    const isFormValid =
      name &&
      assignee &&
      start !== new Date() &&
      end &&
      progress !== null &&
      progress >= 0 &&
      progress <= 100 &&
      (currentFormType !== "task" || (parentId && dependencies.length > 0));

    if (isFormValid) {
      setValidationStatus(true);

      const startDate = new Date(newTask.start);
      const endDate = new Date(newTask.end);
      const taskToAdd = {
        ...newTask,
        start: startDate,
        end: endDate,
        dependencies: newTask.dependencies || ["0"],
        parentId: newTask.parentId || "0",
      };
      const filterInitialElement = tasks.filter(
        (task) => task.name !== "Create Gantt Chart"
      );
      setTasks([...filterInitialElement, taskToAdd]);
      handleCloseModal();

      try {
        const response = await axiosPrivate.put("/gantt-chart/15", {
          gantt_chart_json: JSON.stringify([
            ...filterInitialElement,
            taskToAdd,
          ]),
        });

        handleCloseModal();
      } catch (error) {
        console.error("Error PUT :", error);
      }
    } else {
      setValidationStatus(false);
    }
  };

  const handleEditTask = async () => {
    const updatedTask = {
      ...newTask,
      start: new Date(newTask?.start),
      end: new Date(newTask?.end),
    };
    const taskIndex = tasks?.findIndex((task) => task?.id === newTask?.id);
    if (taskIndex === -1) {
      console.error("Task with ID", newTask?.id, "not found for editing.");
      return;
    }
    const updatedTasks = [...tasks];
    updatedTasks[taskIndex] = updatedTask;
    setTasks(updatedTasks);
    handleCloseModal();
    try {
      await axiosPrivate.put("/gantt-chart/15", {
        gantt_chart_json: JSON.stringify(updatedTasks),
      });
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const deleteTask = async (taskForDelete) => {
    let updatedTasks = [];

    if (taskForDelete.type === "task") {
      updatedTasks = tasks.filter((task) => task?.id !== taskForDelete.id);
    } else if (taskForDelete.type === "project") {
      updatedTasks = tasks.filter(
        (task) =>
          task?.id !== taskForDelete.id && task?.parentId !== taskForDelete.id
      );
    }

    setTasks(updatedTasks);
    handleCloseModal();

    try {
      await axiosPrivate.put("/gantt-chart/15", {
        gantt_chart_json: JSON.stringify(updatedTasks),
      });
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const openDeleteConfirmModal = (task) => {
    setTaskToDelete(task);
    setShowDeleteConfirmModal(true);
  };

  const closeDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(false);
    setTaskToDelete(null);
  };

  const confirmDeleteTask = () => {
    if (taskToDelete) {
      deleteTask(taskToDelete);
    }
    closeDeleteConfirmModal();
  };

  const handleDeleteButtonClick = (task) => {
    openDeleteConfirmModal(task);
  };

  const handleProgressChange = async (task) => {
    const updatedTasks = tasks.map((t) =>
      t.id === task.id ? { ...t, progress: task.progress } : t
    );
    setTasks(updatedTasks);
    try {
      await axiosPrivate.put("/gantt-chart/15", {
        gantt_chart_json: JSON.stringify(updatedTasks),
      });
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  };

  const onDateChange = async (updatedTask) => {
    const { id, start, end } = updatedTask;
    const newStart = new Date(start);
    const newEnd = new Date(end);
    const updatedTasks = tasks.map((task) =>
      task.id === id ? { ...task, start: newStart, end: newEnd } : task
    );
    setTasks(updatedTasks);

    try {
      await axiosPrivate.put("/gantt-chart/15", {
        gantt_chart_json: JSON.stringify(updatedTasks),
      });
    } catch (error) {
      console.error("Error updating dates:", error);
    }
  };

  const toolTipContent = (task) => {
    return (
      <div
        style={{
          fontSize: "12px",
          border: "1px solid #ccc",
          opacity: "80%",
          borderRadius: "5px",
          backgroundColor: "white",
          padding: "4px 7px",
        }}
      >
        <span>
          <strong>Name:</strong> {task?.task?.name}
        </span>
        <br />
        <hr style={{ padding: "0", margin: "2px" }} />
        <span>
          <strong>Assignee:</strong> {task?.task?.assignee}
        </span>
        <br />
        <hr style={{ padding: "0", margin: "2px" }} />
        <span>
          <strong>Progress:</strong> {task?.task?.progress}%
        </span>
        {task?.task?.type === "task" && (
          <>
            <hr style={{ padding: "0", margin: "2px" }} />
            <span>
              <strong>Task For:</strong>{" "}
              {task?.task?.parentId
                ? tasks.find((t) => t.id === task.task.parentId)?.name ||
                  "Parent Task Not Found"
                : "No Parent"}
            </span>

            {task?.task?.dependencies.length !== 0 && (
              <>
                <hr style={{ padding: "0", margin: "2px" }} />
                <span>
                  <strong>Dependencies: </strong>
                  {task?.task?.dependencies.length > 0
                    ? task.task.dependencies
                        .map((depId) => tasks.find((t) => t.id === depId)?.name)
                        .join(", ")
                    : " No Dependencies"}
                </span>
              </>
            )}
          </>
        )}

        <hr style={{ padding: "0", margin: "2px" }} />
        <span>
          <strong>ID:</strong> {task?.task?.id}
        </span>
      </div>
    );
  };

  const fetchGanttData = async () => {
    try {
      const response = await axiosPrivate.get("/gantt-chart/15");
      const data = response?.data?.data;
      const parseData = JSON.parse(data[0]?.gantt_chart_json);
      if (parseData && parseData?.length > 0) {
        setTasks((prev) => {
          const updatedData = parseData
            .filter((task) => task?.name !== "Create Gantt Chart")
            .map((task) => ({
              ...task,
              start: new Date(task.start),
              end: new Date(task.end),
            }));
          return updatedData;
        });
      }
    } catch (error) {
      console.error("Error Get:", error);
    }
  };

  useEffect(() => {
    fetchGanttData();
  }, []);

  const showAndHideChart = () => {
    return (
      tasks.length >= 1 &&
      tasks.some((task) => task?.name !== "Create Gantt Chart")
    );
  };

  return (
    <div className="content">
      <div className={styles.pageHeading}>
        <h4 className="m-1 pl-3">Create Timeline</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between mb-3">
            {/* add task and subtask button */}
            <div>
              <button
                type="button"
                className="btn btn-primary"
                style={{ backgroundColor: "rgb(89, 169, 133)" }}
                onClick={() => handleOpenModal("project")}
              >
                Add New Project
              </button>
              {showAndHideChart() && (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "rgb(163, 163, 255)" }}
                  onClick={() => handleOpenModal("task")}
                >
                  Add New Task
                </button>
              )}
            </div>

            {/* view buttons */}
            {showAndHideChart() && (
              <div className="btn-group" role="group" aria-label="View Mode">
                <button
                  type="button"
                  className={`btn ${
                    viewMode === ViewMode.Day ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => setViewMode(ViewMode.Day)}
                >
                  Day
                </button>
                <button
                  type="button"
                  className={`btn ${
                    viewMode === ViewMode.Week ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => setViewMode(ViewMode.Week)}
                >
                  Week
                </button>
                <button
                  type="button"
                  className={`btn ${
                    viewMode === ViewMode.Month
                      ? "btn-primary"
                      : "btn-secondary"
                  }`}
                  onClick={() => setViewMode(ViewMode.Month)}
                >
                  Month
                </button>
              </div>
            )}
          </div>

          {/* form modal */}
          <div
            className={`modal fade ${showModal ? "show d-block" : ""}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden={!showModal}
            style={{ display: showModal ? "block" : "none" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div
                className="modal-content"
                style={{ backgroundColor: "#f4f4f4" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title">
                    {editedTask
                      ? currentFormType === "project"
                        ? "Edit Project"
                        : "Edit Task"
                      : currentFormType === "project"
                      ? "Add New Project"
                      : "Add New Task"}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseModal}
                    aria-label="Close"
                    style={{ fontSize: "30px" }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form>
                  <div className="modal-body row">
                    <div className="col-6 mt-2">
                      <label htmlFor="name" className="form-label">
                        {currentFormType === "project" ? "Project " : "Task "}
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`Add ${
                          currentFormType === "project" ? " project " : " task "
                        } name`}
                        id="name"
                        name="name"
                        value={newTask?.name}
                        onChange={handleChange}
                        required
                      />
                      {validationStatus && newTask?.name === "" ? (
                        <p className={styles.requireField}>
                          Enter
                          {currentFormType === "project"
                            ? " project "
                            : " task "}
                          name
                        </p>
                      ) : null}
                    </div>
                    <div className="col-6 mt-2">
                      <label htmlFor="assignee" className="form-label">
                        {currentFormType === "project" ? "Project " : "Task "}
                        Assign To
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add Assignee Name"
                        name="assignee"
                        id="assignee"
                        value={newTask?.assignee}
                        onChange={handleChange}
                        required
                      />
                      {validationStatus && newTask?.assignee === "" ? (
                        <p className={styles.requireField}>
                          Enter{" "}
                          {currentFormType === "project" ? "project " : "task "}{" "}
                          assignee name
                        </p>
                      ) : null}
                    </div>
                    <div className="col-6 mt-2">
                      <label htmlFor="start" className="form-label">
                        Start Date
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="start"
                        name="start"
                        value={newTask?.start}
                        onChange={handleChange}
                        required
                      />
                      {validationStatus &&
                      new Date(newTask?.start).toISOString().split("T")[0] ===
                        new Date().toISOString().split("T")[0] ? (
                        <p className={styles.requireField}>Enter start date</p>
                      ) : null}
                    </div>
                    <div className="col-6 mt-2">
                      <label htmlFor="end" className="form-label">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="end"
                        name="end"
                        value={newTask?.end}
                        onChange={handleChange}
                        required
                      />
                      {validationStatus && (
                        <>
                          {new Date(newTask?.end)
                            .toISOString()
                            .split("T")[0] ===
                            new Date().toISOString().split("T")[0] && (
                            <p className={styles.requireField}>
                              Enter a valid end date
                            </p>
                          )}
                          {new Date(newTask?.start) >=
                            new Date(newTask?.end) && (
                            <p className={styles.requireField}>
                              End date must be greater than start date
                            </p>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-6 mt-2">
                      <label htmlFor="progress" className="form-label">
                        Progress (%)
                      </label>
                      <input
                        type="number"
                        id="progress"
                        name="progress"
                        className="form-control"
                        placeholder="Add Progress"
                        value={newTask?.progress}
                        onChange={handleChange}
                        required
                      />
                      {validationStatus &&
                      (newTask?.progress === null ||
                        newTask?.progress < 0 ||
                        newTask?.progress > 100) ? (
                        <p className={styles.requireField}>
                          Progress must be between 0 and 100
                        </p>
                      ) : null}
                    </div>
                    {currentFormType === "task" && (
                      <>
                        <div className="col-6 mt-2">
                          <label htmlFor="parentId" className="form-label">
                            Task for
                          </label>
                          <select
                            className="form-control"
                            id="parentId"
                            name="parentId"
                            onChange={handleChange}
                            value={newTask?.parentId}
                            required
                          >
                            <option value="">Select</option>
                            {tasks
                              ?.filter((task) => task?.type === "project")
                              ?.map((task) => (
                                <option key={task?.id} value={task?.id}>
                                  {task?.name}
                                </option>
                              ))}
                          </select>
                          {validationStatus && newTask?.parentId === "" ? (
                            <p className={styles.requireField}>
                              Enter task for
                            </p>
                          ) : null}
                        </div>
                        <div className="col-6 mt-2">
                          <label htmlFor="dependencies" className="form-label">
                            Task Dependencies
                          </label>
                          <select
                            className="form-control"
                            id="dependencies"
                            name="dependencies"
                            onChange={handleChange}
                            value={newTask?.dependencies || ["0"]}
                            required
                          >
                            <option value="" selected>
                              Select
                            </option>
                            <option value="">None</option>
                            <option value={newTask?.parentId}>
                              {newTask?.parentId &&
                                tasks?.find(
                                  (task) => task?.id === newTask?.parentId
                                )?.name}
                            </option>

                            {tasks
                              ?.filter(
                                (task) =>
                                  task?.type === "task" &&
                                  task?.parentId === newTask?.parentId
                              )
                              ?.map((task) => (
                                <>
                                  <option key={task?.id} value={task?.id}>
                                    {task?.name}
                                  </option>
                                </>
                              ))}
                          </select>
                          {validationStatus &&
                          newTask?.dependencies.length === 0 ? (
                            <p className={styles.requireField}>
                              Enter Task dependencies
                            </p>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                </form>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                  {editedTask && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteButtonClick(editedTask)}
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="submit"
                    style={{
                      backgroundColor: editedTask
                        ? currentFormType === "project"
                          ? "rgb(250, 196, 101)"
                          : "rgb(250, 196, 101)"
                        : currentFormType === "project"
                        ? "rgb(89, 169, 133)"
                        : "rgb(163, 163, 255)",
                    }}
                    className="btn btn-primary"
                    onClick={editedTask ? handleEditTask : handleAddTask}
                  >
                    {editedTask
                      ? currentFormType === "project"
                        ? "Update"
                        : "Save"
                      : currentFormType === "project"
                      ? "Add Project"
                      : "Add Task"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* confirmation modal */}
          <div
            className={`modal fade ${
              showDeleteConfirmModal ? "show d-block" : ""
            }`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="deleteConfirmModalLabel"
            aria-hidden={!showDeleteConfirmModal}
            style={{ display: showDeleteConfirmModal ? "block" : "none" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="deleteConfirmModalLabel">
                    Confirm Deletion
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={closeDeleteConfirmModal}
                    aria-label="Close"
                    style={{ fontSize: "30px" }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {editedTask?.type === "task" ? (
                    <p>
                      Are you sure you want to delete task
                      <strong>{editedTask?.name}</strong>?
                    </p>
                  ) : (
                    <p>
                      When you delete <strong>{editedTask?.name}</strong> then
                      all sub-tasks will also be deleted.
                    </p>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeDeleteConfirmModal}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={confirmDeleteTask}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showAndHideChart() ? (
            <GanttOriginal
              tasks={tasks.map((task) => ({
                ...task,
                dependencies: task?.dependencies || ["0"],
              }))}
              viewMode={viewMode}
              TaskListHeader={TaskListHeader}
              TaskListTable={TaskListTable}
              CalendarHeader={CalendarHeader}
              Grid={Grid}
              BarDisplay={BarDisplay}
              VerticalScroll={VerticalScroll}
              HorizontalScroll={HorizontalScroll}
              TooltipContent={(e) => toolTipContent(e)}
              onDoubleClick={(task) => {
                const type = task?.type === "project" ? "project" : "task";
                handleOpenModal(type, task);
              }}
              onProgressChange={handleProgressChange}
              onDateChange={onDateChange}
              columnWidth={60}
              ganttHeight={570}
            />
          ) : (
            <p>Please add new projects </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default Gantt;
