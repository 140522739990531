import { Rowing } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import BOMControlPanel from "../Bom/BOMControlPanel.jsx";
import DataSheetControlPanel from "../../DataSheet/DataSheetControlPanel.jsx";
import {
  customStyles,
  customStylesForDisabled,
} from "../../../styles/CustomStylesForSelect.jsx";
import {
  ipRatingOptions,
  controlPanelSupplyVoltageOptions,
  controlPanelBreakingCapaciy,
  typeOfControllingOptions,
  controlPanelCableEntry,
  controlPanelBusBarMaterial,
  controlPanelPackingType,
  enclosureMake,
  exclosureCompartment,
  exclosureSheathThickness,
  controlPanelSILValues,
  areaClassificationOptions,
  thyristorLegOptions,
} from "../../../utils/DropdownOptions.jsx";
import NonEditableBankBundleTable from "../../datatable/NonEditableBankBundleTable.jsx";
import ControlPanel from "../Bom/ControlPanel.jsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function Screen10({ formik, isPageComplete }) {
  const installedCapacity = parseFloat(formik.values.installedCapacity) || 0;
  const totalBank = parseInt(formik.values.bank) || 0;
  const effectiveVessel = parseInt(formik.values.multistackvessel) || 1;
  const bundleHeatLoad = installedCapacity / effectiveVessel;

  const [renderTable, setRenderTable] = useState(0);

  const handleInputChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    const updatedTableData = [...formik.values.bundleTableData];
    updatedTableData[rowIndex].bundle[colIndex].bank = value;

    const total =
      bundleHeatLoad - updatedTableData[rowIndex].bundle[colIndex].bank;
    const bank = formik.values.bank - 1;
    const eachData = total / bank;
    updatedTableData[rowIndex].bundle.map((data, index) => {
      if (index !== colIndex) {
        data.bank = eachData;
      }
    });

    formik.setFieldValue("bundleTableData", updatedTableData);
  };

  const printPDF = (elementId) => {
    const input = document.getElementById(elementId);
    const scale = 2 / window.devicePixelRatio;

    html2canvas(input, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("download.pdf");
    });
  };

  return (
    <div>
      {/* <div  style={{padding: "20px 70px" }}>
        <DataSheetControlPanel formik={formik} />
      </div>
      <hr />
      <hr />
      <hr />
      <hr />
      <hr />
      <hr />
      <hr /> */}
      {/* <div style={{ padding: "20px 70px" }}>
        <ControlPanel formik={formik} />
      </div>
      <hr />
      <hr />
      <hr />
      <hr />
      <hr />
      <hr />
      <hr />
      <div style={{ padding: "20px 70px" }}>
        <BOMControlPanel formik={formik} />
      </div>
      <hr /> */}
      <div className="mb-5 px-4">
        <div className="w-100 row justify-content-center mb-30">
          <label htmlFor="" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Control Panel
          </label>
          <div className="d-flex align-items-center" style={{ gap: "20px" }}>
            <input
              type="radio"
              id="cp_controlPanel"
              name="cp_controlPanel"
              checked={formik?.values?.cp_controlPanel === "yes"}
              onChange={() => formik.setFieldValue("cp_controlPanel", "yes")}
              value={formik?.values?.cp_controlPanel}
            />
            <label htmlFor="vesselYes">Yes</label>
            <input
              type="radio"
              id="cp_controlPanel"
              name="cp_controlPanel"
              checked={formik?.values?.cp_controlPanel === "no"}
              onChange={() => formik.setFieldValue("cp_controlPanel", "no")}
              value={formik?.values?.cp_controlPanel}
            />
            <label htmlFor="vesselNo">No</label>
          </div>
        </div>
        {formik?.values?.cp_controlPanel === "yes" && (
          <>
            <div className="w-100 row justify-content-center ">
              <div className="row w-100">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_Power">Power (Kw Unit)</label>
                  <input
                    type="text"
                    id="cp_Power"
                    name="cp_Power"
                    onChange={formik.handleChange}
                    value={formik.values.installedCapacity}
                    className="form-control"
                    placeholder="Enter power"
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_areaClassification">
                    Area Classification
                  </label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_areaClassification"
                    id="cp_areaClassification"
                    options={areaClassificationOptions}
                    placeholder="Select area classification"
                    value={
                      formik?.values?.cp_areaClassification
                        ? {
                            value: formik?.values?.cp_areaClassification,
                            label: formik?.values?.cp_areaClassification,
                          }
                        : null
                    }
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_areaClassification",
                        option ? option.value : ""
                      )
                    }
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_controlPanelMOC">Control Panel MOC</label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_controlPanelMOC"
                    id="cp_controlPanelMOC"
                    options={
                      formik?.values?.cp_areaClassification === "Hazardous Area"
                        ? [
                            {
                              value: "Aluminium Die Casted",
                              label: "Aluminium Die Casted",
                            },
                          ]
                        : [
                            {
                              value: "C.R.C.A",
                              label: "C.R.C.A",
                            },
                            { value: "SS304", label: "SS304" },
                            { value: "SS316", label: "SS316" },
                          ]
                    }
                    placeholder="Select Control Panel MOC"
                    onChange={(option) =>
                      formik?.setFieldValue(
                        "cp_controlPanelMOC",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_controlPanelMOC
                        ? {
                            label: formik?.values?.cp_controlPanelMOC,
                            value: formik?.values?.cp_controlPanelMOC,
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_paintShadeMOC">Paint Shade</label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_paintShadeMOC"
                    id="cp_paintShadeMOC"
                    options={[
                      {
                        value: "RAL 7032",
                        label: "RAL 7032",
                      },
                      {
                        value: "RAL 7035",
                        label: "RAL 7035",
                      },
                    ]}
                    placeholder="Select Control Panel Paint Shade"
                    onChange={(option) =>
                      formik?.setFieldValue(
                        "cp_paintShadeMOC",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_paintShadeMOC
                        ? {
                            label: formik?.values?.cp_paintShadeMOC,
                            value: formik?.values?.cp_paintShadeMOC,
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_controlPanelQuantity">
                    Control Panel Quantity
                  </label>
                  <input
                    type="number"
                    name="cp_controlPanelQuantity"
                    id="cp_controlPanelQuantity"
                    value={formik?.values?.cp_controlPanelQuantity}
                    className="form-control"
                    placeholder="Enter Control Panel Quantity"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_breakingCapacity">Breaking Capacity</label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_breakingCapacity"
                    id="cp_breakingCapacity"
                    options={controlPanelBreakingCapaciy}
                    placeholder="Select Breaking Capacity"
                    value={
                      formik?.values?.cp_breakingCapacity
                        ? {
                            value: formik?.values?.cp_breakingCapacity,
                            label: formik?.values?.cp_breakingCapacity,
                          }
                        : null
                    }
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_breakingCapacity",
                        option ? option.value : ""
                      )
                    }
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_ipRating">IP Rating</label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_ipRating"
                    placeholder="Select IP Rating"
                    id="cp_ipRating"
                    options={ipRatingOptions}
                    onChange={(option) =>
                      formik?.setFieldValue(
                        "cp_ipRating",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik.values.cp_ipRating
                        ? {
                            label: formik.values.cp_ipRating,
                            value: formik.values.cp_ipRating,
                          }
                        : null
                    }
                    onBlur={formik?.handleBlur}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_voltage">Voltage (Volt)</label>
                  <input
                    type="text"
                    id="cp_voltage"
                    name="cp_voltage"
                    onChange={formik.handleChange}
                    value={formik?.values?.voltage}
                    className="form-control"
                    placeholder="Enter voltage"
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_controlSupplyVoltage">
                    Control Supply Voltage
                  </label>
                  <Select
                    styles={customStyles}
                    isClearable
                    name="cp_controlSupplyVoltage"
                    id="cp_controlSupplyVoltage"
                    options={controlPanelSupplyVoltageOptions}
                    defaultValue={{ value: "240 A.C", label: "240 A.C" }}
                    placeholder="Select Control Supply Voltage"
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_controlSupplyVoltage",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_controlSupplyVoltage
                        ? {
                            value: formik?.values?.cp_controlSupplyVoltage,
                            label: formik?.values?.cp_controlSupplyVoltage,
                          }
                        : null
                    }
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_thyristorPanelSelection">
                    Thyristor Panel
                  </label>
                  <div className="">
                    <div className="row px-2 py-2">
                      <input
                        type="radio"
                        id="cp_thyristorPanelSelection"
                        name="cp_thyristorPanelSelection"
                        className="row gap-3 ml-0"
                        checked={
                          formik?.values?.cp_thyristorPanelSelection === "yes"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "cp_thyristorPanelSelection",
                            "yes"
                          )
                        }
                        value={formik?.values?.cp_thyristorPanelSelection}
                      />
                      <label htmlFor="cp_thyristorPanelSelection">Yes</label>
                      <input
                        type="radio"
                        id="cp_thyristorPanelSelection"
                        name="cp_thyristorPanelSelection"
                        className="row gap-3 ml-5"
                        checked={
                          formik?.values?.cp_thyristorPanelSelection ==
                          " Contactor Control"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "cp_thyristorPanelSelection",
                            " Contactor Control"
                          )
                        }
                        value={formik?.values?.cp_thyristorPanelSelection}
                      />
                      <label htmlFor="cp_thyristorPanelSelection">No</label>
                    </div>
                    {formik?.values?.cp_thyristorPanelSelection === "yes" && (
                      <>
                        <div className="">
                          <label>PID Controller</label>
                          <div className="row py-2">
                            <input
                              type="radio"
                              id="pid_controller_yes"
                              name="cp_thyristorPanel"
                              checked={
                                formik?.values?.cp_thyristorPanel ===
                                "With PID Controller"
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  "cp_thyristorPanel",
                                  "With PID Controller"
                                );
                                formik.setFieldValue(
                                  "cp_processTemperatureController",
                                  1
                                );
                              }}
                              value="With PID Controller"
                            />
                            <label htmlFor="pid_controller_yes">Yes</label>
                            <input
                              type="radio"
                              id="pid_controller_no"
                              name="cp_thyristorPanel"
                              checked={
                                formik?.values?.cp_thyristorPanel ===
                                "Without PID Controller"
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  "cp_thyristorPanel",
                                  "Without PID Controller"
                                );
                                formik.setFieldValue(
                                  "cp_processTemperatureController",
                                  ""
                                );
                              }}
                              value="Without PID Controller"
                            />
                            <label htmlFor="pid_controller_no">No</label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_softStart">Soft Start</label>
                  <div>
                    <div className="row px-2 py-2">
                      <input
                        type="radio"
                        name="cp_softStart"
                        className="row gap-3 ml-0"
                        value="yes"
                        checked={formik?.values?.cp_softStart === "yes"}
                        onChange={
                          formik?.values?.cp_softStart === "yes"
                            ? () => formik.setFieldValue("cp_softStart", "no")
                            : () => formik.setFieldValue("cp_softStart", "yes")
                        }
                      />{" "}
                      Yes
                      <input
                        type="radio"
                        name="cp_softStart"
                        value="no"
                        className="row gap-3 ml-5"
                        checked={formik?.values?.cp_softStart === "no"}
                        onChange={
                          formik?.values?.cp_softStart === "no"
                            ? () => formik.setFieldValue("cp_softStart", "yes")
                            : () => formik.setFieldValue("cp_softStart", "no")
                        }
                      />{" "}
                      No
                    </div>
                  </div>
                </div>

                {formik?.values?.cp_thyristorPanelSelection === "yes" && (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_thyristorLeg">Thyristor Leg</label>
                      <Select
                        isClearable
                        styles={customStyles}
                        name="cp_thyristorLeg"
                        id="cp_thyristorLeg"
                        options={thyristorLegOptions}
                        placeholder="Select Thyristor Leg"
                        value={
                          formik?.values?.cp_thyristorLeg
                            ? {
                                value: formik?.values?.cp_thyristorLeg,
                                label: formik?.values?.cp_thyristorLeg,
                              }
                            : null
                        }
                        onChange={(option) =>
                          formik.setFieldValue(
                            "cp_thyristorLeg",
                            option ? option.value : ""
                          )
                        }

                        // // isClearable
                      />
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_thyristorFiringType">
                        Thyristor Firing Type
                      </label>
                      <Select
                        isClearable
                        styles={customStyles}
                        name="cp_thyristorFiringType"
                        id="cp_thyristorFiringType"
                        options={
                          formik?.values?.cp_thyristorLeg === "2 Lag"
                            ? [{ value: "Burst Firing", label: "Burst Firing" }]
                            : [
                                {
                                  value: "Burst Firing",
                                  label: "Burst Firing",
                                },
                                {
                                  value: "Single Cycle Zero Cross Over",
                                  label: "Single Cycle Zero Cross Over",
                                },
                                {
                                  value: "3 Phase Angle Firing",
                                  label: "3 Phase Angle Firing",
                                },
                              ]
                        }
                        placeholder="Select Thyristor Firing Type"
                        onChange={(option) =>
                          formik.setFieldValue(
                            "cp_thyristorFiringType",
                            option ? option.value : ""
                          )
                        }
                        value={
                          formik?.values?.cp_thyristorFiringType
                            ? {
                                value: formik?.values?.cp_thyristorFiringType,
                                label: formik?.values?.cp_thyristorFiringType,
                              }
                            : null
                        }
                      />
                    </div>
                  </>
                )}

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_inputTypeOfController">
                    Input Type Of Controller
                  </label>
                  <div className="row mt-10">
                    <input
                      type="radio"
                      id="cp_inputTypeOfController"
                      name="cp_inputTypeOfController"
                      className="row gap-3"
                      checked={
                        formik?.values?.cp_inputTypeOfController === "RTD"
                      }
                      onChange={() =>
                        formik.setFieldValue("cp_inputTypeOfController", "RTD")
                      }
                      value={formik?.values?.cp_inputTypeOfController}
                    />
                    <label htmlFor="cp_inputTypeOfController">RTD</label>
                  </div>
                  <div className="row">
                    <input
                      type="radio"
                      id="cp_inputTypeOfController"
                      name="cp_inputTypeOfController"
                      className="row gap-3"
                      checked={
                        formik?.values?.cp_inputTypeOfController === "MILI AMP"
                      }
                      onChange={() =>
                        formik.setFieldValue(
                          "cp_inputTypeOfController",
                          "MILI AMP"
                        )
                      }
                      value={formik?.values?.cp_inputTypeOfController}
                    />
                    <label htmlFor="cp_inputTypeOfController">MILI AMP</label>
                  </div>
                  <div className="row">
                    <input
                      type="radio"
                      id="cp_inputTypeOfController"
                      name="cp_inputTypeOfController"
                      className="row gap-3"
                      checked={
                        formik?.values?.cp_inputTypeOfController ===
                        "THERMOCOUPLE"
                      }
                      onChange={() =>
                        formik.setFieldValue(
                          "cp_inputTypeOfController",
                          "THERMOCOUPLE"
                        )
                      }
                      value={formik?.values?.cp_inputTypeOfController}
                    />
                    <label htmlFor="cp_inputTypeOfController">
                      THERMOCOUPLE
                    </label>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_typeTestRequirement">
                    Type Test Requirement
                  </label>
                  <div className="row px-2 py-2">
                    <input
                      type="radio"
                      name="cp_typeTestRequirement"
                      value="yes"
                      className="row gap-3 ml-0"
                      checked={formik?.values?.cp_typeTestRequirement === "yes"}
                      onChange={
                        formik?.values?.cp_typeTestRequirement === "yes"
                          ? () =>
                              formik.setFieldValue("cp_typeTestRequirement", "")
                          : () =>
                              formik.setFieldValue(
                                "cp_typeTestRequirement",
                                "yes"
                              )
                      }
                    />{" "}
                    Yes
                    <input
                      type="radio"
                      name="cp_typeTestRequirement"
                      value="no"
                      className="row gap-3 ml-5"
                      checked={formik?.values?.cp_typeTestRequirement === "no"}
                      onChange={
                        formik?.values?.cp_typeTestRequirement === "no"
                          ? () =>
                              formik.setFieldValue("cp_typeTestRequirement", "")
                          : () =>
                              formik.setFieldValue(
                                "cp_typeTestRequirement",
                                "no"
                              )
                      }
                    />{" "}
                    No
                  </div>
                  {formik?.values?.cp_typeTestRequirement === "yes" && (
                    <input
                      type="text"
                      name="cp_typeTestStandard"
                      value="IEC61439"
                      readOnly
                      className="form-control"
                    />
                  )}
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_incomerBusDust">Incomer Bus Duct</label>
                  <div className="row px-2 py-2">
                    <input
                      type="radio"
                      name="cp_incomerBusDust"
                      value="yes"
                      className="row gap-3 ml-0"
                      checked={formik?.values?.cp_incomerBusDust === "yes"}
                      onChange={
                        formik?.values?.cp_incomerBusDust === "yes"
                          ? () =>
                              formik.setFieldValue("cp_incomerBusDust", "no")
                          : () =>
                              formik.setFieldValue("cp_incomerBusDust", "yes")
                      }
                    />{" "}
                    Yes
                    <input
                      type="radio"
                      name="cp_incomerBusDust"
                      value="no"
                      checked={formik?.values?.cp_incomerBusDust === "no"}
                      className="row gap-3 ml-5"
                      onChange={
                        formik?.values?.cp_incomerBusDust === "no"
                          ? () =>
                              formik.setFieldValue("cp_incomerBusDust", "yes")
                          : () =>
                              formik.setFieldValue("cp_incomerBusDust", "no")
                      }
                    />{" "}
                    No
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_frequency">Frequency (Hz)</label>
                  <input
                    type="text"
                    id="cp_frequency"
                    name="cp_frequency"
                    onChange={formik.handleChange}
                    value={formik.values.frequency}
                    className="form-control"
                    placeholder="Enter frequency"
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_typeOfControlling">
                    Type of Controlling
                  </label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_typeOfControlling"
                    id="cp_typeOfControlling"
                    options={typeOfControllingOptions}
                    placeholder="Type of Controlling"
                    value={
                      formik?.values?.cp_typeOfControlling
                        ? {
                            value: formik?.values?.cp_typeOfControlling,
                            label: formik?.values?.cp_typeOfControlling,
                          }
                        : null
                    }
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_typeOfControlling",
                        option ? option.value : ""
                      )
                    }
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_cableEntry">Cable Entry</label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_cableEntry"
                    id="cp_cableEntry"
                    options={controlPanelCableEntry}
                    placeholder="Select Cable Entry"
                    // defaultValue={{ value: "Top", label: "Top" }}
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_cableEntry",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_cableEntry
                        ? {
                            value: formik?.values?.cp_cableEntry,
                            label: formik?.values?.cp_cableEntry,
                          }
                        : null
                    }
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_skinTemperatureController">
                    Skin Temperature Controller
                  </label>
                  <input
                    type="number"
                    name="cp_skinTemperatureController"
                    id="cp_skinTemperatureController"
                    value={formik?.values?.cp_skinTemperatureController}
                    className="form-control"
                    placeholder="Skin Temperature Controller"
                    onChange={formik.handleChange}
                    onBlur={formik?.handleBlur}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_tubeSheetTemperatureController">
                    Tube Sheet Temperature Controller
                  </label>
                  <input
                    type="text"
                    name="cp_tubeSheetTemperatureController"
                    id="cp_tubeSheetTemperatureController"
                    value={formik?.values?.cp_tubeSheetTemperatureController}
                    onChange={formik?.handleChange}
                    className="form-control"
                    placeholder="Tube Sheet Temperature"
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_processTemperatureController">
                    Process Temperature Controller
                  </label>
                  <input
                    type="number"
                    name="cp_processTemperatureController"
                    id="cp_processTemperatureController"
                    value={formik?.values?.cp_processTemperatureController}
                    className="form-control"
                    placeholder="Process Temperature Controller"
                    onChange={formik.handleChange}
                    onBlur={formik?.handleBlur}
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_busBarMaterial">Bus Bar Material</label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_busBarMaterial"
                    id="cp_busBarMaterial"
                    options={controlPanelBusBarMaterial}
                    // defaultValue={{ value: "Copper", label: "Copper" }}
                    placeholder="Select Bus Bar Material"
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_busBarMaterial",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_busBarMaterial
                        ? {
                            value: formik?.values?.cp_busBarMaterial,
                            label: formik?.values?.cp_busBarMaterial,
                          }
                        : null
                    }
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_packingType">Packing Type</label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_packingType"
                    id="cp_packingType"
                    options={controlPanelPackingType}
                    // defaultValue={{ value: "Wooden", label: "Wooden" }}
                    placeholder="Select Packing Type"
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_packingType",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_packingType
                        ? {
                            value: formik?.values?.cp_packingType,
                            label: formik?.values?.cp_packingType,
                          }
                        : null
                    }
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_enclosureMake">Enclosure Make</label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_enclosureMake"
                    id="cp_enclosureMake"
                    options={enclosureMake}
                    placeholder="Select Enclosure Make"
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_enclosureMake",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_enclosureMake
                        ? {
                            value: formik?.values?.cp_enclosureMake,
                            label: formik?.values?.cp_enclosureMake,
                          }
                        : null
                    }
                    onBlur={formik?.handleBlur}
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_enclosureSizeWidth">
                    Enclosure Size (Width)
                  </label>
                  <input
                    type="number"
                    name="cp_enclosureSizeWidth"
                    id="cp_enclosureSizeWidth"
                    value={formik?.values?.cp_enclosureSizeWidth}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Enclosure Size (Width)"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_enclosureSizeDepth">
                    Enclosure Size (Depth)
                  </label>
                  <input
                    type="number"
                    name="cp_enclosureSizeDepth"
                    id="cp_enclosureSizeDepth"
                    value={formik?.values?.cp_enclosureSizeDepth}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Enclosure Size (Depth)"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_enclosureSizeHeight">
                    Enclosure Size (Height)
                  </label>
                  <input
                    type="number"
                    name="cp_enclosureSizeHeight"
                    id="cp_enclosureSizeHeight"
                    value={formik?.values?.cp_enclosureSizeHeight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Enclosure Size (Height)"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_enclosureCompartment">
                    Enclosure Compartment
                  </label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_enclosureCompartment"
                    id="cp_enclosureCompartment"
                    options={exclosureCompartment}
                    placeholder="Enclosure Compartment"
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_enclosureCompartment",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_enclosureCompartment
                        ? {
                            value: formik?.values?.cp_enclosureCompartment,
                            label: formik?.values?.cp_enclosureCompartment,
                          }
                        : null
                    }
                    onBlur={formik?.handleBlur}
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_enclosureSheathThickness">
                    Enclosure Sheath Thickness
                  </label>
                  <Select
                    isClearable
                    styles={customStyles}
                    name="cp_enclosureSheathThickness"
                    id="cp_enclosureSheathThickness"
                    options={exclosureSheathThickness}
                    placeholder="Select Sheath Thickness"
                    onChange={(option) =>
                      formik.setFieldValue(
                        "cp_enclosureSheathThickness",
                        option ? option.value : ""
                      )
                    }
                    value={
                      formik?.values?.cp_enclosureSheathThickness
                        ? {
                            value: formik?.values?.cp_enclosureSheathThickness,
                            label: formik?.values?.cp_enclosureSheathThickness,
                          }
                        : null
                    }
                    onBlur={formik?.handleBlur}
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <label htmlFor="cp_sil22Requirement">
                    Safety Relay Requirement
                  </label>
                  <div className="row px-2 py-2">
                    <input
                      type="radio"
                      name="cp_sil22Requirement"
                      value="yes"
                      className="row gap-3 ml-0"
                      checked={formik?.values?.cp_sil22Requirement === "yes"}
                      onChange={
                        formik?.values?.cp_sil22Requirement === "yes"
                          ? () =>
                              formik.setFieldValue("cp_sil22Requirement", "no")
                          : () =>
                              formik.setFieldValue("cp_sil22Requirement", "yes")
                      }
                    />{" "}
                    Yes
                    <input
                      type="radio"
                      name="cp_sil22Requirement"
                      value="no"
                      className="row gap-3 ml-5"
                      checked={formik?.values?.cp_sil22Requirement === "no"}
                      onChange={
                        formik?.values?.cp_sil22Requirement === "no"
                          ? () =>
                              formik.setFieldValue("cp_sil22Requirement", "yes")
                          : () =>
                              formik.setFieldValue("cp_sil22Requirement", "no")
                      }
                    />{" "}
                    No
                  </div>
                  {formik?.values?.cp_sil22Requirement === "yes" && (
                    <div className="mt-15">
                      <Select
                        isClearable
                        styles={customStyles}
                        name="cp_silvalue"
                        id="cp_silvalue"
                        options={controlPanelSILValues}
                        placeholder="Select SIL Value"
                        onChange={(option) =>
                          formik.setFieldValue(
                            "cp_silvalue",
                            option ? option.value : ""
                          )
                        }
                        value={
                          formik?.values?.cp_silvalue
                            ? {
                                value: formik?.values?.cp_silvalue,
                                label: formik?.values?.cp_silvalue,
                              }
                            : null
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex col-md-6 col-lg-6 col-sm-12 ml-0 pl-0">
              <NonEditableBankBundleTable formik={formik} />
            </div>
          </>
        )}
      </div>
      <hr />
      <div className="my-5 px-4">
        <div className="d-flex justify-content-between">
          <div>
            <button
              className={`btn ${
                renderTable === 1 ? "btn-danger" : "btn-outline-danger"
              }`}
              onClick={(e) => {
                e.preventDefault();
                renderTable === 1 ? setRenderTable(0) : setRenderTable(1);
              }}
            >
              Control Panel Data Sheet
            </button>
            <button
              className={`btn ${
                renderTable === 2 ? "btn-danger" : "btn-outline-danger"
              }`}
              onClick={(e) => {
                e.preventDefault();
                renderTable === 2 ? setRenderTable(0) : setRenderTable(2);
              }}
            >
              Control Panel Bom
            </button>
          </div>

          {renderTable > 0 && (
            <div>
              <button
                className={`btn btn-outline-danger`}
                onClick={(e) => {
                  e.preventDefault();
                  printPDF(
                    renderTable === 1 ? "ControlPanel" : "BOMControlPanel"
                  );
                }}
              >
                Print PDF
              </button>
            </div>
          )}
        </div>
        { renderTable > 0 && (
          <div style={{ border: "1px solid gray", margin: "30px 100px " }}>
            {renderTable === 1 && (
              <div
                className="mt-30"
                id={"ControlPanel"}
                style={{ color: "black", padding: "20px 70px" }}
              >
                <ControlPanel formik={formik} />
              </div>
            )}
            {renderTable === 2 && (
              <div
                className="mt-30"
                id={"BOMControlPanel"}
                style={{ color: "black", padding: "20px 70px" }}
              >
                <BOMControlPanel formik={formik} />
              </div>
            )}
          </div>
        )}
      </div>

      <hr />
    </div>
  );
}

export default Screen10;
