import React from 'react'

const NonEditableBankBundleTable = ( {formik}) => {

    const totalBank = parseInt(formik?.values.bank) || 0;
  const bundleHeatLoad =
    parseFloat(formik?.values.installedCapacity) /
    (parseInt(formik?.values.multistackvessel) || "N/A");

  return (
    <div>{formik &&
        formik?.values?.multistackvessel &&
        formik?.values?.bank &&
        formik?.values?.heatDutyKW && (
            <table className="table border border-secondary w-100">
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                    }}
                  >
                    <strong>Bundle</strong>
                  </th>
                  {Array.from({
                    length: formik?.values?.bank,
                  }).map((_, i) => (
                    <th
                      key={i}
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                      }}
                    >
                      <strong>Bank {i + 1}</strong>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {formik?.values?.bundleTableData?.map(
                  (row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td
                        style={{
                          border: "1px solid #ccc",
                          padding: "8px",
                        }}
                      >
                        Bundle {rowIndex + 1} -
                        {bundleHeatLoad.toFixed(2)}
                      </td>
                      {row?.bundle.map((col, colIndex) => (
                        <td
                          key={colIndex}
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                          }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minWidth: "30px",
                              boxSizing: "border-box",
                              padding: "5px",
                            }}
                            name={`bundleTableData[${rowIndex}].bundle[${colIndex}].bank`}
                            id={`bundleTableData${rowIndex}.bundle${colIndex}.bank`}
                            type="text"
                            className="form-control"
                            // placeholder={(
                            //   bundleHeatLoad / totalBank
                            // ).toFixed(2)}
                            value={col.bank}
                            readOnly
                          />
                        </td>
                      ))}
                    </tr>
                  )
                )}
              </tbody>
            </table>
        )}
    </div>
  )
}

export default NonEditableBankBundleTable