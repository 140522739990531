import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BOM from "../Bom/BOM";

function Screen9({ formik }) {
  const trClass = {
    borderCollapse: "collapse",
    height: "22px",
  };

  const tdClass = {
    borderCollapse: "collapse",
    border: "1px solid black",
    height: "22px",
    padding: "0 4px",
    minWidth: "20px",
  };

  const tableClass = {
    width: "100%",
    border: "1px solid black",
    borderCollapse: "collapse",
  };

  const tableParentDiv = {
    display: "flex",
    margin: "10px",
    gap: "10px",
  };

  const printPDF = (elementId) => {
    const input = document.getElementById(elementId);
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("download.pdf");
    });
  };

  const powerelement = () => {
    const totalnumberofelements = formik?.values?.totalnumberofelements || 0;
    const power = formik?.values?.power || 0;
    const powerperelement = totalnumberofelements
      ? Math.round(power / totalnumberofelements)
      : 0;
    formik.setFieldValue("powerperelement", powerperelement);
  };

  const calculateHeatingElementAddValue = (
    thickness,
    distance,
    totalnumberofelements
  ) => {
    return Math.round(
      ((5 * ((thickness + 30 + distance) / 5) * 2 * totalnumberofelements) /
        1000 /
        totalnumberofelements /
        2) *
      1000
    );
  };

  const setHeatingElementValues = (values) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };

  const heatingElement = () => {
    const {
      fixingofheatingelements,
      totalnumberofelements,
      heatingElementThickness,
      distbwheaterflangetoterminalbox,
      heatersheathtubedia,
    } = formik.values;
    const totalElements = parseInt(totalnumberofelements) || 0;
    const thickness = parseFloat(heatingElementThickness) || 0;
    const distance = parseInt(distbwheaterflangetoterminalbox) || 0;

    const commonValues = {
      Sleeves: {
        heatingElementType: parseFloat(heatersheathtubedia) + 0.5,
        heatingElementValue: "ID",
        heatingElementUnit: parseFloat(heatersheathtubedia) + 0.5 + 4,
        heatingElememtAddUnit: "OD",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "Drilling & Sleeves Welding",
      },
      "Tig + Sleeves": {
        heatingElementType: parseFloat(heatersheathtubedia) + 0.5,
        heatingElementValue: "ID",
        heatingElementUnit: parseFloat(heatersheathtubedia) + 0.5 + 4,
        heatingElememtAddUnit: "OD",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "Drilling & Sleeves Welding",
      },
      Ferrule: {
        heatingElementType: "FOR",
        heatingElementValue: heatersheathtubedia,
        heatingElementUnit: "MM",
        heatingElememtAddUnit: "DIA",
        heatingElementAddValue: "HEATING ELEMENT",
        heatingElementAddValueUnit: "TING ELEMENT",
        heatingElementTubeSheet: "False",
      },
      Bush: {
        heatingElementType: "Bush",
        heatingElementValue: " '' ",
        heatingElementUnit: " ",
        heatingElememtAddUnit: "BUSH",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "False",
      },
      Direct: {
        heatingElementType: heatersheathtubedia,
        heatingElementValue: "DIA",
        heatingElementUnit: "FUSED",
        heatingElememtAddUnit: "DIRECT",
        heatingElementAddValue: " ",
        heatingElementAddValueUnit: " ",
        heatingElementTubeSheet: "Drilling & Grooving for Direct Fusing",
      },
      Nut: {
        heatingElementType: "2.25",
        heatingElementValue: "Nut",
        heatingElementUnit: " ",
        heatingElememtAddUnit: " ",
        heatingElementAddValue: " ",
        heatingElementAddValueUnit: " ",
        heatingElementTubeSheet: "Drilling & Collar Welding",
      },
    };

    const defaultValues = {
      heatingElementType: " ",
      heatingElementValue: " ",
      heatingElementUnit: " ",
      heatingElememtAddUnit: " ",
      heatingElementAddValue: " ",
      heatingElementAddValueUnit: " ",
      heatingElementTubeSheet: "False",
    };

    setHeatingElementValues(
      commonValues[fixingofheatingelements] || defaultValues
    );
  };
  // Function  to calculate the values for sending the payload for tie rod with sleeves
  const calculateBomLengths = (maxActiveHotLength, maxActiveColdLength, setFieldValue) => {
    const HotLengthCalculatedForBom = 10 * Math.round(((maxActiveHotLength * 2) + 10) / 10);

    const TotalDevelopedLengthForBom = HotLengthCalculatedForBom + 2 * maxActiveColdLength;

    // Update Formik fields
    setFieldValue("hotLengthCalculatedForBom", HotLengthCalculatedForBom);
    setFieldValue("totalDevelopedLengthForBom", TotalDevelopedLengthForBom);
  };
  useEffect(() => {
    calculateBomLengths(
      formik.values.maxactivehotlength,
      formik.values.maxactivecoldlength,
      formik.setFieldValue
    );
  }, [formik.values.maxactivehotlength, formik.values.maxactivecoldlength, formik.setFieldValue]);

  const netWeightPayload = {
    type: "flangeWeightForInch",
    data: [
      // 1 MATERIAL
      // 3 TUBE SHEET (HEATER FLANGE)
      // {
      //   flangeRating: formik?.values?.FlangeClass,
      //   nps: formik?.values?.flangeSize.toString(),
      // },
      //  5 SHELL RING
      // {
      //   flangeClass: formik?.values?.FlangeClass,
      //   flangeSize: formik.values.flangeSize,
      // },
      //  6 SHELL / VESSEL
      // {
      //   sch: parseInt(formik?.values?.schedule),
      //   nps: parseFloat(formik?.values?.flangeSize),
      //   schedule: parseInt(formik?.values?.schedule),
      // },
      //  7 DISH END "3.14*(response.data.dishend)*(response.data.dishend)*3*8*J6/4/1000000"
      {
        flangeSize: formik.values.flangeSize,
        key: "dishEnd",
        heading: "pipeod",
        thickness: formik.values.shellThickness,
      },

      //  8 - INLET NOZZEL
      formik?.values?.nozzle[0]?.inletNozzle && {
        key: "inletNozzle",
        flangeSize: formik.values.nozzle[0].size,
        schedule: formik.values.nozzle[0].schedules,
      },

      //  9 - INLET FLANGES
      formik?.values?.nozzle[0]?.inletNozzle && {
        key: "inletFlange",
        flangeSize: formik.values.nozzle[0].size,
        vesselRangeType: formik.values.nozzle[0].flangeType,
        flangeRating: formik.values.nozzle[0].flangeRating,
      },

      //  10 - OUTLET NOZZEL
      formik?.values?.nozzle[1]?.outletNozzle && {
        key: "outletNozzle",
        flangeSize: formik.values.nozzle[1].size,
        schedule: formik.values.nozzle[1].schedules,
      },

      // 11 - OUTLET FLANGES
      formik?.values?.nozzle[1]?.outletNozzle && {
        key: "outletFlange",
        flangeSize: formik.values.nozzle[1].size,
        vesselRangeType: formik.values.nozzle[1].flangeType,
        flangeRating: formik.values.nozzle[1].flangeRating,
      },

      //  12 - VENT NOZZLE
      formik?.values?.nozzle[2]?.vent && {
        key: "ventNozzle",
        flangeSize: formik.values.nozzle[2].size,
        schedule: formik.values.nozzle[2].schedules,
      },

      // 13 - VENT NOZZLE FLANGE
      formik?.values?.nozzle[2]?.vent && {
        key: "ventFlange",
        flangeSize: formik.values.nozzle[2].size,
        vesselRangeType: formik.values.nozzle[2].flangeType,
        flangeRating: formik.values.nozzle[2].flangeRating,
      },

      //  14 - DRAIN NOZZLE
      formik?.values?.nozzle[3]?.drain && {
        key: "drainNozzle",
        flangeSize: formik.values.nozzle[3].size,
        schedule: formik.values.nozzle[3].schedules,
      },

      //  15 - DRAIN NOZZLE FLANGE
      formik?.values?.nozzle[3]?.drain && {
        key: "drainFlange",
        flangeSize: formik.values.nozzle[3].size,
        vesselRangeType: formik.values.nozzle[3].flangeType,
        flangeRating: formik.values.nozzle[3].flangeRating,
      },

      //  16 - Process NOZZLES
      formik?.values?.nozzle[4]?.process && {
        key: "processNozzle",
        flangeSize: formik.values.nozzle[4].size,
        schedule: formik.values.nozzle[4].schedules,
      },

      //  17 - Process NOZZLE FLANGES
      formik?.values?.nozzle[4]?.process && {
        key: "processFlange",
        flangeSize: formik.values.nozzle[4].size,
        vesselRangeType: formik.values.nozzle[4].flangeType,
        flangeRating: formik.values.nozzle[4].flangeRating,
      },

      //  18 - DUMMY NOZZLES
      formik?.values?.nozzle[5]?.dummy && {
        key: "dummyNozzle",
        flangeSize: formik.values.nozzle[5].size,
        schedule: formik.values.nozzle[5].schedules,
      },
      //  19 - DUMMY NOZZLE FLANGES
      formik?.values?.nozzle[5]?.dummy && {
        key: "dummyFlange",
        flangeSize: formik.values.nozzle[5].size,
        vesselRangeType: formik.values.nozzle[5].flangeType,
        flangeRating: formik.values.nozzle[5].flangeRating,
      },
      //  20 - BAFFLE (3.14*(response.data.baffle)-10-2*(formik?.values?.shellThickness))*(formik.values.baffleplatethickness)*(formik.values.numberofHotBaffles)*8/1000000)/4)
      {
        // flangeRating: formik?.values?.FlangeClass,
        key: "baffle",
        heading: "pipeod",
        flangeSize: formik.values.flangeSize,
        bafflePlatethickness: formik.values.baffleplatethickness,
        shellThickness: formik.values.shellThickness,
        numberOfBaffles: formik.values.numberofHotBaffles,
        baffleType: formik.values.baffletype,
      },
      // 21. Tie Rod with Sleeves
      {
        key: "tieRodWithSleeves",
        hotLengthCalculatedForBom: formik.values.hotLengthCalculatedForBom,
        totalDevelopedLengthForBom: formik.values.totalDevelopedLengthForBom,
        numberOfHotBaffles: formik.values.numberofHotBaffles,
        tieRodDia: formik.values.tieroddia,
      },
      // 21 - TIE ROD WITH SLEEVES - (=ROUND((4*((formik.values.developedlength)/2-100)-(formik.values.maxactivehotlength)/2/(formik.values.numberofHotBaffles))*((formik.values.tieroddia)+4)*((formik.values.tieroddia)+4)*8*3.14/4/1000000,2))
      // 20 - LUGS SUPPORT
      {
        key: "lugSupport",
        type: "getLugWeight",
        heading: "pipeod",
        flangeSize: formik.values.flangeSize,
        insulationThickness: formik.values.insulationThickness,
      },
      // 21 - JUNCTION BOX =ROUND('Design Data'!AA20)
      {
        heading: "pipeod",
        key: "junctionBox",
        flangeSize: formik.values.flangeSize,
      },
      // Junction box weight if flp and flp terminal box + JB weight
      {
        key: "terBoxFabCost",
        baffleBom: formik.values.baffleBomValue,
        flangeSize: formik.values.flangeSize,
      },
      {
        key: "sideAttachedJb",
        baffleBom: formik.values.baffleBomValue,
        flangeSize: formik.values.flangeSize,
        stack: formik.values.multistackvessel,
      },

      // TER. BOX BASE PLATE IF FLP or TER BOX IF NON FLP
      {
        key: "terBoxBasePlate",
        flangeSize: formik.values.flangeSize,
        flpYesOrNo: formik?.values?.terminalBoxIfFlpOrNonFlp,
      },
      {
        key: "flpJbWeight",
        flpyesorno: formik?.values?.terminalBoxIfFlpOrNonFlp,
        flangeSize: formik.values.flangeSize,
      },
      // =ROUND((((IF(J5>24,CHOOSE((J5-25),AV71,,AV72,,AV73,,AV74,,AV75,,AV76,,AV77,,AV78),CHOOSE(IF(J5=1.5,5,IF(J5=2.5,7,IF(J5=3.5,9,J5))),AV55,AV57,AV59,AV61,AV56,AV62,AV58,AV63,AV60,AV64,,AV65,,AV66,,AV67,,AV68,,AV69,,,,AV70)))*2+(250*6)+(3.14*100))*3*8*(IF(J5>24,CHOOSE((J5-25),AV71,,AV72,,AV73,,AV74,,AV75,,AV76,,AV77,,AV78),CHOOSE(IF(J5=1.5,5,IF(J5=2.5,7,IF(J5=3.5,9,J5))),AV55,AV57,AV59,AV61,AV56,AV62,AV58,AV63,AV60,AV64,,AV65,,AV66,,AV67,,AV68,,AV69,,,,AV70)))/1000000),2)
      // (J5>24,CHOOSE((J5-25),AV71,,AV72,,AV73,,AV74,,AV75,,AV76,,AV77,,AV78),CHOOSE(IF(J5=1.5,5,IF(J5=2.5,7,IF(J5=3.5,9,J5))),AV55,AV57,AV59,AV61,AV56,AV62,AV58,AV63,AV60,AV64,,AV65,,AV66,,AV67,,AV68,,AV69,,,,AV70)))*2+(250*6)+(3.14*100))*3*8*(IF(J5>24,CHOOSE((J5-25),AV71,,AV72,,AV73,,AV74,,AV75,,AV76,,AV77,,AV78),CHOOSE(IF(J5=1.5,5,IF(J5=2.5,7,IF(J5=3.5,9,J5))),AV55,AV57,AV59,AV61,AV56,AV62,AV58,AV63,AV60,AV64,,AV65,,AV66,,AV67,,AV68,,AV69,,,,AV70)

      // xxxxxxxxxxxxxxxxxxxxxxxx 22 - TER. BOX BASE PLATE IF FLP or TER BOX IF NON FLP
      //Insulation Cladding Cylinder weights
      {
        key: "insulationCladdingCylinderWt",
        od: formik?.values?.pipeOD,
        vesselLength: formik?.values?.maxactivehotlength,
        claddingSheetThickness: formik?.values?.insulation.claddingSheetThickness,
        claddingSheetDensity: formik?.values?.insulation.claddingSheetDensity,
      },
      // Cladding Facing Plate Wt.
      {
        key: "claddingFacingPlateWt",
        od: formik?.values?.pipeOD,
        claddingSheetThickness: formik?.values?.insulation.claddingSheetThickness,
        claddingSheetDensity: formik?.values?.insulation.claddingSheetDensity,
      },
      // Cladding Wastage Wt.
      {
        key: "claddingWastageWt",
        wastage: formik?.values?.insulation.wastage,
      },
      // Cladding Total Wt.
      {
        key: "claddingTotalWt",
      },
      // Glass Wool Wt
      {
        key: "glassWoolWt",
        od: formik?.values?.pipeOD,
        vesselLength: formik?.values?.maxactivehotlength,
        insulationThickness: formik?.values?.insulation.insulationThickness,
        insulationDensity: formik?.values?.insulation.insulationDensity,
        wastage: formik?.values?.insulation.wastage,
      },
      // Glass Wool Wastage
      // {
      //   key: "glassWoolWastage",

      // },
      // // Glass Wool Total Wt.
      // {
      //   key: "glassWoolTotalWt",
      // }
    ],
  };

  const pricePayload = {
    type: "pricesPayload",
    data: [
      {
        totalOfAll: {
          manufacturingCost: formik?.values?.manufacturingCost
            ? Number(formik?.values?.manufacturingCost)
            : 0,
          margin: formik?.values?.margin ? Number(formik?.values?.margin) : 0,
          contingency: formik?.values?.contingency
            ? Number(formik?.values?.contingency)
            : 0,
          unitMaterialCost: formik?.values?.finalCosting?.unitMaterialCost
            ? Number(formik?.values?.finalCosting?.unitMaterialCost)
            : 0,
          bundleMaterialCost: formik?.values?.finalCosting?.bundleMaterialCost
            ? Number(formik?.values?.finalCosting?.bundleMaterialCost)
            : 0,
        },
      },
      {
        key: "tubeSheet",
        name: "FLANGE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
        size: formik.values.flangeSize,
        class: formik.values.FlangeClass,
        type: formik.values.flangeSelection,
      },
      //Tube Sheet Driling Price payload
      {
        key: "tubeSheetDrilling",
        fixingofheatingelements: formik?.values?.fixingofheatingelements,
        materialofsleevesbush: formik?.values?.materialofsleevesbush,
        nutsize: formik?.values?.nutsize,
        totalnumberofelements: formik?.values?.totalnumberofelements,
        flangeSize: formik?.values?.flangeSize,
        heatersheathtubedia: formik?.values?.heatersheathtubedia,
        flangeClass: formik?.values?.FlangeClass,
      },
      {
        key: "shellRing",
        name: "FLANGE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
        size: formik.values.flangeSize,
        class: formik.values.FlangeClass,
        type: formik.values.flangeSelection,
      },
      {
        key: "shellVessel",
        name: "FLANGE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
        size: formik.values.flangeSize,
        class: formik.values.FlangeClass,
        type: formik.values.flangeSelection,
      },
      {
        key: "dishEnd",
        name: "FLANGE",
        material_category: formik.values.FlangeMaterial,
        material: formik.values.FlangeMaterialCode,
        size: formik.values.flangeSize,
        class: formik.values.FlangeClass,
        type: formik.values.flangeSelection,
      },
      formik?.values?.nozzle[0]?.inletNozzle && {
        key: "inletNozzle",
        name: "FLANGE",
        material_category: formik.values.nozzle[0].material,
        material: formik.values.nozzle[0].materialCode,
        size: formik.values.nozzle[0].size,
        class: formik.values.nozzle[0].flangeRating,
        type: formik.values.nozzle[0].flangeType,
      },
      formik?.values?.nozzle[0]?.inletNozzle && {
        key: "inletFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[0].material,
        material: formik.values.nozzle[0].materialCode,
        size: formik.values.nozzle[0].size,
        class: formik.values.nozzle[0].flangeRating,
        type: formik.values.nozzle[0].flangeType,
      },
      formik?.values?.nozzle[1]?.outletNozzle && {
        key: "outletNozzle",
        name: "FLANGE",
        material_category: formik.values.nozzle[1].material,
        material: formik.values.nozzle[1].materialCode,
        size: formik.values.nozzle[1].size,
        class: formik.values.nozzle[1].flangeRating,
        type: formik.values.nozzle[1].flangeType,
      },
      formik?.values?.nozzle[1]?.outletNozzle && {
        key: "outletFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[1].material,
        material: formik.values.nozzle[1].materialCode,
        size: formik.values.nozzle[1].size,
        class: formik.values.nozzle[1].flangeRating,
        type: formik.values.nozzle[1].flangeType,
      },
      formik?.values?.nozzle[2]?.vent && {
        key: "ventNozzle",
        name: "FLANGE",
        material_category: formik.values.nozzle[2].material,
        material: formik.values.nozzle[2].materialCode,
        size: formik.values.nozzle[2].size,
        class: formik.values.nozzle[2].flangeRating,
        type: formik.values.nozzle[2].flangeType,
      },
      formik?.values?.nozzle[2]?.vent && {
        key: "ventFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[2].material,
        material: formik.values.nozzle[2].materialCode,
        size: formik.values.nozzle[2].size,
        class: formik.values.nozzle[2].flangeRating,
        type: formik.values.nozzle[2].flangeType,
      },
      formik?.values?.nozzle[3]?.drain && {
        key: "drainNozzle",
        name: "FLANGE",
        material_category: formik.values.nozzle[3].material,
        material: formik.values.nozzle[3].materialCode,
        size: formik.values.nozzle[3].size,
        class: formik.values.nozzle[3].flangeRating,
        type: formik.values.nozzle[3].flangeType,
      },
      formik?.values?.nozzle[3]?.drain && {
        key: "drainFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[3].material,
        material: formik.values.nozzle[3].materialCode,
        size: formik.values.nozzle[3].size,
        class: formik.values.nozzle[3].flangeRating,
        type: formik.values.nozzle[3].flangeType,
      },
      formik?.values?.nozzle[4]?.process && {
        key: "processNozzle",
        name: "FLANGE",
        material_category: formik.values.nozzle[4].material,
        material: formik.values.nozzle[4].materialCode,
        size: formik.values.nozzle[4].size,
        class: formik.values.nozzle[4].flangeRating,
        type: formik.values.nozzle[4].flangeType,
      },
      formik?.values?.nozzle[4]?.process && {
        key: "processFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[4].material,
        material: formik.values.nozzle[4].materialCode,
        size: formik.values.nozzle[4].size,
        class: formik.values.nozzle[4].flangeRating,
        type: formik.values.nozzle[4].flangeType,
      },
      formik?.values?.nozzle[5]?.dummy && {
        key: "dummyNozzle",
        name: "FLANGE",
        material_category: formik.values.nozzle[5].material,
        material: formik.values.nozzle[5].materialCode,
        size: formik.values.nozzle[5].size,
        class: formik.values.nozzle[5].flangeRating,
        type: formik.values.nozzle[5].flangeType,
      },
      formik?.values?.nozzle[5]?.dummy && {
        key: "dummyFlange",
        name: "FLANGE",
        material_category: formik.values.nozzle[5].material,
        material: formik.values.nozzle[5].materialCode,
        size: formik.values.nozzle[5].size,
        class: formik.values.nozzle[5].flangeRating,
        type: formik.values.nozzle[5].flangeType,
      },
      {
        name: formik.values.bafflematerial,
        key: "baffle",
        type: 1,
      },
      {
        name: formik.values.tiematerial,
        key: "tieRodWithSleeves",
        type: 1,
      },
      {
        name: formik.values.sadlemoc,
        key: "lugSupport",
        type: 1,
      },
      {
        name: formik.values.instrumentjunctionboxmaterial,
        key: "junctionBox",
        type: 1,
      },
      // Cable Gland payload
      {
        name: "cableGland",
        key: "cableGland",
        heaterterminalboxijb: formik?.values?.heaterterminalboxijb,
        stack: formik?.values?.multistackvessel,
        voltage: formik?.values?.voltage,
        power: formik?.values?.power,
        cableGland: formik?.values?.cableGland,
      },
      // Connection Price Payload
      {
        key: "connection",
        elements: formik?.values?.fixingofheatingelements === "Nut" ? 3 * formik?.values?.nutsize : formik?.values?.totalnumberofelements,
        individualElementPower: formik?.values?.power / formik?.values?.numberofelementsconnected,
        voltage: formik?.values?.voltage,
        power: formik?.values?.power,
        bank: formik?.values?.bank,
      },
      // HARDWARE
      {
        key: "hardware",
        flangeSize: formik?.values?.flangeSize,
        inletSize: formik?.values?.nozzle[0]?.size,
        name: "FLANGE",
        material_category: formik?.values?.FlangeMaterial,
        material: formik?.values?.FlangeMaterialCode,
        class: formik?.values?.FlangeClass,
        type: formik?.values?.flangeSelection,

      },
      // Insulation Price payload to fetch the price
      {
        name: "insulation",
        key: "insulationPrice",
        glassWoolRate: formik?.values?.insulationPrice?.glassWoolRate,
        claddingSheetRate: formik?.values?.insulationPrice?.claddingSheetRate,
        labourCharges: formik?.values?.insulationPrice?.labourCharges,
        miscCharges: formik?.values?.insulationPrice?.miscCharges,
        insulation: formik?.values?.insulation.insulationThickness,
      },
      {
        name: formik.values.materialofsleevesbush,
        key: "fixingheatingelementprice",
        type: 1,
      },

      {
        name: formik.values.heaterterminalboxmaterial,
        key: "heaterTerminalBoxMaterialPrice",
        type: 1,
      },

      {
        name: "heatingelement",
        size: formik?.values?.heatersheathtubedia,
        key: "heatingelement",
      },


    ],
  };
  // {
  //   "name": "FLANGE",
  //   "material_category": formik?.values?.FlangeMaterial,
  //   "material": formik?.values?.FlangeMaterialCode,
  //   "size": formik?.values?.flangeSize,
  //   "class": formik?.values?.FlangeClass,
  //   "type": formik?.values?.flangeSelection
  // },
  // {
  //   "name": "FLANGE",
  //   "material_category": "ALLOY",
  //   "material": "SB-564",
  //   "size": "24",
  //   "class": "1500",
  //   "type": "SORF"
  // },
  // {
  //   "name": "TUBE",
  //   "material_category": "SS",
  //   "material": "Welded Tubes as per SA 249 Grade TP 304",
  //   "size": "OD 9.50mm  X 0.60mm THK ",
  //   "class": "",
  //   "type": ""
  // }
  // { key1: 'value3', key2: 'value4' },
  // Add more objects as needed

  const otherNetWeight = [
    // Flange Weight
    {
      key: "tubeSheet",
      type: "getFlangeWeight",
      flange_rating: formik?.values?.FlangeClass,
      nps: formik?.values?.flangeSize,
    },

    // flange weight in kg
    {
      key: "shellRing",
      type: "getFlangeWeightInKg",
      flange_type: formik?.values?.vesselFlangeType,
      pipe_size: formik?.values?.flangeSize,
      flange_rating: formik?.values?.FlangeClass.toString(),
    },

    // pipe weight
    {
      key: "shellVessel",
      type: "getPipeWeight",
      sch: parseInt(formik?.values?.schedule),
      nps: parseFloat(formik?.values?.flangeSize),
    },
    netWeightPayload,
    pricePayload,
  ];

  useEffect(() => {
    powerelement();
    heatingElement();
  }, [formik.values.power, formik.values.totalnumberofelements]);

  return (
    <div>
      <button
        className="btn btn-outline-danger"
        style={{ margin: "10px 70px " }}
        onClick={(e) => {
          e.preventDefault();
          printPDF("yes-vessel");
        }}
      >
        Print PDF
      </button>
      <div id="yes-vessel" style={{ color: "black", padding: " 80px 70px" }}>
        <BOM
          formik={formik}
          trClass={trClass}
          tdClass={tdClass}
          tableClass={tableClass}
          tableParentDiv={tableParentDiv}
          netWeightPayload={netWeightPayload}
          pricePayload={pricePayload}
          otherNetWeight={otherNetWeight}
        />
      </div>
      <hr />
    </div>
  );
}

export default Screen9;
