import React, { useEffect, useState } from "react";
import Screen1 from "../../components/calculator/Screens/Screen1.jsx";
import Screen2 from "../../components/calculator/Screens/Screen2.jsx";
import Screen3 from "../../components/calculator/Screens/Screen3.jsx";
import Screen4 from "../../components/calculator/Screens/Screen4.jsx";
import Screen5 from "../../components/calculator/Screens/Screen5.jsx";
import Screen6 from "../../components/calculator/Screens/Screen6.jsx";
import Screen7 from "../../components/calculator/Screens/Screen7.jsx";
import Screen8 from "../../components/calculator/Screens/Screen8.jsx";
import Screen9 from "../../components/calculator/Screens/Screen9.jsx";
import Screen10 from "../../components/calculator/Screens/Screen10.jsx";

import { FaCheck, FaExclamation } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./Calculator.module.css";
import { useNavigate } from "react-router-dom";
import "./app.css";
import materialSelection from "./FormikValues/ms.js";
import validationRules from "./FormikValues/validations.js";
import flangeSelection from "./FormikValues/fs.js";
import multiStackVesselForm from "./FormikValues/msv.js";
import bom from "./FormikValues/bom";
import technicalCalculation from "./FormikValues/tc.js";
import nozzleSelection from "./FormikValues/ns.js";
import vesselSelection from "./FormikValues/vs.js";
import costComponent from "./FormikValues/cc.js";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import customerLogin from "./FormikValues/cf.js";
import controlPanel from "./FormikValues/cp.js";
import MultiStackpage from "../../components/calculator/Multistackvessel/MultiStackpage.jsx";

function Calculator(onBlrfChange) {
  const [formData, setFormData] = useState({});
  const [currStep, setCurrStep] = useState(1);
  const [id, setId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [current, setCurrent] = useState(1);
  const calculationId = localStorage.getItem("calculationId");
  const [newValidationRules, setNewValidationsRules] =
    useState(validationRules);
  /* Formik Start */
  const formik = useFormik({
    initialValues: {
      ...customerLogin,
      ...technicalCalculation,
      ...multiStackVesselForm,
      ...materialSelection,
      ...flangeSelection,
      ...bom,
      ...vesselSelection,
      ...nozzleSelection,
      ...MultiStackpage,
      ...costComponent,
      ...controlPanel,
    },
    // validateOnChange: false,
    // validationSchema: Yup.object(newValidationRules),
    // validateOnBlur: true,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const userData = localStorage.getItem("customerDetails");
      if (userData) {
        const parsedUserData = JSON.parse(userData);

        if (parsedUserData?.id) {
          try {
            setSubmitting(true);

            const formData = {
              customer_id: parsedUserData.id,
              order_id: parsedUserData.id,
              calculate_json: JSON.stringify(values), // Use values directly
              is_completed: 1,
            };

            // Use PUT request for updating
            const response = await axiosPrivate.put(
              `calculate/${calculationId}`,
              formData
            );

            if (response.status === 200) {
              handleCreateFolderStructure();
              navigate("/customer");
            } else {
              showErrorToast("Failed to update data.");
            }
          } catch (error) {
            showErrorToast(error.message);
          } finally {
            setSubmitting(false);
          }
        } else {
          showErrorToast("Please select a customer before proceeding.");
        }
      } else {
        showErrorToast("Please select a customer before proceeding.");
      }
    },
  });

  /* Formik End */
  useEffect(() => {
    const storedId = localStorage.getItem("id");
    if (storedId) setId(storedId);
  }, []);

  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState(() => {
    if (formik?.values?.vesselSelection === "yes") {
      return {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
      };
    } else {
      return {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        7: "",
        8: "",
        9: "",
        10: "",
      };
    }
  });
  const [events, setEvents] = useState([]);

  const [eventsCount, setEventsCount] = useState(() => {
    if (formik?.values?.vesselSelection === "yes") {
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
    } else {
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    }
  });
  useEffect(() => {
    // Update events count according to vessel selection
    setEvents(() => {
      const baseEvents = [
        "Customer Details",
        "Technical Calculation",
        "Material Selection",
        "Flange Selection",
        "Vessel Selection",
        "Data Sheet",
        "Component Cost",
        "BOM",
        "Control Panel",
      ];

      if (formik?.values?.vesselSelection === "yes") {
        baseEvents.splice(5, 0, "Nozzle Selection");
      }

      if (formik?.values?.multistackvessel > 1) {
        baseEvents.splice(baseEvents.indexOf("Data Sheet"), 0, "Multi Stack");
      }

      return baseEvents;
    });
  }, [formik?.values?.vesselSelection, formik?.values?.multistackvessel]);
  const handleBack = (e) => {
    e.preventDefault();
    setCurrStep((prevStep) => (prevStep !== 1 ? prevStep - 1 : prevStep));
  };

  const handleSaveandNext = async (e) => {
    e.preventDefault();
    const userData = localStorage.getItem("customerDetails");

    if (userData) {
      const parsedUserData = JSON.parse(userData);

      // Check if userData and userId are available
      if (parsedUserData?.id) {
        try {
          const formData = {
            customer_id: parsedUserData.id,
            order_id: parsedUserData.id,
            calculate_json: JSON.stringify(formik.values), // Ensure formik values are serialized
            is_completed: JSON.parse(localStorage.getItem("is_completed")),
          };
          let response;
          if (
            calculationId &&
            calculationId !== "undefined" &&
            calculationId !== "null"
          ) {
            // If calculationId exists, make a PUT request to update existing data
            response = await axiosPrivate.put(
              `calculate/${calculationId}`,
              formData
            );
          } else {
            // If calculationId does not exist, make a POST request to create new data
            response = await axiosPrivate.post(`calculate`, formData);
            if (response.status === 201 && response?.data?.data) {
              const json_id = response.data.data;
              localStorage.setItem("calculationId", json_id);
              setId(json_id);
            }
          }

          if (response.status === 200 || response.status === 201) {
            setCurrStep((prevStep) =>
              prevStep < events.length ? prevStep + 1 : prevStep
            );
          } else {
            showErrorToast("Failed to save data.");
          }
        } catch (error) {
          showErrorToast(error.message);
        }
      } else {
        showErrorToast("Please select a customer before proceeding.");
      }
    } else {
      showErrorToast("Please select a customer before proceeding.");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSaveandNextStepper = async (e) => {
    e.preventDefault();
    const userData = localStorage.getItem("customerDetails");

    if (userData) {
      const parsedUserData = JSON.parse(userData);

      // Check if userData and userId are available
      if (parsedUserData?.id) {
        try {
          const formData = {
            customer_id: parsedUserData.id,
            order_id: parsedUserData.id,
            calculate_json: JSON.stringify(formik.values), // Ensure formik values are serialized
            is_completed: JSON.parse(localStorage.getItem("is_completed")),
          };

          let response;
          if (
            calculationId &&
            calculationId !== "undefined" &&
            calculationId !== "null"
          ) {
            // If calculationId exists, make a PUT request to update existing data
            response = await axiosPrivate.put(
              `calculate/${calculationId}`,
              formData
            );
          } else {
            // If calculationId does not exist, make a POST request to create new data
            response = await axiosPrivate.post(`calculate`, formData);
            if (response.status === 201 && response?.data?.data) {
              const json_id = response.data.data;
              localStorage.setItem("calculationId", json_id);
              setId(json_id);
            }
          }

          if (response.status === 200 || response.status === 201) {
            // Do not update the current step index here
          } else {
            showErrorToast("Failed to save data.");
          }
        } catch (error) {
          showErrorToast(error.message);
        }
      } else {
        showErrorToast("Please select a customer before proceeding.");
      }
    } else {
      showErrorToast("Please select a customer before proceeding.");
    }
  };
  const handleCreateFolderStructure = async () => {
    try {
      const customer_id = localStorage.getItem("customer_id");
      const response = await axiosPrivate.post("/create/folders/structures", {
        customer_id,
      });
      if (response.status === 200) {
        showSuccessToast("Folder structure created successfully.");
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const handleGetCalculateData = async () => {
    try {
      const response = await axiosPrivate.get(`calculate/${calculationId}`);
      if (response.status === 200 && response?.data?.data) {
        const data = response.data.data;
        const calculate_json = JSON.parse(data.calculation_json);
        const customer_id = data.customer_id;
        const iscompleted = data.is_completed;
        localStorage.setItem("customer_id", customer_id);
        localStorage.setItem("is_completed", iscompleted);
        // Update Formik values
        formik.setValues((prev) => ({
          ...prev,
          ...calculate_json,
        }));

        // Update local state if needed
        setFormData(calculate_json);
        setCurrent(data.current_page);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  useEffect(() => {
    if (
      calculationId &&
      calculationId !== "undefined" &&
      calculationId !== "null"
    ) {
      handleGetCalculateData();
      setRefresh(false);
    }
  }, [refresh, calculationId]);

  const handleStepClick = (step) => {
    setCurrStep(step);
  };

  const isPageComplete = (page_no, totalCount, actualCount) => {
    if (totalCount === actualCount) {
      pageStatus[page_no] = "complete";
    } else {
      pageStatus[page_no] = "pending";
    }
  };

  useEffect(() => {
    let currentElement = document.querySelector(".current");
    let nextElement = currentElement?.nextElementSibling;

    if (nextElement?.classList.contains("pending")) {
      currentElement?.classList.add("recheck");
    }
    if (nextElement?.classList.contains("complete")) {
      currentElement?.classList.add("recheck_complete");
    }
  }, [currStep]);

  const handleFlangeThickness = async () => {
    const FlangeClass = formik?.values?.FlangeClass;
    const pipe_size = formik?.values?.flangeSize;
    try {
      const response = await axiosPrivate.post("/get/flange-thickness", {
        flange_class: FlangeClass,
        pipe_size: pipe_size,
      });
      const blrf = parseFloat(response?.data?.data[0]?.BLRF) || 0;
      formik.setFieldValue("heatingElementThickness", blrf);
      const wnrf = parseFloat(response?.data?.data[0]?.WNRF) || 0;
      const sorf = parseFloat(response?.data?.data[0]?.SORF) || 0;
      formik.setFieldValue("blrfValue", blrf);
      let maxActiveColdLength;
      if (
        formik.values.vesselSelection === "yes" &&
        (formik.values.vesselFlangeType === "sorf" ||
          formik.values.vesselFlangeType === "threaded" ||
          formik.values.vesselFlangeType === "lap_joint")
      ) {
        maxActiveColdLength =
          blrf + sorf + (formik?.values?.nozzle[0]?.size * 25.4) / 2;
      } else if (
        formik.values.vesselFlangeType === "rtjf" ||
        formik.values.vesselFlangeType === "wnrf"
      ) {
        maxActiveColdLength =
          blrf + wnrf + (formik?.values?.nozzle[0]?.size * 25.4) / 2;
      }

      formik.setFieldValue(
        "maxactivecoldlength",
        parseFloat(maxActiveColdLength).toFixed(2)
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching flange thickness:", error);
      throw error;
    }
  };

  // const updateMultiStackVesselFormData = (
  //   multiStackLength = formik?.values?.multistackvessel
  // ) => {
  //   const currentFormData = formik?.values?.multiStackVesselFormData || [];
  //   const refObject = formik?.initialValues?.multiStackVesselFormData[0] || {};

  //   // Ensure previously filled data is preserved
  //   const preservedData = currentFormData.slice(0, Math.min(multiStackLength, currentFormData.length));

  //   // Add new empty data for remaining slots
  //   const newFormData = Array.from(
  //     { length: Math.max(multiStackLength - preservedData.length, 0) - 1 },
  //     (_, index) => ({
  //       ...refObject,
  //       id: preservedData.length + index + 1,
  //     })
  //   );

  //   // Combine preserved and new data
  //   const updatedFormData = [...preservedData, ...newFormData];

  //   // Update multiStackVesselFormData
  //   formik?.setFieldValue("multiStackVesselFormData", updatedFormData);
  // };

  const updateMultiStackVesselFormData = (
    multiStackLength = formik?.values?.multistackvessel
  ) => {
    const currentFormData = formik?.values?.multiStackVesselFormData || [];
    const refObject = formik?.initialValues?.multiStackVesselFormData[0] || {};

    // Adjust preserved data based on the new desired length
    const preservedData = currentFormData.slice(0, multiStackLength);

    // Add new empty data for remaining slots, if necessary
    const newFormData = Array.from(
      { length: multiStackLength - 1 },
      (_, index) => ({
        ...refObject,
        id: preservedData.length + index + 1,
      })
    );

    const newData = [formik?.values?.multiStackVesselFormData[0],...newFormData];

    formik?.setFieldValue("multiStackVesselFormData", newData);
  };

  const renderCurrentPage = () => {
    const screens = [
      Screen1,
      Screen2,
      Screen3,
      Screen4,
      Screen5,
      formik?.values?.vesselSelection === "yes" ? Screen6 : Screen7,
      formik?.values?.vesselSelection === "yes" ? Screen7 : Screen8,
      formik?.values?.vesselSelection === "yes" ? Screen8 : Screen9,
      formik?.values?.vesselSelection === "yes" ? Screen9 : Screen10,
      Screen10,
    ];

    if (formik?.values?.multistackvessel > 1) {
      screens.splice(screens.indexOf(Screen7) + 1, 0, MultiStackpage);
    }

    const StepComponent = screens[currStep - 1];

    return (
      <StepComponent
        formik={formik}
        handleRefresh={currStep === 1 ? setRefresh : undefined}
        setFormData={setFormData}
        setPageStatus={setPageStatus}
        isPageComplete={isPageComplete}
        currStep={currStep}
        handleStepClick={handleStepClick}
        handleFlangeThickness={handleFlangeThickness}
        updateMultiStackVesselFormData={updateMultiStackVesselFormData}
        {...(currStep === 2 ? { setId } : {})} // Only for Screen2
      />
    );
  };

  useEffect(() => {
    renderCurrentPage();

    // Update pageStatus according to vessel selection
    // Update eventsCount according to vessel selection
    if (formik?.values?.vesselSelection === "yes") {
      setEventsCount(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"]);
    } else {
      setEventsCount(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]);
    }

    // Update events according to vessel selection and multistackvessel
    setEvents(() => {
      const baseEvents = [
        "Customer Details",
        "Technical Calculation",
        "Material Selection",
        "Flange Selection",
        "Vessel Selection",
        "Data Sheet",
        "Component Cost",
        "BOM",
        "Control Panel",
      ];

      if (formik?.values?.vesselSelection === "yes") {
        baseEvents.splice(5, 0, "Nozzle Selection");
      }

      if (formik?.values?.multistackvessel > 1) {
        baseEvents.splice(
          baseEvents.indexOf("Data Sheet") + 1,
          0,
          "Multi Stack"
        );
      }

      return baseEvents;
    });
  }, [formik?.values?.vesselSelection, formik?.values?.multistackvessel]);
  // Functions for different heatersheathtubedia values
  const getFlangeSizeFor8 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
      { max: 3, size: "N/A" },
      { max: 15, size: 6 },
      { max: 27, size: 8 },
      { max: 48, size: 10 },
      { max: 69, size: 12 },
      { max: 84, size: 14 },
      { max: 112, size: 16 },
      { max: 147, size: 18 },
      { max: 183, size: 20 },
      { max: 271, size: 24 },
      { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
      if (numberOfElements <= range.max) {
        return range.size;
      }
    }
  };

  const getFlangeSizeFor11 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
      { max: 3, size: "N/A" },
      { max: 11, size: 6 },
      { max: 20, size: 8 },
      { max: 37, size: 10 },
      { max: 53, size: 12 },
      { max: 65, size: 14 },
      { max: 90, size: 16 },
      { max: 115, size: 18 },
      { max: 145, size: 20 },
      { max: 217, size: 24 },
      { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
      if (numberOfElements <= range.max) {
        return range.size;
      }
    }
  };

  const getFlangeSizeFor12_5 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
      { max: 3, size: "N/A" },
      { max: 9, size: 6 },
      { max: 17, size: 8 },
      { max: 32, size: 10 },
      { max: 46, size: 12 },
      { max: 57, size: 14 },
      { max: 80, size: 16 },
      { max: 100, size: 18 },
      { max: 125, size: 20 },
      { max: 160, size: 24 },
      { max: 175, size: 26 },
      { max: 215, size: 28 },
      { max: 240, size: 30 },
      { max: 270, size: 32 },
      { max: 290, size: 34 },
      { max: 310, size: 36 },
      { max: 355, size: 38 },
      { max: 390, size: 40 },
      { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
      if (numberOfElements <= range.max) {
        return range.size;
      }
    }
  };

  const getFlangeSizeFor13_5 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
      { max: 3, size: "N/A" },
      { max: 8, size: 6 },
      { max: 16, size: 8 },
      { max: 29, size: 10 },
      { max: 42, size: 12 },
      { max: 51, size: 14 },
      { max: 72, size: 16 },
      { max: 92, size: 18 },
      { max: 115, size: 20 },
      { max: 142, size: 24 },
      { max: 168, size: 26 },
      { max: 195, size: 28 },
      { max: 222, size: 30 },
      { max: 248, size: 32 },
      { max: 275, size: 34 },
      { max: 305, size: 36 },
      { max: 335, size: 38 },
      { max: 370, size: 40 },
      { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
      if (numberOfElements <= range.max) {
        return range.size;
      }
    }
  };

  const getFlangeSizeFor16 = (numberOfElements) => {
    if (numberOfElements === "" || isNaN(numberOfElements)) return;

    const ranges = [
      { max: 3, size: "N/A" },
      { max: 6, size: 6 },
      { max: 11, size: 8 },
      { max: 21, size: 10 },
      { max: 30, size: 12 },
      { max: 38, size: 14 },
      { max: 51, size: 16 },
      { max: 67, size: 18 },
      { max: 85, size: 20 },
      { max: 105, size: 24 },
      { max: 132, size: 26 },
      { max: 157, size: 28 },
      { max: 182, size: 30 },
      { max: 205, size: 32 },
      { max: 230, size: 34 },
      { max: 260, size: 36 },
      { max: 290, size: 38 },
      { max: 320, size: 40 },
      { max: Infinity, size: "N/A" },
    ];

    for (const range of ranges) {
      if (numberOfElements <= range.max) {
        return range.size;
      }
    }
  };

  // Function map based on heatersheathtubedia values
  const flangeSizeFunctions = {
    8: getFlangeSizeFor8,
    10: getFlangeSizeFor8,
    11: getFlangeSizeFor11,
    12: getFlangeSizeFor12_5,
    12.5: getFlangeSizeFor12_5,
    13.5: getFlangeSizeFor13_5,
    16: getFlangeSizeFor16,
  };

  useEffect(() => {
    const numberOfElements = Number(formik?.values?.numberofelementsconnected);
    const heatersheathtubedia = formik?.values?.heatersheathtubedia;

    // Choose the appropriate function based on heatersheathtubedia
    const getFlangeSize = flangeSizeFunctions[heatersheathtubedia];

    if (getFlangeSize) {
      if (!isNaN(numberOfElements)) {
        const flangeSize = getFlangeSize(numberOfElements);
        formik.setFieldValue("flangeSize", flangeSize);
      }
    } else {
      formik.setFieldValue("flangeSize", ""); // Set to empty if heatersheathtubedia has no matching function
    }
  }, [
    formik?.values?.numberofelementsconnected,
    formik?.values?.heatersheathtubedia,
  ]);

  return (
    <>
      <form
        onSubmit={() => {
          formik?.handleSubmit();
        }}
      >
        <div className="container-fluid">
          <div className="d-flex justify-between items-center mb-5 stepsBar mt-5">
            {Array.from({ length: events.length }, (_, index) => (
              <div
                key={index}
                className={`step ${
                  index === currStep - 1 ? "current" : pageStatus[index + 1]
                }`}
                onClick={(e) => {
                  setCurrStep(index + 1);
                  handleSaveandNextStepper(e);
                }}
              >
                <div className={`step-icon`}>
                  {index + 1 === currStep && (
                    <div className="step-circle"></div>
                  )}
                  {pageStatus[index + 1] === "complete" &&
                    index + 1 !== currStep && (
                      <FaCheck style={{ color: "white" }} />
                    )}
                  {pageStatus[index + 1] === "pending" &&
                    index + 1 !== currStep && (
                      <FaExclamation style={{ color: "white" }} />
                    )}
                  {pageStatus[index + 1] === "" && index + 1 !== currStep && (
                    <div className="step-circle"></div>
                  )}
                </div>
                <span className="text-xs mt-2 d-none d-lg-block">
                  {events[index]}
                </span>
                <span className="text-xs mt-2 d-lg-none">
                  {eventsCount[index]}
                </span>
              </div>
            ))}
          </div>
          <div className="card">
            <div
              className={`card-body ${
                currStep === 1 ? "custom-card-body" : "card-body"
              }`}
            >
              {renderCurrentPage()}
              <div className="d-flex justify-content-end">
                <div>
                  {currStep !== 1 && (
                    <button
                      onClick={(e) => handleBack(e)}
                      type="button"
                      className="btn btn-outline-danger"
                    >
                      {"Back"}
                    </button>
                  )}
                  {currStep !== events.length && (
                    <button
                      onClick={(e) => handleSaveandNext(e)}
                      type="button"
                      className="btn btn-outline-danger"
                    >
                      {"Save & Next"}
                    </button>
                  )}
                  {currStep === events.length && (
                    <button
                      type="submit"
                      className="btn btn-outline-danger"
                      onClick={formik.handleSubmit}
                    >
                      {"Submit"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Calculator;
