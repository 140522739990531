import React, { useEffect, useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import Select from "react-select";
import { showErrorToast } from "../../../utils/Toaster";
import {
  customStyles,
  customStylesForDisabled,
} from "../../../styles/CustomStylesForSelect.jsx";
import {
  elevationOptions,
  orientationOptions,
} from "../../../utils/DropdownOptions";

function Screen6({ formik, isPageComplete, handleFlangeThickness }) {
  const [totalCount, setTotalCount] = useState(1);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.nozzle[0]?.size, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(6, totalCount, actualCount);
  }, [actualCount, totalCount]);

  const [schedules, setSchedules] = useState([]);
  const [nozzles, setNozzles] = useState(() => {
    const savedNozzles = localStorage.getItem("nozzles");
    return savedNozzles
      ? JSON?.parse(savedNozzles)
      : [{ id: 1, name: "Inlet" }];
  });
  const [materials, setMaterials] = useState([]);
  const [materialCode, setMaterialCode] = useState([]);
  const [materialCodeMapping, setMaterialCodeMapping] = useState({});

  // const handleChange = (id, field, value) => {
  //   setNozzles(
  //     nozzles.map((nozzle) =>
  //       nozzle.id === id ? { ...nozzle, [field]: value } : nozzle
  //     )
  //     )
  //   );
  // };

  const handleChange = (index, field, value) => {
    const newNozzles = [...formik.values.nozzle];
    newNozzles[index][field] = value;
    formik.setFieldValue("nozzle", newNozzles);

    formik.values.multiStackVesselFormData.forEach((item, stackIndex) => {
      const newStackNozzles = [...item.nozzle];
      newStackNozzles[index][field] = value;
      formik.setFieldValue(
        `multiStackVesselFormData[${stackIndex}].nozzle`,
        newStackNozzles
      );
    });
  };

  // const handleDrawingChange = (id, file) => {
  //   // You can update state or perform any other action here
  // };

  const sizeOptions = [
    // { value: 0.125, label: "1/8" },
    // { value: 0.25, label: "1/4" },
    // { value: 0.375, label: "3/8" },
    // { value: 0.5, label: "1/2" },
    // { value: 0.75, label: "3/4" },
    { value: 1, label: "1" },
    // { value: 1.25, label: "1-1/4" },
    { value: 1.5, label: "1-1/2" },
    { value: 2, label: "2" },
    { value: 2.5, label: "2-1/2" },
    { value: 3, label: "3" },
    { value: 3.5, label: "3-1/2" },
    { value: 4, label: "4" },
    // { value: 4.5, label: "4-1/2" },
    // { value: 5, label: "5" },
    { value: 6, label: "6" },
    // { value: 7, label: "7" },
    { value: 8, label: "8" },
    // { value: 9, label: "9" },
    { value: 10, label: "10" },
    // { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 14, label: "14" },
    { value: 16, label: "16" },
    { value: 18, label: "18" },
    { value: 20, label: "20" },
    { value: 24, label: "24" },
    { value: 26, label: "26" },
    { value: 28, label: "28" },
    { value: 30, label: "30" },
    { value: 32, label: "32" },
    { value: 34, label: "34" },
    { value: 36, label: "36" },
    { value: 38, label: "38" },
    { value: 40, label: "40" },
    { value: 42, label: "42" },
    { value: 48, label: "48" },
  ];

  const flangeTypeOptions = [
    { value: "NA", label: "NA" },
    { value: "blrf", label: "BLRF" },
    { value: "sorf", label: "SORF" },
    { value: "wnrf", label: "WNRF" },
    { value: "rtjf", label: "RTJF" },
    { value: "lap_joint", label: "Lap Joint" },
    { value: "threaded", label: "Threaded" },
    { value: "socket_weld", label: "Socket Weld" },
  ];

  const flangeRatingOptions = [
    { value: "150", label: "150" },
    { value: "300", label: "300" },
    { value: "450", label: "450" },
    { value: "600", label: "600" },
    { value: "900", label: "900" },
    { value: "1200", label: "1200" },
    { value: "1500", label: "1500" },
    { value: "2500", label: "2500" },
  ];

  const selectedKettleSize = formik.values.KettleSize;
  const selectedFlangeSize = formik.values.flangeSize;
  const sizeToFilterBy = selectedKettleSize || selectedFlangeSize;
  const filteredSizeOptions = sizeToFilterBy
    ? sizeOptions.filter(
        (option) =>
          parseFloat(option ? option.value : "") <= parseFloat(sizeToFilterBy)
      )
    : sizeOptions;

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNozzles((prevNozzles) => ({
      ...prevNozzles,
      [name]: checked,
    }));
  };

  const fetchMaterials = async () => {
    try {
      const response = await axiosPrivate.get("/material-grade");
      if (response.status == 200) {
        const data = response?.data?.data;

        if (data.length > 0) {
          const materialCodeMapping = data.reduce((acc, item) => {
            if (!acc[item.materials]) {
              acc[item.materials] = new Set();
            }
            acc[item.materials].add(item.materials_code);
            return acc;
          }, {});

          const uniqueMaterials = Array.from(
            new Set(data.map((item) => item.materials))
          );
          if (uniqueMaterials.length > 0) {
            const materialsOptions = uniqueMaterials.map((material) => ({
              value: material,
              label: material,
            }));
            setMaterials(materialsOptions);
            setMaterialCodeMapping(materialCodeMapping);
          }
        } else {
          setMaterials([]);
          setMaterialCodeMapping([]);
        }
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  useEffect(() => {
    const fetchSCH = async () => {
      try {
        const response = await axiosPrivate.get("schedules");
        if (response.status == 200) {
          const data = response?.data?.data;
          if (data.length > 0) {
            const uniqueSchedules = Array.from(
              new Set(data.map((item) => item.sch))
            ).map((sch) => data.find((item) => item.sch === sch));

            const sortedSchedules = uniqueSchedules.sort((a, b) => {
              const schA = a.sch;
              const schB = b.sch;

              // Check if values are numeric
              const isNumA = !isNaN(parseFloat(schA)) && isFinite(schA);
              const isNumB = !isNaN(parseFloat(schB)) && isFinite(schB);

              if (isNumA && isNumB) {
                return parseFloat(schA) - parseFloat(schB);
              } else if (isNumA) {
                return -1;
              } else if (isNumB) {
                return 1;
              } else {
                return schA.localeCompare(schB);
              }
            });
            setSchedules(uniqueSchedules);
          }
        }
      } catch (error) {
        showErrorToast(error.message);
      }
    };
    fetchSCH();
  }, []);

  useEffect(() => {
    localStorage.setItem("nozzles", JSON.stringify(nozzles));
  }, [nozzles]);
  // Function to handle increase
  // const handleIncrease = (index) => {
  //   const newDummy = [...formik.values.nozzle[5].dummy];
  //   const newItem = {
  //     size: "",
  //     schedules: "",
  //     flangeRating: "",
  //     flangeType: "",
  //     counterFlange: "",
  //     materialCode: "",
  //     material: "",
  //   };
  //   newDummy.push(newItem);
  //   formik.setFieldValue(`nozzle[5].dummy`, newDummy);
  // };

  // Function to handle decrease
  // const handleDecrease = (index) => {
  //   const newDummy = [...formik.values?.nozzle[5]?.dummy];
  //   if (newDummy.length > 1) {
  //     newDummy.splice(index, 1);
  //     formik.setFieldValue(`nozzle[5].dummy`, newDummy);
  //   }
  // };

  const getSelectedOption = (value) => {
    return flangeTypeOptions.find((option) => option.value === value);
  };

  const handleMaterialSelect = (selectedMaterial) => {
    if (selectedMaterial) {
      const materialCodes = Array.from(
        materialCodeMapping[selectedMaterial.value] || []
      ).map((materialCode) => ({
        value: materialCode,
        label: materialCode,
      }));
      setMaterialCode(materialCodes);
    } else {
      setMaterialCode([]);
      handleChange(0, "materialCode", "");
      handleChange(1, "materialCode", "");
      handleChange(2, "materialCode", "");
      handleChange(3, "materialCode", "");
      handleChange(4, "materialCode", "");
      handleChange(5, "materialCode", "");
    }
  };

  return (
    <div className="container-fluid ">
      {/* Nozzle */}
      <div className="row">
        {/* inlet nozzle */}
        <div className="col-12 justify-content-center">
          <div className="d-flex align-items-center row mb-15">
            <div className="d-flex align-items-center col-lg-2 col-md-4 col-sm-6">
              <input
                type="checkbox"
                id="inletVesselCounter"
                name="inletVesselCounter"
                value="inletVesselCounter"
                className="mr-2 custom-checkbox"
                checked={
                  formik?.values?.nozzle &&
                  formik?.values?.nozzle[0]?.inletNozzle
                }
                onClick={() => {
                  formik.values.nozzle[0].inletNozzle =
                    !formik.values.nozzle[0].inletNozzle;
                  if (!formik.values.nozzle[0].inletNozzle) {
                    formik.values.nozzle[0].counterFlange = false;
                  }
                }}
                onChange={handleCheckboxChange}
              ></input>
              <label
                htmlFor="inletVesselCounter"
                className="d-flex align-items-center font-weight-bold"
              >
                Inlet Nozzle
              </label>
            </div>
            {formik?.values?.nozzle && formik.values.nozzle[0].inletNozzle && (
              <>
                <div className="d-flex mr-5 align-items-center">
                  <input
                    type="checkbox"
                    id="inletCounterFlange"
                    name="inletCounterFlange"
                    value="inletCounterFlange"
                    className="mr-2 custom-checkbox custom-checkbox"
                    checked={
                      formik?.values?.nozzle &&
                      formik?.values?.nozzle[0]?.counterFlange
                    }
                    onClick={() => {
                      formik.values.nozzle[0].counterFlange =
                        !formik.values.nozzle[0].counterFlange;
                    }}
                    onChange={handleCheckboxChange}
                  ></input>
                  <label
                    htmlFor="inletCounterFlange"
                    className="d-flex align-items-center font-weight-bold"
                  >
                    Counter Flange
                  </label>
                </div>
                {/* <div>
                  {formik.values.nozzle[0].inletNozzle +
                    ":" +
                    formik?.values?.nozzle[0]?.counterFlange}
                </div> */}
              </>
            )}
          </div>
          {formik?.values?.nozzle && formik?.values?.nozzle[0]?.inletNozzle && (
            <div className="row mt-2">
              {/* Inlet Size  */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15 ">
                <label htmlFor="size">Size</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Size"
                  name="size"
                  id="size"
                  options={filteredSizeOptions}
                  value={filteredSizeOptions.find(
                    (option) => option.value === formik?.values?.nozzle[0]?.size
                  )}
                  onChange={(selectedOption) => {
                    const value = selectedOption ? selectedOption.value : "";
                    handleChange(0, "size", value);
                    handleFlangeThickness();
                  }}
                />
              </div>
              {/* Inlet Schedule */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="schedules">Schedules</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Schedule"
                  className="w-100"
                  name={`nozzle[${0}].schedules`}
                  id="schedules"
                  options={schedules.map((sch) => ({
                    label: sch.sch,
                    value: sch.sch,
                  }))}
                  value={
                    formik?.values?.nozzle[0]?.schedules
                      ? {
                          label: formik?.values?.nozzle[0]?.schedules,
                          value: formik?.values?.nozzle[0]?.schedules,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      0,
                      "schedules",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Inlet Flange Rating */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeRating">Flange Rating</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Rating"
                  className="w-100"
                  name={`nozzle[${0}].flangeRating`} // Ensure correct index is used (0 in this case)
                  id="flangeRating"
                  options={flangeRatingOptions}
                  value={
                    formik?.values?.nozzle[0]?.flangeRating
                      ? {
                          label: formik?.values?.nozzle[0]?.flangeRating,
                          value: formik?.values?.nozzle[0]?.flangeRating,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      0,
                      "flangeRating",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Inlet Flange Type */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeType">Flange Type</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Type"
                  className="w-100"
                  name={`nozzle[${0}].flangeType`} // Ensure correct index is used (0 in this case)
                  id="flangeType"
                  options={flangeTypeOptions}
                  value={
                    formik?.values?.nozzle[0]?.flangeType
                      ? getSelectedOption(formik?.values?.nozzle[0]?.flangeType)
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      0,
                      "flangeType",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Inlet Material */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="material">Material</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Material"
                  className="w-100"
                  name={`nozzle[${0}].material`} // Ensure correct index is used (0 in this case)
                  id={`material`}
                  options={materials}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      formik.setFieldValue(
                        "nozzle[0].material",
                        selectedOption.value
                      );
                      handleMaterialSelect(selectedOption);
                      handleChange(0, "material", selectedOption.value);
                    } else {
                      formik.setFieldValue("nozzle[0].material", "");
                      formik.setFieldValue("nozzle[0].materialCode", "");
                      setMaterialCode([]);
                    }
                  }}
                  value={
                    formik?.values?.nozzle[0]?.material
                      ? {
                          label: formik?.values?.nozzle[0]?.material,
                          value: formik?.values?.nozzle[0]?.material,
                        }
                      : null
                  }
                />
              </div>
              {/* Inlet Material Code */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Material Code</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Material Code"
                  className="w-100"
                  name={`nozzle[${0}]?.materialCode`}
                  id="materialCode"
                  options={materialCode}
                  value={
                    formik?.values?.nozzle[0]?.materialCode
                      ? {
                          label: formik?.values?.nozzle[0]?.materialCode,
                          value: formik?.values?.nozzle[0]?.materialCode,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      0,
                      "materialCode",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Inlet Orientation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Orientation( Deg.)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Orientation"
                  className="w-100"
                  name={`nozzle[${0}]?.orientation`}
                  id="inletOrientation"
                  options={orientationOptions}
                  value={
                    formik?.values?.nozzle[0]?.orientation
                      ? {
                          label: formik?.values?.nozzle[0]?.orientation,
                          value: formik?.values?.nozzle[0]?.orientation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      0,
                      "orientation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Inlet Elevation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Elevation (mm)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Elevation"
                  className="w-100"
                  name={`nozzle[${0}]?.elevation`}
                  id="inletElevation"
                  options={elevationOptions}
                  value={
                    formik?.values?.nozzle[0]?.elevation
                      ? {
                          label: formik?.values?.nozzle[0]?.elevation,
                          value: formik?.values?.nozzle[0]?.elevation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      0,
                      "elevation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          )}
        </div>

        {/* outlet Nozzle */}
        <div className="col-12 justify-content-center mt-4">
          <div className="d-flex align-items-center row mb-15">
            {/* Oulet Checkbox  */}
            <div className="d-flex align-items-center col-lg-2 col-md-4 col-sm-6">
              <input
                type="checkbox"
                id="outletNozzle"
                name="outletNozzle"
                value="outletNozzle"
                className="mr-2 custom-checkbox"
                checked={
                  formik?.values?.nozzle &&
                  formik?.values?.nozzle[1]?.outletNozzle
                }
                onClick={() => {
                  formik.values.nozzle[1].outletNozzle =
                    !formik.values.nozzle[1].outletNozzle;
                  if (!formik.values.nozzle[1].outletNozzle) {
                    formik.values.nozzle[1].counterFlange = false;
                  }
                }}
                onChange={handleCheckboxChange}
              ></input>
              <label
                htmlFor="outletNozzle"
                className="d-flex align-items-center font-weight-bold"
              >
                Outlet Nozzle
              </label>
            </div>
            {formik?.values?.nozzle && formik.values.nozzle[1].outletNozzle && (
              <>
                {/* Oulet Flange Counter  */}
                <div className="d-flex mr-5 align-items-center">
                  <input
                    type="checkbox"
                    id="outletCounterFlange"
                    name="outletCounterFlange"
                    value="outletCounterFlange"
                    className="mr-2 custom-checkbox"
                    checked={
                      formik?.values?.nozzle &&
                      formik.values.nozzle[1]?.counterFlange
                    }
                    onClick={() => {
                      formik.values.nozzle[1].counterFlange =
                        !formik.values.nozzle[1].counterFlange;
                    }}
                    onChange={handleCheckboxChange}
                  ></input>
                  <label
                    htmlFor="outletCounterFlange"
                    className="d-flex align-items-center font-weight-bold"
                  >
                    Counter Flange
                  </label>
                </div>
                {/* <div>
                  {formik.values.nozzle[1].outletNozzle +
                    ":" +
                    formik.values.nozzle[1]?.counterFlange}
                </div> */}
              </>
            )}
          </div>
          {formik?.values?.nozzle && formik.values.nozzle[1].outletNozzle && (
            <div className="row mt-2">
              {/* Outlet size  */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="size">Size</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Size"
                  name={`nozzle[${1}].size`}
                  id="size"
                  options={filteredSizeOptions}
                  value={filteredSizeOptions.find(
                    (option) => option.value === formik?.values?.nozzle[1]?.size
                  )}
                  onChange={(selectedOption) =>
                    handleChange(
                      1,
                      "size",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Outlet Schedule */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="schedules">Schedules</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Schedule"
                  className="w-100"
                  name={`nozzle[${1}].schedules`}
                  id="schedules"
                  options={schedules.map((sch) => ({
                    label: sch.sch,
                    value: sch.sch,
                  }))}
                  value={
                    formik?.values?.nozzle[1]?.schedules
                      ? {
                          label: formik?.values?.nozzle[1]?.schedules,
                          value: formik?.values?.nozzle[1]?.schedules,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      1,
                      "schedules",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Outlet Flange Rating */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeRating">Flange Rating</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Rating"
                  className="w-100"
                  name={`nozzle[${1}].flangeRating`} // Ensure correct index is used (0 in this case)
                  id="flangeRating"
                  options={flangeRatingOptions}
                  value={
                    formik?.values?.nozzle[1]?.flangeRating
                      ? {
                          label: formik?.values?.nozzle[1]?.flangeRating,
                          value: formik?.values?.nozzle[1]?.flangeRating,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      1,
                      "flangeRating",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Outlet Flange Type */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeType">Flange Type</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Type"
                  className="w-100"
                  name={`nozzle[${1}].flangeType`} // Ensure correct index is used (0 in this case)
                  id="flangeType"
                  options={flangeTypeOptions}
                  value={
                    formik?.values?.nozzle[1]?.flangeType
                      ? getSelectedOption(formik?.values?.nozzle[1]?.flangeType)
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      1,
                      "flangeType",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Outlet Material */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="material">Material</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Material"
                  className="w-100"
                  name={`nozzle[${1}].material`} // Ensure correct index is used (0 in this case)
                  id={`material`}
                  options={materials}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      formik.setFieldValue(
                        "nozzle[1].material",
                        selectedOption.value
                      );
                      handleMaterialSelect(selectedOption);
                      handleChange(1, "material", selectedOption.value);
                    } else {
                      formik.setFieldValue("nozzle[1].material", "");
                      formik.setFieldValue("nozzle[1].materialCode", "");
                      setMaterialCode([]);
                    }
                  }}
                  value={
                    formik?.values?.nozzle[1]?.material
                      ? {
                          label: formik?.values?.nozzle[1]?.material,
                          value: formik?.values?.nozzle[1]?.material,
                        }
                      : null
                  }
                />
              </div>
              {/* Outlet Material Code */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Material Code</label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="materialCode"
                  name={`nozzle[${1}]?.materialCode`}
                  className="w-100"
                  placeholder="Material Code"
                  options={materialCode}
                  value={
                    formik?.values?.nozzle[1]?.materialCode
                      ? {
                          label: formik?.values?.nozzle[1]?.materialCode,
                          value: formik?.values?.nozzle[1]?.materialCode,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      1,
                      "materialCode",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Outlet Orientation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Orientation( Deg.)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Orientation"
                  className="w-100"
                  name={`nozzle[${1}]?.orientation`}
                  id="inletOrientation"
                  options={orientationOptions}
                  value={
                    formik?.values?.nozzle[1]?.orientation
                      ? {
                          label: formik?.values?.nozzle[1]?.orientation,
                          value: formik?.values?.nozzle[1]?.orientation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      1,
                      "orientation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Outlet Elevation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Elevation (mm)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Elevation"
                  className="w-100"
                  name={`nozzle[${1}]?.elevation`}
                  id="inletElevation"
                  options={elevationOptions}
                  value={
                    formik?.values?.nozzle[1]?.elevation
                      ? {
                          label: formik?.values?.nozzle[1]?.elevation,
                          value: formik?.values?.nozzle[1]?.elevation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      1,
                      "elevation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          )}
        </div>

        {/* vent */}
        <div className="col-12 justify-content-center mt-4">
          <div className="d-flex align-items-center row mb-15">
            {/* Vent Checkbox  */}
            <div className="d-flex align-items-center col-lg-2 col-md-4 col-sm-6">
              <input
                type="checkbox"
                id="vent"
                name="vent"
                value="vent"
                className="mr-2 custom-checkbox"
                checked={
                  formik?.values?.nozzle && formik?.values?.nozzle[2]?.vent
                }
                onClick={() => {
                  formik.values.nozzle[2].vent = !formik.values.nozzle[2].vent;
                  if (!formik.values.nozzle[2].vent) {
                    formik.values.nozzle[2].counterFlange = false;
                  }
                }}
                onChange={handleCheckboxChange}
              ></input>
              <label
                htmlFor="vent"
                className="d-flex align-items-center font-weight-bold"
              >
                Vent
              </label>
            </div>
            {formik?.values?.nozzle && formik?.values?.nozzle[2]?.vent && (
              <>
                {/* Vent Counter Flange  */}
                <div className="d-flex mr-5 align-items-center">
                  <input
                    type="checkbox"
                    id="ventCounterFlange"
                    name="ventCounterFlange"
                    value="ventCounterFlange"
                    className="mr-2 custom-checkbox"
                    checked={
                      formik?.values?.nozzle &&
                      formik?.values?.nozzle[2]?.counterFlange
                    }
                    onClick={() => {
                      formik.values.nozzle[2].counterFlange =
                        !formik.values.nozzle[2].counterFlange;
                    }}
                    onChange={handleCheckboxChange}
                  ></input>
                  <label
                    htmlFor="ventCounterFlange"
                    className="d-flex align-items-center font-weight-bold"
                  >
                    Counter Flange
                  </label>
                </div>
                {/* <div>
                  {formik?.values?.nozzle[2]?.vent +
                    ":" +
                    formik?.values?.nozzle[2]?.counterFlange}
                </div> */}
              </>
            )}
          </div>
          {formik?.values?.nozzle && formik?.values?.nozzle[2]?.vent && (
            <div className="row mt-2">
              {/* Vent Size  */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="size">Size</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Size"
                  name={`nozzle[${2}].size`}
                  id="size"
                  options={filteredSizeOptions}
                  value={filteredSizeOptions.find(
                    (option) => option.value === formik?.values?.nozzle[2]?.size
                  )}
                  onChange={(selectedOption) =>
                    handleChange(
                      2,
                      "size",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Vent Schedule */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="schedules">Schedules</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Schedule"
                  className="w-100"
                  name={`nozzle[${2}].schedules`}
                  id="schedules"
                  options={schedules.map((sch) => ({
                    label: sch.sch,
                    value: sch.sch,
                  }))}
                  value={
                    formik?.values?.nozzle[2]?.schedules
                      ? {
                          label: formik?.values?.nozzle[2]?.schedules,
                          value: formik?.values?.nozzle[2]?.schedules,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      2,
                      "schedules",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Vent Flange Rating */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeRating">Flange Rating</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Rating"
                  className="w-100"
                  name={`nozzle[${2}].flangeRating`} // Ensure correct index is used (0 in this case)
                  id="flangeRating"
                  options={flangeRatingOptions}
                  value={
                    formik?.values?.nozzle[2]?.flangeRating
                      ? {
                          label: formik?.values?.nozzle[2]?.flangeRating,
                          value: formik?.values?.nozzle[2]?.flangeRating,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      2,
                      "flangeRating",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Vent Flange Type */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeType">Flange Type</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Type"
                  className="w-100"
                  name={`nozzle[${2}].flangeType`} // Ensure correct index is used (0 in this case)
                  id="flangeType"
                  options={flangeTypeOptions}
                  value={
                    formik?.values?.nozzle[2]?.flangeType
                      ? getSelectedOption(formik?.values?.nozzle[2]?.flangeType)
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      2,
                      "flangeType",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Vent Material */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="material">Material</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Material"
                  className="w-100"
                  name={`nozzle[${2}].material`} // Ensure correct index is used (2 in this case)
                  id={`material`}
                  options={materials}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      formik.setFieldValue(
                        "nozzle[2].material",
                        selectedOption.value
                      );
                      handleMaterialSelect(selectedOption);
                      handleChange(2, "material", selectedOption.value);
                    } else {
                      formik.setFieldValue("nozzle[2].material", "");
                      formik.setFieldValue("nozzle[2].materialCode", "");
                      setMaterialCode([]);
                    }
                  }}
                  value={
                    formik?.values?.nozzle[2]?.material
                      ? {
                          label: formik?.values?.nozzle[2]?.material,
                          value: formik?.values?.nozzle[2]?.material,
                        }
                      : null
                  }
                />
              </div>
              {/* Vent Material Code */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Material Code</label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="materialCode"
                  name={`nozzle[${2}]?.materialCode`}
                  className="w-100"
                  placeholder="Material Code"
                  options={materialCode}
                  value={
                    formik?.values?.nozzle[2]?.materialCode
                      ? {
                          label: formik?.values?.nozzle[2]?.materialCode,
                          value: formik?.values?.nozzle[2]?.materialCode,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      2,
                      "materialCode",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Vent Orientation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Orientation( Deg.)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Orientation"
                  className="w-100"
                  name={`nozzle[${2}]?.orientation`}
                  id="inletOrientation"
                  options={orientationOptions}
                  value={
                    formik?.values?.nozzle[2]?.orientation
                      ? {
                          label: formik?.values?.nozzle[2]?.orientation,
                          value: formik?.values?.nozzle[2]?.orientation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      2,
                      "orientation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Vent Elevation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Elevation (mm)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Elevation"
                  className="w-100"
                  name={`nozzle[${2}]?.elevation`}
                  id="inletElevation"
                  options={elevationOptions}
                  value={
                    formik?.values?.nozzle[2]?.elevation
                      ? {
                          label: formik?.values?.nozzle[2]?.elevation,
                          value: formik?.values?.nozzle[2]?.elevation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      2,
                      "elevation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          )}
        </div>

        {/* Drain */}
        <div className="col-12 justify-content-center mt-4">
          <div className="d-flex align-items-center row mb-15">
            {/* Drain Checkbox  */}
            <div className="d-flex align-items-center col-lg-2 col-md-4 col-sm-6">
              <input
                type="checkbox"
                id="drain"
                name="drain"
                value="drain"
                className="mr-2 custom-checkbox"
                checked={
                  formik?.values?.nozzle && formik?.values?.nozzle[3]?.drain
                }
                onClick={() => {
                  formik.values.nozzle[3].drain =
                    !formik.values.nozzle[3].drain;
                  if (!formik.values.nozzle[3].drain) {
                    formik.values.nozzle[3].counterFlange = false;
                  }
                }}
                onChange={handleCheckboxChange}
              ></input>
              <label
                htmlFor="drain"
                className="d-flex align-items-center font-weight-bold"
              >
                Drain
              </label>
            </div>
            {formik?.values?.nozzle && formik?.values?.nozzle[3]?.drain && (
              <>
                {/* Drain Counter Flange  */}
                <div className="d-flex mr-5 align-items-center">
                  <input
                    type="checkbox"
                    id="drainCounterFlange"
                    name="drainCounterFlange"
                    value="drainCounterFlange"
                    className="mr-2 custom-checkbox"
                    checked={
                      formik?.values?.nozzle &&
                      formik?.values?.nozzle[3]?.counterFlange
                    }
                    onClick={() => {
                      formik.values.nozzle[3].counterFlange =
                        !formik.values.nozzle[3].counterFlange;
                    }}
                    onChange={handleCheckboxChange}
                  ></input>
                  <label
                    htmlFor="drainCounterFlange"
                    className="d-flex align-items-center font-weight-bold"
                  >
                    Counter Flange
                  </label>
                </div>
                {/* <div>
                  {formik?.values?.nozzle[3]?.drain +
                    ":" +
                    formik?.values?.nozzle[3]?.counterFlange}
                </div> */}
              </>
            )}
          </div>
          {formik?.values?.nozzle && formik?.values?.nozzle[3]?.drain && (
            <div className="row mt-2">
              {/* Drain Size  */}
              <div className="col-lg-2 col-md-4 col-sm-12">
                <label htmlFor="size">Size</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Size"
                  name={`nozzle[${3}].size`}
                  id="size"
                  options={filteredSizeOptions}
                  value={filteredSizeOptions.find(
                    (option) => option.value === formik?.values?.nozzle[3]?.size
                  )}
                  onChange={(selectedOption) =>
                    handleChange(
                      3,
                      "size",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Drain Schedule */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="schedules">Schedules</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Schedule"
                  className="w-100"
                  name={`nozzle[${3}].schedules`}
                  id="schedules"
                  options={schedules.map((sch) => ({
                    label: sch.sch,
                    value: sch.sch,
                  }))}
                  value={
                    formik?.values?.nozzle[3]?.schedules
                      ? {
                          label: formik?.values?.nozzle[3]?.schedules,
                          value: formik?.values?.nozzle[3]?.schedules,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      3,
                      "schedules",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Drain Flange Rating */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeRating">Flange Rating</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Rating"
                  className="w-100"
                  name={`nozzle[${3}].flangeRating`} // Ensure correct index is used (0 in this case)
                  id="flangeRating"
                  options={flangeRatingOptions}
                  value={
                    formik?.values?.nozzle[3]?.flangeRating
                      ? {
                          label: formik?.values?.nozzle[3]?.flangeRating,
                          value: formik?.values?.nozzle[3]?.flangeRating,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      3,
                      "flangeRating",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Drain Flange Type */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeType">Flange Type</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Type"
                  className="w-100"
                  name={`nozzle[${3}].flangeType`} // Ensure correct index is used (0 in this case)
                  id="flangeType"
                  options={flangeTypeOptions}
                  value={
                    formik?.values?.nozzle[3]?.flangeType
                      ? getSelectedOption(formik?.values?.nozzle[3]?.flangeType)
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      3,
                      "flangeType",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Drain Material */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="material">Material</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Material"
                  className="w-100"
                  name={`nozzle[${3}].material`} // Ensure correct index is used (3 in this case)
                  id={`material`}
                  options={materials}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      formik.setFieldValue(
                        "nozzle[3].material",
                        selectedOption.value
                      );
                      handleMaterialSelect(selectedOption);
                      handleChange(3, "material", selectedOption.value);
                    } else {
                      formik.setFieldValue("nozzle[3].material", "");
                      formik.setFieldValue("nozzle[3].materialCode", "");
                      setMaterialCode([]);
                    }
                  }}
                  value={
                    formik?.values?.nozzle[3]?.material
                      ? {
                          label: formik?.values?.nozzle[3]?.material,
                          value: formik?.values?.nozzle[3]?.material,
                        }
                      : null
                  }
                />
              </div>
              {/* Drain Material Code */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Material Code</label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="materialCode"
                  name={`nozzle[${3}]?.materialCode`}
                  className="w-100"
                  placeholder="Material Code"
                  options={materialCode}
                  value={
                    formik?.values?.nozzle[3]?.materialCode
                      ? {
                          label: formik?.values?.nozzle[3]?.materialCode,
                          value: formik?.values?.nozzle[3]?.materialCode,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      3,
                      "materialCode",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Drain Orientation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Orientation( Deg.)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Orientation"
                  className="w-100"
                  name={`nozzle[${3}]?.orientation`}
                  id="inletOrientation"
                  options={orientationOptions}
                  value={
                    formik?.values?.nozzle[3]?.orientation
                      ? {
                          label: formik?.values?.nozzle[3]?.orientation,
                          value: formik?.values?.nozzle[3]?.orientation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      3,
                      "orientation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Drain Elevation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Elevation (mm)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Elevation"
                  className="w-100"
                  name={`nozzle[${3}]?.elevation`}
                  id="inletElevation"
                  options={elevationOptions}
                  value={
                    formik?.values?.nozzle[3]?.elevation
                      ? {
                          label: formik?.values?.nozzle[3]?.elevation,
                          value: formik?.values?.nozzle[3]?.elevation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      3,
                      "elevation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          )}
        </div>

        {/* process */}
        <div className="col-12 justify-content-center mt-4">
          <div className="d-flex align-items-center row mb-15">
            {/* Process Checkbox  */}
            <div className="d-flex align-items-center col-lg-2 col-md-4 col-sm-6">
              <input
                type="checkbox"
                id="process"
                name="process"
                value="process"
                className="mr-2 custom-checkbox"
                checked={
                  formik?.values?.nozzle && formik?.values?.nozzle[4]?.process
                }
                onClick={() => {
                  formik.values.nozzle[4].process =
                    !formik.values.nozzle[4].process;
                  if (!formik.values.nozzle[4].process) {
                    formik.values.nozzle[4].counterFlange = false;
                  }
                }}
                onChange={handleCheckboxChange}
              ></input>
              <label
                htmlFor="process"
                className="d-flex align-items-center font-weight-bold"
              >
                Process
              </label>
            </div>
            {formik?.values?.nozzle && formik?.values?.nozzle[4]?.process && (
              <>
                {/* Process Counter Flange  */}
                <div className="d-flex mr-5 align-items-center">
                  <input
                    type="checkbox"
                    id="processCounterFlange"
                    name="processCounterFlange"
                    value="processCounterFlange"
                    className="mr-2 custom-checkbox"
                    checked={
                      formik?.values?.nozzle &&
                      formik?.values?.nozzle[4]?.counterFlange
                    }
                    onClick={() => {
                      formik.values.nozzle[4].counterFlange =
                        !formik.values.nozzle[4].counterFlange;
                    }}
                    onChange={handleCheckboxChange}
                  ></input>
                  <label
                    htmlFor="processCounterFlange"
                    className="d-flex align-items-center font-weight-bold"
                  >
                    Counter Flange
                  </label>
                </div>
                {/* <div>
                  {formik?.values?.nozzle[4]?.process +
                    ":" +
                    formik.values.nozzle[4].counterFlange}
                </div> */}
              </>
            )}
          </div>

          {formik?.values?.nozzle && formik?.values?.nozzle[4]?.process && (
            <div className="row mt-2">
              {/* Process Size  */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="size">Size</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Size"
                  name={`nozzle[${4}].size`}
                  id="size"
                  options={filteredSizeOptions}
                  value={filteredSizeOptions.find(
                    (option) => option.value === formik?.values?.nozzle[4]?.size
                  )}
                  onChange={(selectedOption) =>
                    handleChange(
                      4,
                      "size",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Process Schedule */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="schedules">Schedules</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Schedule"
                  className="w-100"
                  name={`nozzle[${4}].schedules`}
                  id="schedules"
                  options={schedules.map((sch) => ({
                    label: sch.sch,
                    value: sch.sch,
                  }))}
                  value={
                    formik?.values?.nozzle[4]?.schedules
                      ? {
                          label: formik?.values?.nozzle[4]?.schedules,
                          value: formik?.values?.nozzle[4]?.schedules,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      4,
                      "schedules",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Process Flange Rating */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeRating">Flange Rating</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Rating"
                  className="w-100"
                  name={`nozzle[${4}].flangeRating`} // Ensure correct index is used (0 in this case)
                  id="flangeRating"
                  options={flangeRatingOptions}
                  value={
                    formik?.values?.nozzle[4]?.flangeRating
                      ? {
                          label: formik?.values?.nozzle[4]?.flangeRating,
                          value: formik?.values?.nozzle[4]?.flangeRating,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      4,
                      "flangeRating",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Process Flange Type */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeType">Flange Type</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Type"
                  className="w-100"
                  name={`nozzle[${4}].flangeType`} // Ensure correct index is used (0 in this case)
                  id="flangeType"
                  options={flangeTypeOptions}
                  value={
                    formik?.values?.nozzle[4]?.flangeType
                      ? getSelectedOption(formik?.values?.nozzle[4]?.flangeType)
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      4,
                      "flangeType",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Process Material */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="material">Material</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Material"
                  className="w-100"
                  name={`nozzle[${4}].material`} // Ensure correct index is used (4 in this case)
                  id={`material`}
                  options={materials}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      formik.setFieldValue(
                        "nozzle[4].material",
                        selectedOption.value
                      );
                      handleMaterialSelect(selectedOption);
                      handleChange(4, "material", selectedOption.value);
                    } else {
                      formik.setFieldValue("nozzle[4].material", "");
                      formik.setFieldValue("nozzle[4].materialCode", "");
                      setMaterialCode([]);
                    }
                  }}
                  value={
                    formik?.values?.nozzle[4]?.material
                      ? {
                          label: formik?.values?.nozzle[4]?.material,
                          value: formik?.values?.nozzle[4]?.material,
                        }
                      : null
                  }
                />
              </div>
              {/* Process Material Code */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Material Code</label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="materialCode"
                  name={`nozzle[${4}]?.materialCode`}
                  className="w-100"
                  placeholder="Material Code"
                  options={materialCode}
                  value={
                    formik?.values?.nozzle[4]?.materialCode
                      ? {
                          label: formik?.values?.nozzle[4]?.materialCode,
                          value: formik?.values?.nozzle[4]?.materialCode,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      4,
                      "materialCode",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Process Orientation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Orientation( Deg.)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Orientation"
                  className="w-100"
                  name={`nozzle[${4}]?.orientation`}
                  id="inletOrientation"
                  options={orientationOptions}
                  value={
                    formik?.values?.nozzle[4]?.orientation
                      ? {
                          label: formik?.values?.nozzle[4]?.orientation,
                          value: formik?.values?.nozzle[4]?.orientation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      4,
                      "orientation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Process Elevation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Elevation (mm)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Elevation"
                  className="w-100"
                  name={`nozzle[${4}]?.elevation`}
                  id="inletElevation"
                  options={elevationOptions}
                  value={
                    formik?.values?.nozzle[4]?.elevation
                      ? {
                          label: formik?.values?.nozzle[4]?.elevation,
                          value: formik?.values?.nozzle[4]?.elevation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      4,
                      "elevation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          )}
        </div>

        {/* dummy */}
        <div className="col-12 justify-content-center mt-4 ">
          <label
            htmlFor="dummy"
            style={{ fontWeight: "bold" }}
            className="d-flex align-items-center"
          >
            {/* Dummuy checkbox  */}
            <input
              type="checkbox"
              id="dummy"
              name="dummy"
              value="dummy"
              className="mr-2 custom-checkbox"
              checked={
                formik?.values?.nozzle && formik?.values?.nozzle[5]?.dummy
              }
              onClick={() => {
                formik.values.nozzle[5].dummy = !formik.values.nozzle[5].dummy;
              }}
              onChange={handleCheckboxChange}
            />
            Dummy
          </label>
          {formik?.values?.nozzle && formik?.values?.nozzle[5]?.dummy && (
            <div className="row mt-2">
              {/* Dummy size  */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor={`size`}>Size</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Size"
                  name={`nozzle[${5}].size`}
                  id="size"
                  options={filteredSizeOptions}
                  value={filteredSizeOptions.find(
                    (option) => option.value === formik?.values?.nozzle[5]?.size
                  )}
                  onChange={(selectedOption) =>
                    handleChange(
                      5,
                      "size",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Dummy Schedule */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="schedules">Schedules</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Schedule"
                  className="w-100"
                  name={`nozzle[${5}].schedules`}
                  id="schedules"
                  options={schedules.map((sch) => ({
                    label: sch.sch,
                    value: sch.sch,
                  }))}
                  value={
                    formik?.values?.nozzle[5]?.schedules
                      ? {
                          label: formik?.values?.nozzle[5]?.schedules,
                          value: formik?.values?.nozzle[5]?.schedules,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      5,
                      "schedules",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Dummy Flange Rating */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeRating">Flange Rating</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Rating"
                  className="w-100"
                  name={`nozzle[${5}].flangeRating`} // Ensure correct index is used (0 in this case)
                  id="flangeRating"
                  options={flangeRatingOptions}
                  value={
                    formik?.values?.nozzle[5]?.flangeRating
                      ? {
                          label: formik?.values?.nozzle[5]?.flangeRating,
                          value: formik?.values?.nozzle[5]?.flangeRating,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      5,
                      "flangeRating",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Dummy Flange Type */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="flangeType">Flange Type</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Flange Type"
                  className="w-100"
                  name={`nozzle[${5}].flangeType`} // Ensure correct index is used (0 in this case)
                  id="flangeType"
                  options={flangeTypeOptions}
                  value={
                    formik?.values?.nozzle[5]?.flangeType
                      ? getSelectedOption(formik?.values?.nozzle[5]?.flangeType)
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      5,
                      "flangeType",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                />
              </div>
              {/* Dummy Quantity */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="quantity">Quantity</label>
                <input
                  placeholder="Enter Qty"
                  className="w-100 form-control"
                  name={`nozzle[${5}]?.quantity`}
                  id="quantity"
                  value={formik?.values?.nozzle[5]?.quantity}
                  onChange={(e) => {
                    handleChange(5, "quantity", e.target.value);
                  }}
                  type="number"
                  onBlur={formik?.handleBlur}
                />
              </div>
              {/* Dummy Material */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="material">Material</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Select Material"
                  className="w-100"
                  name={`nozzle[${5}].material`} // Ensure correct index is used (5 in this case)
                  id={`material`}
                  options={materials}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      formik.setFieldValue(
                        "nozzle[5].material",
                        selectedOption.value
                      );
                      handleMaterialSelect(selectedOption);
                      handleChange(5, "material", selectedOption.value);
                    } else {
                      formik.setFieldValue("nozzle[5].material", "");
                      formik.setFieldValue("nozzle[5].materialCode", "");
                      setMaterialCode([]);
                    }
                  }}
                  value={
                    formik?.values?.nozzle[5]?.material
                      ? {
                          label: formik?.values?.nozzle[5]?.material,
                          value: formik?.values?.nozzle[5]?.material,
                        }
                      : null
                  }
                />
              </div>
              {/* Dummy Material Code */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Material Code</label>
                <Select
                  isClearable
                  styles={customStyles}
                  id="materialCode"
                  name={`nozzle[${5}]?.materialCode`}
                  className="w-100"
                  placeholder="Material Code"
                  options={materialCode}
                  value={
                    formik?.values?.nozzle[5]?.materialCode
                      ? {
                          label: formik?.values?.nozzle[5]?.materialCode,
                          value: formik?.values?.nozzle[5]?.materialCode,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      5,
                      "materialCode",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Dummy Orientation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Orientation( Deg.)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Orientation"
                  className="w-100"
                  name={`nozzle[${5}]?.orientation`}
                  id="inletOrientation"
                  options={orientationOptions}
                  value={
                    formik?.values?.nozzle[5]?.orientation
                      ? {
                          label: formik?.values?.nozzle[5]?.orientation,
                          value: formik?.values?.nozzle[5]?.orientation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      5,
                      "orientation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* Outlet Elevation */}
              <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
                <label htmlFor="MaterialCode">Elevation (mm)</label>
                <Select
                  isClearable
                  styles={customStyles}
                  placeholder="Elevation"
                  className="w-100"
                  name={`nozzle[${5}]?.elevation`}
                  id="inletElevation"
                  options={elevationOptions}
                  value={
                    formik?.values?.nozzle[5]?.elevation
                      ? {
                          label: formik?.values?.nozzle[5]?.elevation,
                          value: formik?.values?.nozzle[5]?.elevation,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(
                      5,
                      "elevation",
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          )}

          {/* <div className="col-md-1 d-flex align-items-start mt-35">
                    {(index > 0 ||
                      formik.values?.nozzle[5]?.dummy.length > 1) && (
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        style={{
                          margin: "0 5px",
                          width: "50px",
                          height: "40px",
                        }}
                        title="Decrease"
                        onClick={() => handleDecrease(index)}
                      >
                        <div style={{ fontSize: "15px" }}>-</div>
                      </button>
                    )}
                    {index === formik.values?.nozzle[5]?.dummy.length - 1 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        title="Increase"
                        style={{
                          margin: "0 5px",
                          width: "50px",
                          height: "40px",
                        }}
                        onClick={() => handleIncrease(index)}
                      >
                        <div style={{ fontSize: "15px" }}>+</div>
                      </button>
                    )}
                  </div> */}
        </div>
      </div>

      {/* ----------------------------------------------- */}

      <hr />
    </div>
  );
}

export default Screen6;

{
  /* <div className="col-12 justify-content-center mt-35 ">
  <label
    htmlFor="dummy"
    style={{ fontWeight: "bold" }}
    className="d-flex"
  >
    <input
      type="checkbox"
      id="dummy"
      name="dummy"
      value="dummy"
      className="mr-2 custom-checkbox"
      checked={formik?.values?.dummy}
      onClick={() => (formik.values.dummy = !formik.values.dummy)}
      onChange={handleCheckboxChange}
    />
    Dummy
  </label>
  <div className="row ">
    {formik?.values?.dummy &&
      formik?.values?.nozzle[5]?.dummy.map((_, index) => (
        <>
          <div className="row col-md-11 mt-2" key={index}>
            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
              <label htmlFor={`nozzle[5].dummy[${index}].size`}>
                Size
              </label>
               <Select
isClearable
styles={customStyles}

                placeholder="Select Size"
                name={`nozzle[5].dummy[${index}].size`}
                id={`nozzle[5].dummy[${index}].size`}
                options={filteredSizeOptions}
                value={filteredSizeOptions.find(
                  (option) =>
                    option ? option.value : "" ===
                    formik?.values?.nozzle[5]?.dummy[index].size
                )}
                onChange={(selectedOption) =>
                  formik?.setFieldValue(
                    `nozzle[5].dummy[${index}].size`,
                    selectedOption ? selectedOption?.value : ""
                  )
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
              <label htmlFor={`nozzle[5].dummy[${index}].schedules`}>
                Schedules
              </label>
               <Select
isClearable
styles={customStyles}

                placeholder="Select Schedule"
                className="w-100"
                name={`nozzle[5].dummy[${index}].schedules`}
                id={`nozzle[5].dummy[${index}].schedules`}
                options={schedules.map((sch) => ({
                  label: sch.sch,
                  value: sch.sch,
                }))}
                value={schedules.find(
                  (option) =>
                    option ? option.value : "" ===
                    formik?.values?.nozzle[5]?.dummy[index].schedules
                )}
                onChange={(selectedOption) =>
                  formik?.setFieldValue(
                    `nozzle[5].dummy[${index}].schedules`,
                    selectedOption ? selectedOption.value : ""
                  )
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
              <label htmlFor={`nozzle[5].dummy[${index}].flangeRating`}>
                Flange Rating
              </label>
               <Select
isClearable
styles={customStyles}

                placeholder="Flange Rating"
                name={`nozzle[5].dummy[${index}].flangeRating`}
                id={`nozzle[5].dummy[${index}].flangeRating`}
                options={flangeRatingOptions}
                value={filteredSizeOptions.find(
                  (option) =>
                    option ? option.value : "" ===
                    formik?.values?.nozzle[5]?.dummy[index].flangeRating
                )}
                onChange={(selectedOption) =>
                  formik?.setFieldValue(
                    `nozzle[5].dummy[${index}].flangeRating`,
                    selectedOption ? selectedOption.value : ""
                  )
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
              <label htmlFor={`nozzle[5].dummy[${index}].flangeType`}>
                Flange Type
              </label>
               <Select
isClearable
styles={customStyles}

                placeholder="Flange Type"
                className="w-100"
                name={`nozzle[5].dummy[${index}].flangeType`}
                id={`nozzle[5].dummy[${index}].flangeType`}
                options={flangeTypeOptions}
                value={filteredSizeOptions.find(
                  (option) =>
                    option ? option.value : "" ===
                    formik?.values?.nozzle[5]?.dummy[index].flangeType
                )}
                onChange={(selectedOption) =>
                  formik?.setFieldValue(
                    `nozzle[5].dummy[${index}].flangeType`,
                    selectedOption ? selectedOption.value : ""
                  )
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <label
                htmlFor={`nozzle[5].dummy[${index}].counterFlange`}
              >
                Counter Flange
              </label>
               <Select
isClearable
styles={customStyles}

                placeholder="Counter Flange"
                className="w-100"
                name={`nozzle[5].dummy[${index}].counterFlange`}
                id={`nozzle[5].dummy[${index}].counterFlange`}
                options={[
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ]}
                value={filteredSizeOptions.find(
                  (option) =>
                    option ? option.value : "" ===
                    formik?.values?.nozzle[5]?.dummy[index]
                      .counterFlange
                )}
                onChange={(selectedOption) =>
                  formik?.setFieldValue(
                    `nozzle[5].dummy[${index}].counterFlange`,
                    selectedOption ? selectedOption.value : ""
                  )
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 mb-15">
              <label htmlFor={`nozzle[5].dummy[${index}].material`}>
                Material
              </label>
               <Select
isClearable
styles={customStyles}

                placeholder="Select Material"
                className="w-100"
                name={`nozzle[5].dummy[${index}].material`}
                id={`nozzle[5].dummy[${index}].material`}
                options={materials}
                value={filteredSizeOptions.find(
                  (option) =>
                    option ? option.value : "" ===
                    formik?.values?.nozzle[5]?.dummy[index].material
                )}
                onChange={(selectedOption) => {
                  if (selectedOption ? selectedOption?.value : "") {
                    formik.setFieldValue(`nozzle[5].dummy[${index}].material`, selectedOption ? selectedOption?.value : "");
                    handleMaterialSelect(selectedOption);
                    handleChange(5, "material", selectedOption ? selectedOption.value : "");
                  }
                }}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 mb-15  mt-3">
              <label htmlFor={`nozzle[5].dummy[${index}].materialCode`}>
                Material Code
              </label>
               <Select
isClearable
styles={customStyles}

                placeholder="Material Code"
                className="w-100"
                name={`nozzle[5].dummy[${index}].materialCode`}
                id={`nozzle[5].dummy[${index}].materialCode`}
                options={materialCode}
                value={filteredSizeOptions.find(
                  (option) =>
                    option ? option.value : "" ===
                    formik?.values?.nozzle[5]?.dummy[index].materialCode
                )}
                onChange={(selectedOption) =>
                  formik?.setFieldValue(
                    `nozzle[5].dummy[${index}].materialCode`,
                    selectedOption ? selectedOption.value : ""
                  )
                }

styles={customStyles}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-1 d-flex align-items-start mt-35">
            {(index > 0 ||
              formik.values?.nozzle[5]?.dummy.length > 1) && (
              <button
                type="button"
                className="btn btn-outline-danger"
                style={{
                  margin: "0 5px",
                  width: "50px",
                  height: "40px",
                }}
                title="Decrease"
                onClick={() => handleDecrease(index)}
              >
                <div style={{ fontSize: "15px" }}>-</div>
              </button>
            )}
            {index === formik.values?.nozzle[5]?.dummy.length - 1 && (
              <button
                type="button"
                className="btn btn-danger"
                title="Increase"
                style={{
                  margin: "0 5px",
                  width: "50px",
                  height: "40px",
                }}
                onClick={() => handleIncrease(index)}
              >
                <div style={{ fontSize: "15px" }}>+</div>
              </button>
            )}
          </div>
          <hr />
        </>
      ))}
  </div>
</div> */
}
