// import { SentimentSatisfiedAlt } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import axiosPrivate from "../../../hooks/axiosPrivate.js";
import { showErrorToast } from "../../../utils/Toaster.js";
import {
  costTypeOptions,
  micsellaneousNameOptions,
} from "../../../utils/DropdownOptions.jsx";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "../../../styles/CustomStylesForSelect.jsx";

const Screen8 = ({ formik }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = () => {
    const newItem = {
      name: "",
      material: "",
      qty: "",
      netWeight: "",
      groundWeight: "",
      unit: "",
      price: "",
    };
    const updatedArray = Array.isArray(formik.values.micsellaneous)
      ? [...formik.values.micsellaneous, newItem]
      : [newItem];
    formik.setFieldValue("micsellaneous", updatedArray);
  };

  const handleRemove = (index) => {
    const updatedItems = formik?.values?.micsellaneous?.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("micsellaneous", updatedItems);
  };

  const [prices, setPrices] = useState([]);
  const fetchPricesFromMaster = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/bom-prices");
      if (response.status === 200) {
        setPrices(response.data?.data);
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const length =
      formik.values.maxactivecoldlength +
      formik.values.maxactivecoldlength +
      formik.values.maxactivehotlength;
    formik.setFieldValue("developedlength", Math.round(Number(length)));
    fetchPricesFromMaster();
  }, []);

  return (
    <>
      <div>
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12 mt-15 mb-30">
          <div className="mb-10">
            <label>
              <b>Material Costing</b>
            </label>
          </div>
          <div className="d-flex w-100 row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.SS316">SS316</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.SS316"
                id="material.SS316"
                value={formik.values.material.SS316}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.SS316L">SS316L</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.SS316L"
                id="material.SS316L"
                value={formik.values.material.SS316L}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.SS304H">SS304H</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.SS304H"
                id="material.SS304H"
                value={formik.values.material.SS304H}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.SS321H">SS321H</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.SS321H"
                id="material.SS321H"
                value={formik.values.material.SS321H}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.SS321">SS321</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.SS321"
                id="material.SS321"
                value={formik.values.material.SS321}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material._22SR_MgO">_22SR_MgO</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material._22SR_MgO"
                id="material._22SR_MgO"
                value={formik.values.material._22SR_MgO}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.Tateho_MgO">Tateho_MgO</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.Tateho_MgO"
                id="material.Tateho_MgO"
                value={formik.values.material.Tateho_MgO}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.MS">MS</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.MS"
                id="material.MS"
                value={formik.values.material.MS}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.PressureParts_CS">
                PressureParts_CS
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.PressureParts_CS"
                id="material.PressureParts_CS"
                value={formik.values.material.PressureParts_CS}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.SS304">SS304</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.SS304"
                id="material.SS304"
                value={formik.values.material.SS304}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.GR11">GR11</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.GR11"
                id="material.GR11"
                value={formik.values.material.GR11}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.INCOLOY800">INCOLOY800</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.INCOLOY800"
                id="material.INCOLOY800"
                value={formik.values.material.INCOLOY800}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.LTCS">LTCS</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.LTCS"
                id="material.LTCS"
                value={formik.values.material.LTCS}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.Duplex">Duplex</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.Duplex"
                id="material.Duplex"
                value={formik.values.material.Duplex}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.SuperDuplex">SuperDuplex</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.SuperDuplex"
                id="material.SuperDuplex"
                value={formik.values.material.SuperDuplex}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.NonPressureParts_CS">
                NonPressureParts_CS
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.NonPressureParts_CS"
                id="material.NonPressureParts_CS"
                value={formik.values.material.NonPressureParts_CS}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.NonPressureParts_SS304">
                NonPressureParts_SS304
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.NonPressureParts_SS304"
                id="material.NonPressureParts_SS304"
                value={formik.values.material.NonPressureParts_SS304}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="material.NonPressureParts_SS316">
                NonPressureParts_SS316
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="material.NonPressureParts_SS316"
                id="material.NonPressureParts_SS316"
                value={formik.values.material.NonPressureParts_SS316}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div> */}
        <div className="w-100 row px-4 col-md-12 col-sm-12 mt-15 mb-30">
          <div className="mb-10">
            <label>
              <b>Other Costing</b>
            </label>
          </div>
          <div className="d-flex w-100 row align-items-center">
            {/* PRICE OF ALL GASKETS  */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="price.priceOfGaskets">PRICE OF ALL GASKETS</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="price.priceOfGaskets"
                id="price.priceOfGaskets"
                value={formik.values.price.priceOfGaskets}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* PRICE OF HARDWARE*/}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="price.priceOfHardware">PRICE OF HARDWARE</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="price.priceOfHardware"
                id="price.priceOfHardware"
                value={formik.values.price.priceOfHardware}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* PRICE OF CONNECTIONS*/}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="price.priceOfConnections">
                PRICE OF CONNECTIONS
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="price.priceOfConnections"
                id="price.priceOfConnections"
                value={formik.values.price.priceOfConnections}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* PRICE OF THERMOWELL*/}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="price.priceOfThermowell">
                PRICE OF THERMOWELL
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="price.priceOfThermowell"
                id="price.priceOfThermowell"
                value={formik.values.price.priceOfThermowell}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* PRICE OF TER. BOX BASE PLATE IF FLP or TER BOX IF NON FLP											*/}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="price.terminalBoxBasePlate">
                PRICE OF TER. BOX BASE PLATE
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="price.terminalBoxBasePlate"
                id="price.terminalBoxBasePlate"
                value={formik?.values?.price?.terminalBoxBasePlate}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* RATE OF PROCESS TC / RTD / TH -*/}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="rateOfProcessTcRtdTh">
                RATE OF PROCESS THERMOCOUPLE / RTD / TH
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="rateOfProcessTcRtdTh"
                id="rateOfProcessTcRtdTh"
                value={formik.values.rateOfProcessTcRtdTh}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* RATE OF PROCESS TT */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="rateOfProcess">RATE OF SHEATH TT</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="rateOfProcess"
                id="rateOfProcess"
                value={formik.values.rateOfProcess}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* RATE OF SHEATH TC / RTD / TH */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="rateOfSeathTcRtdTh">
                RATE OF SHEATH THERMOCOUPLE / RTD / TH
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="rateOfSeathTcRtdTh"
                id="rateOfSeathTcRtdTh"
                value={formik.values.rateOfSeathTcRtdTh}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* RATE OF SHEATH TT */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="rateOfSeathTT">
                RATE OF SHEATH TC / RTD / TH
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Price"}
                name="rateOfSeathTT"
                id="rateOfSeathTT"
                value={formik.values.rateOfSeathTT}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="w-100 row px-4 col-md-12 col-sm-12 mt-15 mb-30">
          <div className="mb-10">
            <label>
              <b>Micsellaneous items and costing</b>
            </label>
          </div>
          {formik?.values?.micsellaneous?.map((item, index) => (
            <div className="d-flex w-100 row align-items-center" key={index}>
              {/* Name */}
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor={`micsellaneous[${index}].name`}>Name</label>
                <span style={{ color: "red" }}>*</span>
                <CreatableSelect
                  styles={customStyles}
                  name={`micsellaneous[${index}].name`}
                  id={`micsellaneous[${index}].name`}
                  placeholder="Select Process Fluid"
                  options={micsellaneousNameOptions}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      `micsellaneous[${index}].name`,
                      selectedOption ? selectedOption.value : null
                    );
                  }} // onBlur={() => formik.setFieldTouched("processFluid", true)}
                  value={
                    formik?.values?.micsellaneous?.[index]?.name
                      ? {
                        value: formik?.values?.micsellaneous?.[index]?.name,
                        label: formik?.values?.micsellaneous?.[index]?.name,
                      }
                      : null
                  }
                  isClearable
                />
              </div>

              {/* Material */}
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <label htmlFor={`micsellaneous[${index}].material`}>
                  Material
                </label>
                <span style={{ color: "red" }}>*</span>
                <input
                  type="text"
                  placeholder="Enter Material"
                  name={`micsellaneous[${index}].material`}
                  id={`micsellaneous[${index}].material`}
                  value={formik?.values?.micsellaneous[index]?.material}
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>

              {/* Qty */}
              {/* <div className="col-lg-1 col-md-6 col-sm-12 mb-15">
                <label htmlFor={`micsellaneous[${index}].qty`}>Qty</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  type="number"
                  placeholder="Enter Qty"
                  name={`micsellaneous[${index}].qty`}
                  id={`micsellaneous[${index}].qty`}
                  value={formik?.values?.micsellaneous[index]?.qty}
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div> */}

              {/* Net Wt */}
              {/* <div className="col-lg-1 col-md-6 col-sm-12 mb-15">
                <label htmlFor={`micsellaneous[${index}].netWeight`}>
                  Net Wt.
                </label>
                <span style={{ color: "red" }}>*</span>
                <input
                  type="number"
                  placeholder="Enter Net Wt"
                  name={`micsellaneous[${index}].netWeight`}
                  id={`micsellaneous[${index}].netWeight`}
                  value={formik?.values?.micsellaneous[index]?.netWeight}
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div> */}

              {/* Ground Wt */}
              {/* <div className="col-lg-1 col-md-6 col-sm-12 mb-15">
                <label htmlFor={`micsellaneous[${index}].groundWeight`}>
                  G Wt.
                </label>
                <span style={{ color: "red" }}>*</span>
                <input
                  type="number"
                  placeholder="Enter G Wt"
                  name={`micsellaneous[${index}].groundWeight`}
                  id={`micsellaneous[${index}].groundWeight`}
                  value={formik?.values?.micsellaneous[index]?.groundWeight}
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div> */}

              {/* Unit */}
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <label htmlFor={`micsellaneous[${index}].unit`}>Unit</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  type="text"
                  placeholder="Enter Unit"
                  name={`micsellaneous[${index}].unit`}
                  id={`micsellaneous[${index}].unit`}
                  value={formik?.values?.micsellaneous[index]?.unit}
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>

              {/* Price */}
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <label htmlFor={`micsellaneous[${index}].price`}>Price</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  type="number"
                  placeholder="Enter Price"
                  name={`micsellaneous[${index}].price`}
                  id={`micsellaneous[${index}].price`}
                  value={formik?.values?.micsellaneous[index]?.price}
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>

              {/* Add/Remove Buttons */}
              <div className="col-lg-1 col-md-6 col-sm-12 d-flex align-items-end w-100 h-100 mb-30">
                {index === formik?.values?.micsellaneous?.length - 1 && (
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    style={{ minHeight: "40px" }}
                    onClick={handleAdd}
                  >
                    Add
                  </button>
                )}
                {index < formik?.values?.micsellaneous?.length - 1 && (
                  <button
                    type="button"
                    className="btn btn-secondary w-100"
                    style={{ minHeight: "40px" }}
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="w-100 row px-4 col-md-12 col-sm-12 mt-15 mb-30">
          <div className="mb-10">
            <label>
              <b>Insulation Cost</b>
            </label>
          </div>
          <div className="d-flex w-100 row align-items-center">
            {/* Glass Wool Rate */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="insulationPrice.glassWoolRate">Glass Wool Rate</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder="Enter Glass Wool Rate"
                name="insulationPrice.glassWoolRate"
                id="insulationPrice.glassWoolRate"
                value={formik?.values?.insulationPrice?.glassWoolRate}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* Cladding Sheet Rate */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="insulationPrice.claddingSheetRate">Cladding Sheet Rate</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder="Enter Cladding Sheet Rate"
                name="insulationPrice.claddingSheetRate"
                id="insulationPrice.claddingSheetRate"
                value={formik?.values?.insulationPrice?.claddingSheetRate}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* labour Charges */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="insulationPrice.labourCharges">Labour Charges</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder="Enter Labour Charges"
                name="insulationPrice.labourCharges"
                id="insulationPrice.labourCharges"
                value={formik?.values?.insulationPrice?.labourCharges}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* Misc Charges */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="insulationPrice.miscCharges">Misc Charges</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder="Enter Misc Charges"
                name="insulationPrice.miscCharges"
                id="insulationPrice.miscCharges"
                value={formik?.values?.insulationPrice?.miscCharges}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="w-100 row px-4 col-md-12 col-sm-12 mt-15 mb-30">
          <div className="mb-10">
            <label>
              <b>Costing</b>
            </label>
          </div>
          <div className="d-flex w-100 row align-items-center">
            {/* Tube Sheet Cost (A/C) */}
            <div className="w-100 d-flex">
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15 d-flex align-items-center">
                <label htmlFor="tubeSheetCost" className="m-0 p-0">
                  TUBE SHEET COST (A/C)
                </label>
                <span style={{ color: "red" }} className="m-0 p-0">
                  *
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <Select
                  name="tubeSheetCostType"
                  id="tubeSheetCostType"
                  options={costTypeOptions}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "tubeSheetCostType",
                      selectedOption.value
                    )
                  }
                  value={
                    formik.values.tubeSheetCostType
                      ? {
                        label:
                          formik.values.tubeSheetCostType
                            .charAt(0)
                            .toUpperCase() +
                          formik.values.tubeSheetCostType.slice(1),
                        value: formik.values.tubeSheetCostType,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <input
                  type="number"
                  placeholder="Enter Price"
                  name="tubeSheetCost"
                  id="tubeSheetCost"
                  disabled={formik.values.tubeSheetCostType === "costing"}
                  value={
                    formik.values.tubeSheetCostType === "costing"
                      ? formik?.values?.price.tubeSheet
                      : formik.values.tubeSheetCost
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>
            </div>

            {/* VESSEL COST (A/C) */}
            <div className="w-100 d-flex">
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15 d-flex align-items-center">
                <label htmlFor="vesselCost" className="m-0 p-0">
                  VESSEL COST (A/C)
                </label>
                <span style={{ color: "red" }} className="m-0 p-0">
                  *
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <Select
                  name="vesselCostType"
                  id="vesselCostType"
                  options={costTypeOptions}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("vesselCostType", selectedOption.value)
                  }
                  value={
                    formik.values.vesselCostType
                      ? {
                        label:
                          formik.values.vesselCostType
                            .charAt(0)
                            .toUpperCase() +
                          formik.values.vesselCostType.slice(1),
                        value: formik.values.vesselCostType,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <input
                  type="number"
                  placeholder="Enter Price"
                  name="vesselCost"
                  id="vesselCost"
                  disabled={formik.values.vesselCostType === "costing"}
                  value={
                    formik.values.vesselCostType === "costing"
                      ? formik?.values?.price?.shellVessel
                      : formik.values.vesselCost
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>
            </div>

            {/* SHELL FLANGE COST (A/C) */}
            <div className="w-100 d-flex">
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15 d-flex align-items-center">
                <label htmlFor="shellFlangeCostType" className="m-0 p-0">
                  SHELL FLANGE COST (A/C)
                </label>
                <span style={{ color: "red" }} className="m-0 p-0">
                  *
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <Select
                  name="shellFlangeCostType"
                  id="shellFlangeCostType"
                  options={costTypeOptions}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "shellFlangeCostType",
                      selectedOption.value
                    )
                  }
                  value={
                    formik.values.shellFlangeCostType
                      ? {
                        label:
                          formik.values.shellFlangeCostType
                            .charAt(0)
                            .toUpperCase() +
                          formik.values.shellFlangeCostType.slice(1),
                        value: formik.values.shellFlangeCostType,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <input
                  type="number"
                  placeholder="Enter Price"
                  name="shellFlangeCost"
                  id="shellFlangeCost"
                  disabled={formik.values.shellFlangeCostType === "costing"}
                  value={
                    formik.values.shellFlangeCostType === "costing"
                      ? formik?.values?.price?.shellRing
                      : formik.values.shellFlangeCost
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>
            </div>

            {/* RADIOGRAPHY COST (A/C) */}
            <div className="w-100 d-flex">
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15 d-flex align-items-center">
                <label htmlFor="radiographyCostType" className="m-0 p-0">
                  RADIOGRAPHY COST (A/C)
                </label>
                <span style={{ color: "red" }} className="m-0 p-0">
                  *
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <Select
                  name="radiographyCostType"
                  id="radiographyCostType"
                  options={costTypeOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption.value.toLowerCase() === "costing") {
                      formik.setFieldValue(
                        "price.radiographyCost",
                        (Number(formik?.values?.flangeSize) * 4500 * formik.values.radiography?.value) / 100
                      );
                    }
                    formik.setFieldValue(
                      "radiographyCostType",
                      selectedOption.value
                    );
                  }}
                  value={
                    formik.values.radiographyCostType
                      ? {
                        label:
                          formik.values.radiographyCostType
                            .charAt(0)
                            .toUpperCase() +
                          formik.values.radiographyCostType.slice(1),
                        value: formik.values.radiographyCostType,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <input
                  type="number"
                  placeholder="Enter Price"
                  name="price.radiographyCost"
                  id="price.radiographyCost"
                  disabled={formik.values.radiographyCostType === "costing"}
                  value={
                    formik.values.radiographyCostType === "costing"
                      ? formik.values.price.radiographyCost
                      : formik.values.price.radiographyCost
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>
            </div>

            {/* PAINTING COST (A/C) */}
            <div className="w-100 d-flex">
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15 d-flex align-items-center">
                <label htmlFor="paintingCostType" className="m-0 p-0">
                  PAINTING COST (A/C)
                </label>
                <span style={{ color: "red" }} className="m-0 p-0">
                  *
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <Select
                  name="paintingCostType"
                  id="paintingCostType"
                  options={costTypeOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption.value.toLowerCase() === "costing") {
                      formik.setFieldValue(
                        "price.paintingCost",
                        Number(prices?.find((item) => item.id === 34)?.price) +
                        4
                      );
                    }
                    formik.setFieldValue(
                      "paintingCostType",
                      selectedOption.value
                    );
                  }}
                  value={
                    formik.values.paintingCostType
                      ? {
                        label:
                          formik.values.paintingCostType
                            .charAt(0)
                            .toUpperCase() +
                          formik.values.paintingCostType.slice(1),
                        value: formik.values.paintingCostType,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <input
                  type="number"
                  placeholder="Enter Price"
                  name="price.paintingCost"
                  id="price.paintingCost"
                  disabled={formik.values.paintingCostType === "costing"}
                  value={
                    formik.values.paintingCostType === "costing"
                      ? formik.values.price.paintingCost
                      : formik.values.price.paintingCost
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>
            </div>

            {/* BAFFLE COST (A/C) */}
            <div className="w-100 d-flex">
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15 d-flex align-items-center">
                <label htmlFor="baffleCostType" className="m-0 p-0">
                  BAFFLE COST (A/C)
                </label>
                <span style={{ color: "red" }} className="m-0 p-0">
                  *
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <Select
                  name="baffleCostType"
                  id="baffleCostType"
                  options={costTypeOptions}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("baffleCostType", selectedOption.value)
                  }
                  value={
                    formik.values.baffleCostType
                      ? {
                        label:
                          formik.values.baffleCostType
                            .charAt(0)
                            .toUpperCase() +
                          formik.values.baffleCostType.slice(1),
                        value: formik.values.baffleCostType,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <input
                  type="number"
                  placeholder="Enter Price"
                  name="baffleCost"
                  id="baffleCost"
                  disabled={formik.values.baffleCostType === "costing"}
                  value={
                    formik.values.baffleCostType === "costing"
                      ? formik.values.price.baffle
                      : formik.values.baffleCost
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>
            </div>

            {/* TERMINAL BOX COST (A/C) */}
            <div className="w-100 d-flex">
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15 d-flex align-items-center">
                <label htmlFor="terminalBoxCostType" className="m-0 p-0">
                  TERMINAL BOX COST (A/C)
                </label>
                <span style={{ color: "red" }} className="m-0 p-0">
                  *
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <Select
                  name="terminalBoxCostType"
                  id="terminalBoxCostType"
                  options={costTypeOptions}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "terminalBoxCostType",
                      selectedOption.value
                    )
                  }
                  value={
                    formik.values.terminalBoxCostType
                      ? {
                        label:
                          formik.values.terminalBoxCostType
                            .charAt(0)
                            .toUpperCase() +
                          formik.values.terminalBoxCostType.slice(1),
                        value: formik.values.terminalBoxCostType,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <input
                  type="number"
                  placeholder="Enter Price"
                  name="terminalBoxCost"
                  id="terminalBoxCost"
                  disabled={formik.values.terminalBoxCostType === "costing"}
                  value={
                    formik.values.terminalBoxCostType === "costing"
                      ? 999
                      : formik.values.terminalBoxCost
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>
            </div>

            {/* INSULATION COST (A/C) */}
            <div className="w-100 d-flex">
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15 d-flex align-items-center">
                <label htmlFor="insulationCostType" className="m-0 p-0">
                  INSULATION COST (A/C)
                </label>
                <span style={{ color: "red" }} className="m-0 p-0">
                  *
                </span>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <Select
                  name="insulationCostType"
                  id="insulationCostType"
                  options={costTypeOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption.value.toLowerCase() === "costing") {
                      formik.setFieldValue(
                        "price.insulationCost",
                        Number(prices?.find((item) => item.id === 34)?.price) *
                        1.2
                      );
                    }
                    formik.setFieldValue(
                      "insulationCostType",
                      selectedOption.value
                    );
                  }}
                  value={
                    formik.values.insulationCostType
                      ? {
                        label:
                          formik.values.insulationCostType
                            .charAt(0)
                            .toUpperCase() +
                          formik.values.insulationCostType.slice(1),
                        value: formik.values.insulationCostType,
                      }
                      : null
                  }
                />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 mb-15">
                <input
                  type="number"
                  placeholder="Enter Price"
                  name="price.insulationCost"
                  id="price.insulationCost"
                  disabled={formik.values.insulationCostType === "costing"}
                  value={
                    formik.values.insulationCostType === "costing"
                      ? formik?.values?.price?.insulationCost
                      : formik?.values?.price?.insulationCost
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          {/*

 */}
        </div>
        <div className="w-100 row px-4 col-md-12 col-sm-12 mt-15 mb-30">
          <div className="mb-10">
            <label>
              <b>Total Margin Cost</b>
            </label>
          </div>
          <div className="d-flex w-100 row align-items-center">
            {/* MANUFACTURING COST  */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="manufacturingCost">
                Manufacturing Cost @ (%)
              </label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Manufacturing Cost"}
                name="manufacturingCost"
                id="manufacturingCost"
                value={formik.values.manufacturingCost}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* Margin */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="margin">Margin @ (%)</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Margin"}
                name="margin"
                id="margin"
                value={formik.values.margin}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>

            {/* Contingency */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
              <label htmlFor="contingency">Contingency @ (%)</label>
              <span style={{ color: "red" }}>*</span>
              <input
                type="number"
                placeholder={"Enter Contingency"}
                name="contingency"
                id="contingency"
                value={formik.values.contingency}
                onChange={formik.handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <hr />
      <hr />
      <hr /> */}

      <div>
        {/* flange cost */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Flange Cost
          </label>
          <div className="mt-0 form d-block">
            <div className="row w-100 mt-10 d-flex w-100">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="flangepipesize">Flange Pipe Size</label>
                <input
                  name="flangeSize"
                  id="flangeSize"
                  type="text"
                  className="form-control"
                  placeholder="Flange Pipe Size"
                  value={formik?.values?.flangeSize}
                  readOnly
                />
              </div>
              <div className="col-lg-3 mb-15 ">
                <label htmlFor="flangePrice">Price</label>
                <input
                  name="flangePrice"
                  id="flangePrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.flangePrice || 0).toFixed(
                    2
                  )}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="flangeTotalCost">Total Cost</label>
                <input
                  name="flangeTotalCost"
                  id="flangeTotalCost"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.flangeTotalCost || 0
                  ).toFixed(2)}
                  placeholder="Total Cost"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15 ">
                <label htmlFor="flangeWeight">Flange Weight</label>
                <input
                  name="flangeWeight"
                  id="flangeWeight"
                  type="text"
                  className="form-control"
                  placeholder="Flange Weight"
                  onChange={formik?.handleChange}
                  value={formik?.values?.flangeWeight}
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* vessel cost */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Vessel Cost
          </label>
          <div className="mt-0 form d-block">
            <div className="row w-100 d-flex mt-10">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="vesselWeight">Vessel Weight</label>
                <input
                  name="vesselWeight"
                  id="vesselWeight"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={formik?.values?.vesselWeight}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="vesselMaterialCC">Vessel Material</label>
                <input
                  name="vesselMaterial"
                  id="vesselMaterial"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={formik?.values?.vesselMaterial}
                  placeholder="Vessel Material"
                  readOnly
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="vesselPrice">Price</label>
                <input
                  name="vesselPrice"
                  id="vesselPrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.vesselPrice || 0).toFixed(
                    2
                  )}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="vesselTotalPrice">Total Cost</label>
                <input
                  name="vesselTotalPrice"
                  id="vesselTotalPrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.vesselTotalPrice || 0
                  ).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* sensor cost */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
        <label htmlFor="" style={{ fontWeight: "bold" }}>
          Sensor Cost
        </label>
        <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tr>
              <td style={{ padding: "10px" }}>
                <div className="row mt-4">
                  <label htmlFor="" style={{ fontWeight: "bold" }}></label>
                </div>
                <div className="mt-15">
                  <label htmlFor="skinSensor">Skin Sensor</label>
                  <input
                    name="sensor[0].sensorsubtype"
                    id="sensor[0].sensorsubtype"
                    type="text"
                    className="form-control"
                    placeholder="Skin Sensor"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[0].sensorsubtype}
                  />
                </div>
                <div className="mt-15">
                  <label htmlFor="tubeSensor">Tube Sensor</label>
                  <input
                    name="sensor[1].sensorsubtype"
                    id="sensor[1].sensorsubtype"
                    type="text"
                    className="form-control"
                    placeholder="Tube Sensor"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[1].sensorsubtype}
                  />
                </div>
                <div className="mt-15">
                  <label htmlFor="proceessSensor">Process Sensor</label>
                  <input
                    name="sensor[2].sensorsubtype"
                    id="sensor[2].sensorsubtype"
                    type="text"
                    className="form-control"
                    placeholder="0"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[2].sensorsubtype}
                  />
                </div>
              </td>
              <td style={{ padding: "10px" }}>
                <div className="row">
                  <label htmlFor="">Quantity</label>
                </div>
                <div className="mt-45">
                  <input
                    name="skinSensorQuantity"
                    id="skinSensorQuantity"
                    type="text"
                    className="form-control"
                    placeholder="0"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[0]?.quantity}
                  />
                </div>
                <div className="mt-45">
                  <input
                    name="tubeSensorQuantity"
                    id="tubeSensorQuantity"
                    type="text"
                    className="form-control"
                    placeholder="0"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[1]?.quantity}
                  />
                </div>
                <div className="mt-45">
                  <input
                    name="proceessSensorQuantity"
                    id="proceessSensorQuantity"
                    type="text"
                    className="form-control"
                    placeholder="0"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[2]?.quantity}
                  />
                </div>
              </td>
              <td style={{ padding: "10px" }}>
                <div className="row ">
                  <label htmlFor="">Total Cost</label>
                </div>
                <div className="mt-45">
                  <input
                    name="skinSensorTotalCost"
                    id="skinSensorTotalCost"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.skinSensorTotalCost || 0
                    ).toFixed(2)}
                  />
                </div>
                <div className="mt-45">
                  <input
                    name="tubeSensorTotalCost"
                    id="tubeSensorTotalCost"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.tubeSensorTotalCost || 0
                    ).toFixed(2)}
                  />
                </div>
                <div className="mt-45">
                  <input
                    name="proceessSensorTotalCost"
                    id="proceessSensorTotalCost"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.proceessSensorTotalCost || 0
                    ).toFixed(2)}
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div> */}

        {/* Baffle Cost */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Baffle Cost
          </label>
          <div className="mt-0 form d-block">
            <div className="row w-100 d-flex mt-10">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="baffleWeight">Baffle Weight</label>
                <input
                  name="baffleWeight"
                  id="baffleWeight"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values.baffleWeight || 0).toFixed(
                    2
                  )}
                  placeholder="Baffle Weight"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="bafflePrice">Baffle Price</label>
                <input
                  name="bafflePrice"
                  id="bafflePrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values.bafflePrice || 0).toFixed(2)}
                  placeholder="Baffle Price"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="totalCost">Total Cost</label>
                <input
                  name="baffleTotalCost"
                  id="baffleTotalCost"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values.baffleTotalCost || 0
                  ).toFixed(2)}
                  placeholder="Total Cost"
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* Nozzle Flange Cost */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
          <h3 style={{ fontWeight: "bold", marginTop: "20px" }}>
            Nozzle Flange Cost
          </h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "10px" }}></th>
                <th style={{ padding: "10px" }}>Pipe Size</th>
                <th style={{ padding: "10px" }}>Price</th>
                <th style={{ padding: "10px" }}>Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Inlet</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="inletNozzlePrice"
                    id="inletNozzlePrice"
                    type="text"
                    className="form-control w-60"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.inletNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>

                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Outlet</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="outletNozzlePrice"
                    id="outletNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.outletNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>

                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Drain</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="drainNozzlePrice"
                    id="drainNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.drainNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>

                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Vent</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="ventNozzlePrice"
                    id="ventNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.ventNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>

                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Process</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="processNozzlePrice"
                    id="processNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.processNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>

                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Dummy</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="dummyNozzlePrice"
                    id="dummyNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.dummyNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

        {/* Nozzle Pipe Size */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
        <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
          <h3 style={{ fontWeight: "bold", marginTop: "20px" }}>
            Nozzle Pipe Size
          </h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "10px" }}></th>
                <th style={{ padding: "10px" }}>Length</th>
                <th style={{ padding: "10px" }}>Price</th>
                <th style={{ padding: "10px" }}>Total Cost</th>
                <th style={{ padding: "10px" }}>Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Inlet</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="inletLength"
                    id="inletLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.inletLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="inletPrice"
                    id="inletPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.inletPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Outlet</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="outletLength"
                    id="outletLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.outletLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="outletPrice"
                    id="outletPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.outletPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Drain</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="drainLength"
                    id="drainLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.drainLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="drainPrice"
                    id="drainPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.drainPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Vent</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="ventLength"
                    id="ventLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.ventLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="ventPrice"
                    id="ventPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.ventPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Process</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="processLength"
                    id="processLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.processLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="processPrice"
                    id="processPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.processPrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Dummy</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="dummyLength"
                    id="dummyLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.dummyLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="dummyPrice"
                    id="dummyPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.dummyPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

        {/* Tube Cost */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Tube Cost
          </label>
          <div className="mt-0 form d-block">
            <div className="row w-100 d-flex mt-10">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="flangepipesize">Heating Element Price </label>
                <input
                  name="heating_element_price"
                  id="heating_element_price"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.heating_element_price || 0
                  ).toFixed(2)}
                  placeholder="Heating Element Price"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="MGOPrice">MGO Price</label>
                <input
                  name="MGOPrice"
                  id="priMGOPricee"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.MGOPrice || 0).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="ElementPrice">Element Price</label>
                <input
                  name="ElementPrice"
                  id="ElementPrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.ElementPrice || 0).toFixed(
                    2
                  )}
                  placeholder="Element Price"Net Wt.*
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="HeatingElementTotalCost">Total Cost</label>
                <input
                  name="HeatingElementTotalCost"
                  id="HeatingElementTotalCost"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.HeatingElementTotalCost || 0
                  ).toFixed(2)}
                  placeholder="Total Cost"
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* Heating Element Fixture Cost */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Heating Element Fixture Cost
          </label>
          <div className="mt-0 form d-block">
            <div className="row w-100 d-flex mt-10">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="HeatingElementFixturePrice">Price </label>
                <input
                  name="HeatingElementFixturePrice"
                  id="HeatingElementFixturePrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.HeatingElementFixturePrice || 0
                  ).toFixed(2)}
                  placeholder="Price"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="HeatingElementFixtureTotalCost">
                  Total Cost
                </label>
                <input
                  name="HeatingElementFixtureTotalCost"
                  id="HeatingElementFixtureTotalCost"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.HeatingElementFixtureTotalCost || 0
                  ).toFixed(2)}
                  placeholder="Total Cost"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="TerminalBoxPrice">Terminal Box Price</label>
                <Select
                  placeholder="Select Size"
                  name="TerminalBoxPrice"
                  id="TerminalBoxPrice"
                  styles={{ width: "50%" }}
                  options={yesNoOption}
                  onChange={formik?.handleChange}
                  value={formik?.values?.TerminalBoxPrice}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-40">
                <label htmlFor="TerminalBoxPrice">&nbsp;</label>
                <input
                  name="TerminalBoxPrice"
                  id="TerminalBoxPrice"
                  type="text"
                  styles={{ width: "50%" }}
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.TerminalBoxPrice || 0
                  ).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-12 col-md-6 col-sm-12 mb-15">
                <label htmlFor="atex">ATEX</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="radio"
                      id="atexYes"
                      name="atex"
                      value="yes"
                      className="form-check-input"
                      style={{ marginBottom: "10px", marginLeft: "100px" }}
                      onChange={formik?.handleChange}
                      checked={formik?.values?.atex === "yes"}
                    />
                    <label
                      htmlFor="atexYes"
                      className="form-check-label"
                      style={{ marginLeft: "5px" }}
                    >
                      Yes
                    </label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="radio"
                      id="atexNo"
                      name="atex"
                      value="no"
                      className="form-check-input"
                      style={{ marginBottom: "10px", marginLeft: "100px" }}
                      onChange={formik?.handleChange}
                      checked={formik?.values?.atex === "no"}
                    />
                    <label
                      htmlFor="atexNo"
                      className="form-check-label"
                      style={{ marginLeft: "5px" }}
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Micsellaneous Cost */}
        {/* <div className="w-100 row px-4 col-md-12 col-sm-12">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Micsellaneous Cost
          </label>
          <div className="mt-0 form d-block">
            <div className="row w-100 d-flex mt-10">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="connectionPrice">
                  Connection Price Single/Three(star/delta)
                </label>
                <input
                  name="connectionPrice"
                  id="connectionPrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.connectionPrice || 0
                  ).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="lugSupport">Lug Support</label>
                <input
                  name="lugSupport"
                  id="lugSupport"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.lugSupport || 0).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="insulation">Insulation</label>
                <input
                  name="insulation"
                  id="insulation"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.insulation || 0).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="gasKits">Gas Kits</label>
                <input
                  name="gasKits"
                  id="gasKits"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.gasKits || 0).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
            </div>

            <div className="row w-100 d-flex mt-10">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="paint">Paint</label>
                <input
                  name="paint"
                  id="paint"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.paint || 0).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="other">Other</label>
                <input
                  name="other"
                  id="other"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  defaultValue={parseFloat(formik?.values?.other || 0).toFixed(
                    2
                  )}
                  placeholder="0.00"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-15">
                <label htmlFor="" style={{ color: "white" }}>
                  calculate
                </label>
                <button className="btn btn-primary bg-primary form-control">
                  <h6>Calculate</h6>
                </button>
              </div>
            </div>
          </div>
        </div> */}

        {/* Total cost estimation */}
        {/* <div className="cost-container">
          <label htmlFor="totalEstimatedCost" className="cost-label">
            TOTAL ESTIMATED COST
          </label>
          <h3 className="cost-value">0.00</h3>
        </div> */}
        {/* <hr /> */}
      </div>
    </>
  );
};

export default Screen8;
