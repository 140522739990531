
const bom = {
  groundWeight: {
    material: 0,
    // heatingElement: 0,
    tubeSheet: 0,
    tubesheetDrilling: 0,
    shellRing: 0,
    shellVessel: 0,
    dishEnd: 0,

    inletNozzle: 0,
    inletFlange: 0,

    outletNozzle: 0,
    outletFlange: 0,

    drainNozzle: 0,
    drainFlange: 0,

    ventNozzle: 0,
    ventFlange: 0,

    processNozzle: 0,
    processFlange: 0,

    dummyNozzle: 0,
    dummyFlange: 0,

    baffle: 0,
    tieRodWithSleeves: 0,
    lugsSupport: 0,
    junctionBox: 0,
    terminalBoxBasePlate: 0,
  },
  netWeight: {
    material: 0,
    // heatingElement: 0,
    tubeSheet: 0,
    tubesheetDrilling: 0,
    shellRing: 0,
    shellVessle: 0,
    dishEnd: 0,

    inletNozzle: 0,
    inletFlange: 0,

    outletNozzle: 0,
    outletFlange: 0,

    drainNozzle: 0,
    drainFlange: 0,

    ventNozzle: 0,
    ventFlange: 0,

    processNozzle: 0,
    processFlange: 0,

    dummyNozzle: 0,
    dummyFlange: 0,

    baffle: 0,
    tieRodWithSleeves: 0,
    lugsSupport: 0,
    junctionBox: 0,
    terminalBoxBasePlate: 0,
  },
  price: {
    material: 0,
    heatingElement: 0,
    tubeSheet: 0,
    tubesheetDrilling: 0,
    shellRing: 0,
    shellVessel: 0,
    dishEnd: 0,

    inletNozzle: 0,
    inletFlange: 0,

    outletNozzle: 0,
    outletFlange: 0,

    drainNozzle: 0,
    drainFlange: 0,

    ventNozzle: 0,
    ventFlange: 0,

    processNozzle: 0,
    processFlange: 0,

    dummyNozzle: 0,
    dummyFlange: 0,
    baffle: 0,
    tieRodWithSleeves: 0,
    lugsSupport: 0,
    junctionBox: 0,
    terminalBoxBasePlate: 0,
    cableGland: 0,
    insulationCost: 0,
    radiographyCost: 0,
    paintingCost: 0,
    terminalBoxCost: 0,
    // baffleCost: 0,
    // SAFETYTHERMOCOUPLE

    safetyThermocouple: 0,
    processThermocouple: 0,
    priceOfGaskets: 0,
    priceOfHardware: 0,
    priceOfThermowell: 0,
    priceOfConnections: 0,
  },

  finalCosting: {
    unitMaterialCost: 0,
    bundleMaterialCost: 0,
    unitCogs: 0,
    bundleCogs: 0,
    unitSellingPrice: 0,
    bundleSellingPrice: 0,
    unitQuotedPrice: 0,
    bundleQuotedPrice: 0,
  },

  unitBundle: {
    COGS: {
      unit: 0,
      bundle: 0,
    },
    sellingPrice: {
      unit: 0,
      bundle: 0,
    },
    quotedPrice: {
      unit: 0,
      bundle: 0,
    },
  },
};

export default bom;

// switch (formik.values.fixingofheatingelements) {
//   case "Sleeves":
//     return data.heatingelement * 2 * gweightofheatingelement;
//   case "Nut":
//     return formik?.values?.nutsize * 3;
//   case "Bush":
//     return formik?.values?.totalnumberofelements * 200;
//   case "Direct":
//     return formik?.values?.totalnumberofelements * 500;
//   case "Tig + Sleeves":
//     return (
//       data.heatingelement * 2 * gweightofheatingelement +
//       formik?.values?.totalnumberofelements * 500
//     );
//   default:
//     return "No Inforcement";
// }

// formik?.values?.fixingofheatingelements.toLowerCase() === "Sleeves"
//   ? data.fixingheatingelementprice * formik?.values?.groundWeight?.material
//   : formik?.values?.fixingofheatingelements.toLowerCase() === "Nut"
//   ? formik?.values?.nutsize * 3
//   : formik?.values?.fixingofheatingelements.toLowerCase() === "Bush"
//   ? formik?.values?.totalnumberofelements * 200
//   : formik?.values?.fixingofheatingelements.toLowerCase() === "Direct"
//   ? formik?.values?.totalnumberofelements * 500
//   : formik?.values?.fixingofheatingelements.toLowerCase() === "Tig + Sleeves"
//   ? data.heatingelement * 2 * gweightofheatingelement +
//     formik?.values?.totalnumberofelements * 500
//   : "No Increment";
